const localeDefault = {
  "I1_10017;28_31263;28_31261": ">",
  "1_10075": "Client Portal",
  "I1_10069;581_1954;7882_21853;433_34538": "2",
  "I1_10061;18_21709": "Content",
  "I1_10033;171_66633": "Created By",
  "I1_10060;18_21709": "Absence Report",
  "I1_10062;33_33258;33_33254": "Disapproved",
  "1_10056": "Action",
  "I1_10069;581_1954;7882_26229;433_34538": "10",
  "I1_10029;171_66633": "Function Name",
  "I1_10053;18_21709": "16/01/2024",
  "I1_10039;18_21709": "Content",
  "I1_10069;368_110840": "Show 1 to 10 of 100 results",
  "I1_10051;33_33258;33_33254": "Approved",
  "I1_10094;250_58575": "List Approval",
  "I1_10095;250_58566": "Approval setting",
  "I1_10077;21_22137": "Top",
  "I1_10050;18_21709": "Content",
  "1_10045": "Action",
  "I1_10069;581_1954;7882_22097;433_34538": "4",
  "I1_10059;18_21709": "Delete",
  "I1_10049;18_21709": "Expense Report",
  "1_10023": "Start Date - End Date",
  "I1_10048;18_21709": "Edit",
  "I1_10069;581_1954;7882_26275;433_34538": "11",
  "I1_10097;23_39072": "Notification",
  "I1_10017;28_31246;28_31236": "Approval",
  "I1_10078;23_39072": "Company Settings",
  "I1_10025;318_97322": "Status",
  "I1_10092;23_39084": "Approval",
  "I1_10064;18_21709": "16/01/2024",
  "I1_10084;21_22137": "Driver Management",
  "I1_10085;21_22137": "Passenger Management",
  "I1_10031;171_66633": "Setting Flow",
  "I1_10032;171_66633": "Status",
  "I1_10034;171_66633": "Created At",
  "I1_10090;23_39072": "Work Management",
  "I1_10017;28_31248;28_31243": "List Approval",
  "I1_10026;121_59079": "Search...",
  "I1_10063;18_21709": "xxx@gmail.com",
  "I1_10069;581_1954;7882_26160;433_34538": "...",
  "I1_10041;18_21709": "xxx@gmail.com",
  "I1_10052;18_21709": "xxx@gmail.com",
  "1_10088": "Chauffeur Connect",
  "I1_10038;18_21709": "Expense Report",
  "I1_10080;23_39072": "Vehicle Management",
  "1_10067": "Action",
  "I1_10037;18_21709": "Create",
  "I1_10030;171_66633": "Description",
  "I1_10035;171_66633": "Action",
  "I1_10069;581_1954;7882_21473;497_44116": "1",
  "I1_10040;33_33258;33_33254": "Approved",
  "1_10073": "5",
  "I1_10018;35_49503": "List Approval",
  "I1_10069;581_1954;7882_21971;433_34538": "3",
  "I1_10069;581_1954;7882_19131;497_44116": "10 / page",
  "I1_10042;18_21709": "16/01/2024",
  "1_10159": "Client Portal",
  "I1_10174;23_39072": "Work Management",
  "I1_10169;21_22137": "Passenger Management",
  "I1_10179;250_58566": "Approval setting",
  "I1_10178;250_58575": "List Approval",
  "I1_10105;35_49552;28_31243": " View Approval Detail",
  "I1_10168;21_22137": "Driver Management",
  "I1_10142;185_68545": "Enter Amount",
  "1_10112": "Date",
  "I1_10105;35_49551;28_31261": ">",
  "I1_10105;35_49550;28_31236": "List Approval",
  "1_10118": "Expense Category",
  "1_10117": "*",
  "I1_10153;30_32137": "Reject",
  "1_10148": "Photo",
  "1_10131": "Which vehicle is this expense for",
  "I1_10114;75_53865;250_37580": "Select Date",
  "I1_10152;30_32140": "Approve",
  "I1_10127;185_68545": "Select Payer",
  "I1_10105;35_49548;28_31236": "Approval",
  "I1_10161;21_22137": "Top",
  "I1_10135;30_32081": "Search",
  "I1_10105;35_49549;28_31261": ">",
  "1_10172": "Chauffeur Connect",
  "I1_10162;23_39072": "Company Settings",
  "I1_10181;23_39072": "Notification",
  "1_10124": "Paid by",
  "1_10123": "*",
  "I1_10128;30_32081": "Search",
  "1_10111": "*",
  "I1_10120;75_53865;250_37580": "Select Expense Category",
  "I1_10134;185_68545": "Select Vehicle",
  "I1_10145;7700_22671": "Description",
  "I1_10164;23_39072": "Vehicle Management",
  "1_10157": "5",
  "I1_10145;7700_22673;185_68601": "Description",
  "1_10139": "Expense Amount",
  "289_32518": "uploadFile",
  "I1_10106;35_49503": " View Approval Detail",
  "1_10138": "*",
  "I1_10176;23_39084": "Approval",
  "1_10215": "*",
  "I1_10238;23_39072": "Vehicle Management",
  "I1_10189;35_49551;28_31261": ">",
  "I1_10250;23_39084": "Approval",
  "I1_10243;21_22137": "Passenger Management",
  "1_10222": "Photo",
  "289_32519": "uploadFile",
  "I1_10190;388_23148;30_32140": "Create",
  "I1_10189;35_49548;28_31236": "Approval",
  "I1_10252;250_58575": "List Approval",
  "I1_10235;21_22137": "Top",
  "I1_10212;75_53865;250_37580": "Select Time",
  "I1_10226;30_32140": "Approve",
  "I1_10199;185_68545": "Select Absentee",
  "1_10203": "*",
  "1_10246": "Chauffeur Connect",
  "I1_10255;23_39072": "Notification",
  "I1_10253;250_58566": "Approval setting",
  "I1_10242;21_22137": "Driver Management",
  "1_10233": "Client Portal",
  "I1_10189;35_49550;28_31236": "List Approval",
  "I1_10219;7636_13405;185_68545": "Reason",
  "I1_10227;30_32137": "Reject",
  "I1_10218;75_53865;250_37580": "Select Time",
  "1_10195": "* ",
  "I1_10200;30_32081": "Search",
  "I1_10236;23_39072": "Company Settings",
  "I1_10248;23_39072": "Work Management",
  "1_10216": "To",
  "1_10204": "Date",
  "1_10196": "Absentee  ",
  "I1_10189;35_49552;28_31243": " View Approval Detail",
  "I1_10189;35_49549;28_31261": ">",
  "1_10209": "*",
  "I1_10190;388_23101": " View Approval Detail",
  "1_10210": "From",
  "I1_10219;7636_13403": "Reason",
  "I1_10206;75_53865;250_37580": "Select Date",
  "1_10231": "5",
  "I1_10273;7700_22671": "Description",
  "I1_10303;250_58566": "Emergency Contact",
  "I1_10301;250_58575": "Overtime Rules",
  "1_10269": "*",
  "I1_10302;250_58566": "Holidays",
  "1_10278": "Weekly Overtime",
  "I1_10311;21_22137": "Passenger Management",
  "1_10314": "Chauffeur Connect",
  "I1_10263;35_49551;28_31261": ">",
  "I1_10273;7700_22673;154_61744": "Description",
  "1_10270": "Overtime Rule Name",
  "I1_10283;75_53807": "Minute",
  "I1_10263;35_49548;28_31236": "Company Settings",
  "I1_10322;23_39072": "Approval",
  "I1_10264;35_49503": "Add Overtime Rule",
  "1_10280": "Total Weekly Overtime Not Exceed",
  "I1_10305;23_39072": "Vehicle Management",
  "I1_10297;23_39084": "Company setting",
  "I1_10310;21_22137": "Driver Management",
  "I1_10295;21_22137": "Top",
  "I1_10327;23_39072": "Notification",
  "I1_10317;23_39072": "Work Management",
  "I1_10263;35_49550;28_31236": "Overtime Rules",
  "I1_10263;35_49549;28_31261": ">",
  "I1_10284;154_64377;75_53865;250_37564": "Monday",
  "I1_10287;30_32140": "Save",
  "1_10293": "Client Portal",
  "1_10277": "*",
  "1_10291": "5",
  "I1_10272;75_53807": "Overtime Rule Name",
  "I1_10282;75_53807": "Hour",
  "I1_10284;154_64376": "First Day of Week",
  "I1_10300;250_58566": "Business Hours",
  "I1_10263;35_49552;28_31243": "Add Overtime Rule",
  "I1_10299;250_58566": "Department Management",
  "I1_10286;30_32137": "Cancel",
  "I1_10377;23_39072": "Vehicle Management",
  "I1_10356;154_64377;75_53865;250_37573": "Monday",
  "I1_10382;21_22137": "Driver Management",
  "I1_10335;35_49550;28_31236": "Overtime Rules",
  "I1_10374;250_58566": "Holidays",
  "1_10342": "Overtime Rule Name",
  "I1_10369;23_39084": "Company setting",
  "1_10365": "Client Portal",
  "1_10386": "Chauffeur Connect",
  "1_10349": "*",
  "I1_10399;23_39072": "Notification",
  "I1_10354;121_58638": "Hour",
  "I1_10336;35_49503": "Edit Overtime Rule",
  "I1_10335;35_49551;28_31261": ">",
  "I1_10345;7700_22671": "Description",
  "I1_10335;35_49552;28_31243": "Edit Overtime Rule",
  "1_10341": "*",
  "I1_10389;23_39072": "Work Management",
  "I1_10344;121_58638": "Overtime Rule Name",
  "I1_10335;35_49549;28_31261": ">",
  "I1_10335;35_49548;28_31236": "Company Settings",
  "I1_10373;250_58575": "Overtime Rules",
  "I1_10371;250_58566": "Department Management",
  "I1_10359;30_32140": "Update",
  "I1_10394;23_39072": "Approval",
  "I1_10372;250_58566": "Business Hours",
  "I1_10383;21_22137": "Passenger Management",
  "1_10352": "Total Weekly Overtime Not Exceed",
  "I1_10367;21_22137": "Top",
  "1_10350": "Weekly Overtime",
  "I1_10356;154_64376": "First Day of Week",
  "1_10363": "5",
  "I1_10345;7700_22673;250_58177": "Description",
  "I1_10355;121_58638": "Minute",
  "I1_10358;30_32137": "Cancel",
  "I1_10375;250_58566": "Emergency Contact",
  "1_10435": "5",
  "I1_10455;21_22137": "Passenger Management",
  "1_10421": "*",
  "I1_10417;7700_22671": "Description",
  "I1_10407;35_49549;28_31261": ">",
  "I1_10428;154_64376": "First Day of Week",
  "I1_10417;7700_22673;185_68601": "Description",
  "1_10424": "Total Weekly Overtime Not Exceed",
  "I1_10471;23_39072": "Notification",
  "I1_10407;35_49551;28_31261": ">",
  "I1_10407;35_49548;28_31236": "Company Settings",
  "I1_10428;154_64377;75_53865;250_37580": "Monday",
  "I1_10407;35_49552;28_31243": "View Overtime Rule",
  "I1_10431;30_32140": "Save",
  "1_10422": "Weekly Overtime",
  "I1_10444;250_58566": "Business Hours",
  "I1_10449;23_39072": "Vehicle Management",
  "I1_10447;250_58566": "Emergency Contact",
  "I1_10461;23_39072": "Work Management",
  "I1_10416;185_68545": "Overtime Rule Name",
  "I1_10426;185_68545": "Hour",
  "I1_10445;250_58575": "Overtime Rules",
  "I1_10439;21_22137": "Top",
  "1_10413": "*",
  "I1_10407;35_49550;28_31236": "Overtime Rules",
  "I1_10443;250_58566": "Department Management",
  "1_10437": "Client Portal",
  "I1_10454;21_22137": "Driver Management",
  "I1_10446;250_58566": "Holidays",
  "I1_10466;23_39072": "Approval",
  "1_10458": "Chauffeur Connect",
  "1_10414": "Overtime Rule Name",
  "I1_10427;185_68545": "Minute",
  "I1_10430;30_32137": "Cancel",
  "I1_10441;23_39084": "Company setting",
  "I1_10408;35_49503": "View Overtime Rule",
  "I1_10480;611_95468;611_95349": "Confirm",
  "I1_10479;30_31919": "Delete",
  "I1_10480;611_93749": "Are you sure to delete Department",
  "I1_10478;30_32128": "Edit",
  "I1_10480;611_93748": "Warning",
  "I1_10480;611_95467;30_32137": "Cancel",
  "I1_10495;75_53818": "In Progress",
  "I1_10494;75_53818": "Completed",
  "1_10488": "Identity Management",
  "I1_10496;75_53818": "Cancel",
  "1_10492": "Export Management",
  "I1_10501;611_93748": "Warning",
  "I1_10500;30_31919": "Delete",
  "I1_10501;611_95467;30_32137": "Cancel",
  "I1_10501;611_95468;611_95349": "Confirm",
  "I1_10499;30_32128": "Edit",
  "I1_10501;611_93749": "Are you sure to delete Business Hour?",
  "I1_10506;611_95468;611_95349": "Confirm",
  "I1_10504;30_32128": "Edit",
  "I1_10506;611_93749": "Are you sure to delete Holiday?",
  "I1_10506;611_93748": "Warning",
  "I1_10507;866_49171;30_32128": "Every year",
  "I1_10505;30_31919": "Delete",
  "I1_10507;866_49169;30_32128": "Never",
  "I1_10506;611_95467;30_32137": "Cancel",
  "I1_10512;611_93748": "Warning",
  "I1_10511;30_31919": "Delete",
  "I1_10512;611_95467;30_32137": "Cancel",
  "I1_10512;611_95468;611_95349": "Confirm",
  "I1_10510;30_32128": "Edit",
  "I1_10512;611_93749": "Are you sure to delete Vehicle Group?",
  "I1_10516;30_31919": "Delete",
  "I1_10515;30_32128": "Edit",
  "I1_10517;611_93749": "Are you sure to delete Vehicle Depot?",
  "I1_10517;611_95468;611_95349": "Confirm",
  "I1_10517;611_95467;30_32137": "Cancel",
  "I1_10517;611_93748": "Warning",
  "I1_10583;8068_76055": "Sep",
  "196_30561": "Absence",
  "I1_10528;611_93387": "Email Invitation Sent!",
  "196_30562": "CLOSE",
  "196_30559": "Approved",
  "1_10530": "Show Result for",
  "196_30553": "Day Shift",
  "I1_10578;8068_76055": "May",
  "I1_10526;30_32128": "Re-send invitation",
  "I1_10527;611_93748": "Warning",
  "1_10559": "Start Date",
  "I1_10524;30_32128": "Edit",
  "196_30558": "Pending",
  "I1_10522;30_32128": "Send invitation",
  "I1_10527;611_95467;30_32137": "Cancel",
  "I1_10521;30_31919": "Delete",
  "1_10543": "Last 7 days",
  "1_10552": "Last 30 days",
  "I1_10585;8068_76055": "Oct",
  "I1_10575;8068_76055": "Mar",
  "1_10561": "End Date",
  "I1_10586;8068_76055": "Nov",
  "1_10539": "This Week",
  "I1_10525;30_31919": "Delete",
  "1_10568": "Mar 2024",
  "1_10548": "This Month",
  "196_30560": "Disapproved",
  "I1_10527;611_93749": "Are you sure to delete Driver?",
  "I1_10587;8068_76055": "Dec",
  "I1_10579;8068_76055": "Jun",
  "1_10556": "Custom",
  "196_30557": "All Day",
  "I1_10582;8068_76055": "Aug",
  "I1_10574;8068_76055": "Feb",
  "I1_10577;8068_76055": "Apr",
  "I1_10581;8068_76055": "Jul",
  "I1_10527;611_95468;611_95349": "Confirm",
  "196_30554": "Evening Shift",
  "I1_10520;30_32128": "Edit",
  "1_10535": "All Time",
  "I1_10573;8068_76053": "Jan",
  "I1_10590;30_32128": "Edit",
  "1_10629": "Start Date",
  "I1_10598;611_93387": "Email Invitation Sent!",
  "1_10622": "Last 30 days",
  "I1_10594;30_32128": "Edit",
  "I1_10597;611_95467;30_32137": "Cancel",
  "1_10609": "This Week",
  "1_10626": "Custom",
  "1_10631": "End Date",
  "I1_10595;30_31919": "Delete",
  "I1_10596;30_32128": "Re-send invitation",
  "194_30550": "Are your sure to make Service Active?",
  "1_10618": "This Month",
  "I1_10591;30_31919": "Delete",
  "I1_10592;30_32128": "Send invitation",
  "194_30548": "Delete service success",
  "I1_10597;611_95468;611_95349": "Confirm",
  "I1_10597;611_93748": "Warning",
  "194_30551": "Are your sure to make Service Inactive?",
  "I1_10597;611_93749": "Are you sure to delete Passenger?",
  "1_10600": "Show Result for",
  "1_10613": "Last 7 days",
  "194_30549": "Incorrect service code",
  "1_10605": "All Time",
  "I1_10636;30_31919": "Delete",
  "I1_10637;611_93748": "Warning",
  "I1_10635;30_32128": "Edit",
  "I1_10637;611_95468;611_95349": "Confirm",
  "I1_10637;611_93749": "Are you sure to delete Expense Category?",
  "I1_10637;611_95467;30_32137": "Cancel",
  "I1_10664;121_59079": "Search...",
  "I1_10683;18_21709": "Content",
  "I1_10816;581_1954;7882_26160;433_34538": "...",
  "I1_10758;18_21709": "Sales",
  "I1_10656;30_32140": "Request for Approval",
  "I1_10746;18_21709": "01/02/2024 8:30",
  "I1_10816;581_1954;7882_21473;497_44116": "1",
  "I1_10729;171_66633": "Driver",
  "I1_10761;581_1954;7882_26229;433_34538": "10",
  "I1_10778;171_66633": "Driver Name",
  "I1_10707;581_1954;7882_26160;433_34538": "...",
  "I1_10816;581_1954;7882_26229;433_34538": "10",
  "I1_10765;30_32140": "Save",
  "I1_10744;835_98906": "ID 2",
  "I1_10655;30_32137": "Cancel",
  "I1_10773;121_59079": "Search...",
  "I1_10675;18_21709": "Content",
  "I1_10728;171_66633": "Department",
  "I1_10816;581_1954;7882_26275;433_34538": "11",
  "I1_10827;611_95468;611_95349": "Confirm",
  "I1_10747;18_21709": "Passenger B",
  "I1_10827;611_93749": "Are you sure to delete Report?",
  "I1_10647;7636_13403": "Approval Flow",
  "I1_10806;18_21709": "Content",
  "I1_10651;7636_13405;185_68545": "Updated",
  "I1_10650;7636_13405;185_68545": "Pending",
  "I1_10827;611_93748": "Warning",
  "I1_10707;581_1954;7882_21853;433_34538": "2",
  "I1_10761;581_1954;7882_26160;433_34538": "...",
  "I1_10808;18_21709": "Content",
  "I1_10697;18_21709": "Content",
  "I1_10825;30_32128": "Expense Report",
  "I1_10668;171_66633": "Driver ID",
  "I1_10754;835_98906": "ID 3",
  "I1_10671;171_66633": "Department",
  "I1_10735;18_21709": "Ride",
  "I1_10759;18_21709": "Driver A",
  "I1_10677;18_21709": "Content",
  "I1_10707;581_1954;7882_21473;497_44116": "1",
  "I1_10787;18_21709": "Content",
  "I1_10761;581_1954;7882_22097;433_34538": "4",
  "I1_10822;30_32128": "Edit",
  "I1_10827;611_95467;30_32137": "Cancel",
  "I1_10761;581_1954;7882_21473;497_44116": "1",
  "I167_30376;30_32128": "Absence Report",
  "I1_10706;18_21709": "Content",
  "I1_10652;7636_13405;185_68545": "2023-12-23 14:24:30",
  "I1_10779;171_66633": "Email Address",
  "I1_10785;18_21709": "Content",
  "I1_10807;18_21709": "Content",
  "I1_10745;18_21709": "Meeting",
  "I1_10726;171_66633": "Pick up Date Time",
  "I1_10707;581_1954;7882_19131;497_44116": "10 / page",
  "I1_10750;18_21709": "Brand Model",
  "I1_10707;368_110840": "Show 1 to 10 of 100 results",
  "I1_10749;18_21709": "Driver A",
  "I1_10816;581_1954;7882_21971;433_34538": "3",
  "I1_10816;581_1954;7882_21853;433_34538": "2",
  "I1_10704;18_21709": "Content",
  "I1_10777;171_66633": "Driver ID",
  "I1_10710;30_32137": "Cancel",
  "I1_10761;368_110840": "Show 1 to 10 of 100 results",
  "I1_10793;18_21709": "Content",
  "I1_10682;18_21709": "Content",
  "I1_10678;18_21709": "Content",
  "I1_10794;18_21709": "Content",
  "I1_10734;835_98906": "ID 1",
  "I1_10648;7636_13403": "Approval ID",
  "I1_10799;18_21709": "Content",
  "1_10769": "Select Absentee",
  "I1_10651;7636_13403": "After status",
  "I1_10760;18_21709": "Brand Model",
  "I1_10757;18_21709": "Passenger C",
  "I1_10736;18_21709": "01/02/2024 8:30",
  "I1_10816;368_110840": "Show 1 to 10 of 100 results",
  "I1_10707;581_1954;7882_21971;433_34538": "3",
  "I1_10685;18_21709": "Content",
  "I1_10814;18_21709": "Content",
  "I1_10650;7636_13403": "Current status",
  "I1_10800;18_21709": "Content",
  "I1_10696;18_21709": "Content",
  "1_10660": "Select Payer",
  "I1_10761;581_1954;7882_21853;433_34538": "2",
  "I1_10739;18_21709": "Driver A",
  "I1_10812;18_21709": "Content",
  "I1_10738;18_21709": "Sales",
  "I1_10805;18_21709": "Content",
  "I1_10652;7636_13403": "Request date",
  "I1_10756;18_21709": "01/02/2024 8:30",
  "I1_10816;581_1954;7882_22097;433_34538": "4",
  "I1_10670;171_66633": "Email Address",
  "I1_10690;18_21709": "Content",
  "I1_10798;18_21709": "Content",
  "I1_10705;18_21709": "Content",
  "I1_10691;18_21709": "Content",
  "I1_10823;30_31919": "Delete",
  "I1_10801;18_21709": "Content",
  "I1_10724;171_66633": "ID",
  "1_10715": "Select Activity",
  "I1_10689;18_21709": "Content",
  "I1_10720;121_59079": "Search...",
  "I1_10699;18_21709": "Content",
  "I1_10786;18_21709": "Content",
  "I1_10692;18_21709": "Content",
  "I1_10719;433_34538": "Activity Type",
  "I1_10761;581_1954;7882_19131;497_44116": "10 / page",
  "I1_10826;30_32128": "Absence Report",
  "1_10642": "Approval Confirmation",
  "I1_10698;18_21709": "Content",
  "I1_10764;30_32137": "Cancel",
  "I1_10813;18_21709": "Content",
  "I1_10819;30_32137": "Cancel",
  "I1_10730;171_66633": "Vehicle",
  "I1_10649;7636_13403": "Requestor",
  "I1_10820;30_32140": "Save",
  "I1_10711;30_32140": "Save",
  "I1_10761;581_1954;7882_21971;433_34538": "3",
  "I1_10684;18_21709": "Content",
  "I1_10703;18_21709": "Content",
  "I1_10727;171_66633": "Passenger",
  "I1_10725;171_66633": "Activity Type",
  "I1_10737;18_21709": "Passenger A",
  "I167_30375;30_32128": "Expense Report",
  "I1_10647;7636_13405;185_68545": "Flow Name (Click to view)",
  "I1_10792;18_21709": "Content",
  "I1_10740;18_21709": "Brand Model",
  "I1_10676;18_21709": "Content",
  "I1_10815;18_21709": "Content",
  "I1_10761;581_1954;7882_26275;433_34538": "11",
  "I1_10707;581_1954;7882_22097;433_34538": "4",
  "I1_10748;18_21709": "Sales",
  "I1_10648;7636_13405;185_68545": "235",
  "I1_10791;18_21709": "Content",
  "I1_10669;171_66633": "Driver Name",
  "I1_10816;581_1954;7882_19131;497_44116": "10 / page",
  "I1_10707;581_1954;7882_26275;433_34538": "11",
  "I1_10784;18_21709": "Content",
  "I1_10780;171_66633": "Department",
  "I1_10707;581_1954;7882_26229;433_34538": "10",
  "I1_10755;18_21709": "Airport",
  "I1_10649;7636_13405;185_68545": "OM Staff - Bien Hoang",
  "I1_10897;581_1954;7882_21853;433_34538": "2",
  "1_10959": "Select Absentee",
  "I1_10981;18_21709": "Content",
  "I1_11006;581_1954;7882_21473;497_44116": "1",
  "I1_10967;171_66633": "Driver ID",
  "I1_10997;18_21709": "Content",
  "I1_10991;18_21709": "Content",
  "I1_11016;30_32128": "Absence Report",
  "I1_10910;121_59079": "Search...",
  "I1_10886;18_21709": "Content",
  "I1_10955;30_32140": "Save",
  "I1_10893;18_21709": "Content",
  "I1_10926;18_21709": "01/02/2024 8:30",
  "I1_10897;368_110840": "Show 1 to 10 of 100 results",
  "I1_11006;581_1954;7882_26275;433_34538": "11",
  "I1_11017;611_95467;30_32137": "Cancel",
  "I1_10984;18_21709": "Content",
  "I1_10854;121_59079": "Search...",
  "I1_10897;581_1954;7882_26275;433_34538": "11",
  "I1_10846;30_32140": "Request for Approval",
  "I1_11017;611_93748": "Warning",
  "I1_10842;7636_13403": "Request date",
  "I1_10837;7636_13405;185_68545": "Flow Name (Click to view)",
  "I1_10925;18_21709": "Ride",
  "I1_10858;171_66633": "Driver ID",
  "I1_10917;171_66633": "Passenger",
  "I1_10897;581_1954;7882_21971;433_34538": "3",
  "I1_10998;18_21709": "Content",
  "I1_10841;7636_13403": "After status",
  "I310_32139;30_32128": "Disapproved",
  "I1_10938;18_21709": "Sales",
  "I1_10951;581_1954;7882_26275;433_34538": "11",
  "I1_10889;18_21709": "Content",
  "I1_10865;18_21709": "Content",
  "I1_10951;581_1954;7882_19131;497_44116": "10 / page",
  "I1_10927;18_21709": "Passenger A",
  "I1_10944;835_98906": "ID 3",
  "I1_10860;171_66633": "Email Address",
  "I1_10974;18_21709": "Content",
  "I1_10842;7636_13405;185_68545": "2023-12-23 14:24:30",
  "I1_10897;581_1954;7882_21473;497_44116": "1",
  "I1_10872;18_21709": "Content",
  "I1_11006;368_110840": "Show 1 to 10 of 100 results",
  "I1_10951;581_1954;7882_22097;433_34538": "4",
  "I1_10859;171_66633": "Driver Name",
  "I1_10935;18_21709": "Meeting",
  "I1_11003;18_21709": "Content",
  "I1_11006;581_1954;7882_21853;433_34538": "2",
  "I1_10951;368_110840": "Show 1 to 10 of 100 results",
  "I1_10969;171_66633": "Email Address",
  "I1_10920;171_66633": "Vehicle",
  "I1_10940;18_21709": "Brand Model",
  "I1_10924;835_98906": "ID 1",
  "I1_10874;18_21709": "Content",
  "I1_10919;171_66633": "Driver",
  "I1_10988;18_21709": "Content",
  "I1_10939;18_21709": "Driver A",
  "I1_10948;18_21709": "Sales",
  "I1_10880;18_21709": "Content",
  "1_10850": "Select Payer",
  "I1_10954;30_32137": "Cancel",
  "I1_10951;581_1954;7882_26229;433_34538": "10",
  "I1_11015;30_32128": "Expense Report",
  "I1_10951;581_1954;7882_21971;433_34538": "3",
  "I1_10866;18_21709": "Content",
  "I1_10882;18_21709": "Content",
  "I1_10930;18_21709": "Brand Model",
  "I1_10968;171_66633": "Driver Name",
  "I1_10963;121_59079": "Search...",
  "I1_11017;611_95468;611_95349": "Confirm",
  "I1_10867;18_21709": "Content",
  "I1_10837;7636_13403": "Approval Flow",
  "I1_11005;18_21709": "Content",
  "1_10832": "Approval Confirmation",
  "I1_10937;18_21709": "Passenger B",
  "I1_10879;18_21709": "Content",
  "I1_10839;7636_13405;185_68545": "OM Staff - Bien Hoang",
  "I1_10989;18_21709": "Content",
  "I1_10951;581_1954;7882_21853;433_34538": "2",
  "I1_10975;18_21709": "Content",
  "I1_10915;171_66633": "Activity Type",
  "I1_10946;18_21709": "01/02/2024 8:30",
  "I1_10996;18_21709": "Content",
  "I1_11012;30_32128": "Edit",
  "I1_10950;18_21709": "Brand Model",
  "I1_10895;18_21709": "Content",
  "I1_10901;30_32140": "Save",
  "I1_10928;18_21709": "Sales",
  "I1_10838;7636_13403": "Approval ID",
  "I1_11010;30_32140": "Save",
  "I1_10894;18_21709": "Content",
  "I1_10982;18_21709": "Content",
  "I1_10840;7636_13405;185_68545": "Pending",
  "I1_10990;18_21709": "Content",
  "I1_10951;581_1954;7882_26160;433_34538": "...",
  "1_10905": "Select Activity",
  "I1_10934;835_98906": "ID 2",
  "I1_10936;18_21709": "01/02/2024 8:30",
  "I1_10897;581_1954;7882_19131;497_44116": "10 / page",
  "I1_10840;7636_13403": "Current status",
  "I1_11006;581_1954;7882_22097;433_34538": "4",
  "I1_10888;18_21709": "Content",
  "I1_10929;18_21709": "Driver A",
  "I1_11009;30_32137": "Cancel",
  "I1_10887;18_21709": "Content",
  "I1_10897;581_1954;7882_26160;433_34538": "...",
  "I1_10868;18_21709": "Content",
  "I1_10838;7636_13405;185_68545": "235",
  "I1_10918;171_66633": "Department",
  "I1_10896;18_21709": "Content",
  "I1_11013;30_31919": "Delete",
  "I1_10861;171_66633": "Department",
  "I1_10947;18_21709": "Passenger C",
  "I1_10970;171_66633": "Department",
  "I1_10945;18_21709": "Airport",
  "I1_10845;30_32137": "Cancel",
  "I1_10897;581_1954;7882_22097;433_34538": "4",
  "I1_11002;18_21709": "Content",
  "I1_10839;7636_13403": "Requestor",
  "I1_10949;18_21709": "Driver A",
  "I1_10897;581_1954;7882_26229;433_34538": "10",
  "I1_11006;581_1954;7882_26229;433_34538": "10",
  "I1_10916;171_66633": "Pick up Date Time",
  "I310_32134;30_32128": "Approved",
  "I1_11006;581_1954;7882_26160;433_34538": "...",
  "I1_11017;611_93749": "Are you sure to delete Report?",
  "I1_11006;581_1954;7882_19131;497_44116": "10 / page",
  "I1_10995;18_21709": "Content",
  "I1_10951;581_1954;7882_21473;497_44116": "1",
  "I1_10909;433_34538": "Activity Type",
  "I1_10914;171_66633": "ID",
  "I310_32133;30_32128": "Pending",
  "I1_11006;581_1954;7882_21971;433_34538": "3",
  "I1_10976;18_21709": "Content",
  "I1_10875;18_21709": "Content",
  "I1_10977;18_21709": "Content",
  "I1_10841;7636_13405;185_68545": "Updated",
  "I1_10983;18_21709": "Content",
  "I1_10873;18_21709": "Content",
  "I1_10881;18_21709": "Content",
  "I1_10900;30_32137": "Cancel",
  "I1_11004;18_21709": "Content",
  "1_11149": "Operation Log",
  "I1_11067;18_21709": "Content",
  "I1_11066;18_21709": "Content",
  "I1_11054;121_59079": "Search...",
  "I1_11120;18_21709": "Content",
  "I1_11092;581_1954;7882_26229;433_34538": "10",
  "I1_11059;171_66633": "Driver Name",
  "I1_11072;18_21709": "Content",
  "I1_11092;581_1954;7882_19131;497_44116": "10 / page",
  "I1_11111;171_66633": "Department",
  "1_11100": "Select Assigned Passenger",
  "I1_11128;18_21709": "Content",
  "I1_11126;18_21709": "Content",
  "I1_11020;30_32128": "Edit",
  "I1_11127;18_21709": "Content",
  "I1_11084;18_21709": "Content",
  "I1_11079;18_21709": "Content",
  "I1_11115;18_21709": "Content",
  "I1_11073;18_21709": "Content",
  "I1_11142;581_1954;7882_26160;433_34538": "...",
  "I1_11121;18_21709": "Content",
  "1_11158": "User ID Received",
  "I1_11040;7636_13405;121_58638": "Updated",
  "I1_11104;121_59079": "Search...",
  "I1_11091;18_21709": "Content",
  "I1_11142;581_1954;7882_22097;433_34538": "4",
  "I1_11129;18_21709": "Content",
  "I1_11141;18_21709": "Content",
  "I1_11064;18_21709": "Content",
  "I1_11083;18_21709": "Content",
  "I1_11092;581_1954;7882_22097;433_34538": "4",
  "I1_11027;611_93748": "Warning",
  "I1_11041;7636_13405;121_58638": "2023-12-23 14:24:30",
  "I1_11133;18_21709": "Content",
  "I1_11142;581_1954;7882_21473;497_44116": "1",
  "I1_11142;581_1954;7882_19131;497_44116": "10 / page",
  "I1_11027;611_95468;611_95349": "Confirm",
  "I1_11022;30_32128": "Return",
  "I1_11092;581_1954;7882_21853;433_34538": "2",
  "I1_11071;18_21709": "Content",
  "I1_11082;18_21709": "Content",
  "I1_11070;18_21709": "Content",
  "I1_11077;18_21709": "Content",
  "I1_11142;581_1954;7882_26275;433_34538": "11",
  "I1_11117;18_21709": "Content",
  "I1_11045;30_32140": "Request for Approval",
  "I1_11092;368_110840": "Show 1 to 10 of 100 results",
  "I1_11096;30_32140": "Save",
  "I1_11135;18_21709": "Content",
  "I1_11026;30_32128": "Delete",
  "I1_11060;171_66633": "Email Address",
  "I1_11142;581_1954;7882_21971;433_34538": "3",
  "I1_11046;623_21687": "The primary driver cannot be deleted",
  "I1_11076;18_21709": "Content",
  "I1_11037;7636_13405;121_58638": "235",
  "I1_11065;18_21709": "Content",
  "I1_11092;581_1954;7882_26160;433_34538": "...",
  "I1_11038;7636_13403": "Requestor",
  "I1_11085;18_21709": "Content",
  "I1_11142;368_110840": "Show 1 to 10 of 100 results",
  "I1_11058;171_66633": "Driver ID",
  "1_11154": "22/02/2024 10:12",
  "I1_11092;581_1954;7882_26275;433_34538": "11",
  "I1_11134;18_21709": "Content",
  "I1_11142;581_1954;7882_21853;433_34538": "2",
  "I1_11123;18_21709": "Content",
  "I1_11116;18_21709": "Content",
  "I1_11108;171_66633": "Passenger ID",
  "I1_11021;30_32128": "Receive",
  "I1_11089;18_21709": "Content",
  "I1_11039;7636_13405;121_58638": "Pending",
  "I1_11027;611_93749": "Are you sure to delete Driver?",
  "I1_11037;7636_13403": "Approval ID",
  "I1_11095;30_32137": "Cancel",
  "I1_11140;18_21709": "Content",
  "I1_11025;30_32128": "Set a primary",
  "1_11153": "22/02/2024 10:12",
  "I1_11122;18_21709": "Content",
  "I1_11146;30_32140": "Select",
  "I1_11038;7636_13405;121_58638": "OM Staff - Bien Hoang",
  "I1_11036;7636_13405;121_58638": "Flow Name (Click to view)",
  "I1_11039;7636_13403": "Current status",
  "I1_11110;171_66633": "Email Address",
  "I1_11040;7636_13403": "After status",
  "I1_11023;30_32128": "Operation Log",
  "I1_11044;30_32137": "Cancel",
  "I1_11036;7636_13403": "Approval Flow",
  "I1_11114;18_21709": "Content",
  "I1_11027;611_95467;30_32137": "Cancel",
  "I1_11145;30_32137": "Cancel",
  "1_11157": "User ID returned",
  "I1_11139;18_21709": "Content",
  "I1_11092;581_1954;7882_21473;497_44116": "1",
  "I1_11138;18_21709": "Content",
  "I1_11092;581_1954;7882_21971;433_34538": "3",
  "I1_11078;18_21709": "Content",
  "I1_11041;7636_13403": "Request date",
  "1_11050": "Select Assigned Driver",
  "I1_11090;18_21709": "Content",
  "1_11031": "Approval Confirmation",
  "I1_11132;18_21709": "Content",
  "I1_11061;171_66633": "Department",
  "I1_11109;171_66633": "Passenger Name",
  "I1_11088;18_21709": "Content",
  "I1_11142;581_1954;7882_26229;433_34538": "10",
  "I1_11160;611_93749": "Are you sure to delete Overtime Rule?",
  "I1_11163;75_53818": "Tuesday",
  "I1_11170;30_32128": "Edit",
  "I1_11160;611_95468;611_95349": "Confirm",
  "I1_11160;611_93748": "Warning",
  "I1_11165;75_53818": "Thursday",
  "I1_11162;75_53818": "Monday",
  "I1_11166;75_53818": "Friday",
  "I1_11160;611_95467;30_32137": "Cancel",
  "I1_11167;75_53818": "Saturday",
  "I1_11171;30_31919": "Delete",
  "I1_11168;75_53818": "Sunday",
  "I1_11164;75_53818": "Wednesday",
  "I1_11194;171_66633": "Action",
  "I1_11175;28_31246;28_31236": "Notification",
  "I1_11258;250_58566": "Send Notification",
  "I1_11219;581_1954;7882_19131;497_44116": "10 / page",
  "I1_11175;28_31263;28_31261": ">",
  "I1_11240;21_22137": "Passenger Management",
  "I1_11219;581_1954;7882_26229;433_34538": "10",
  "I1_11247;23_39072": "Approval",
  "I1_11259;250_58575": "Segment",
  "I1_11212;835_98906": "Segment 3",
  "I1_11191;171_66633": "Title",
  "I1_11213;18_21709": "Specific Organization",
  "I1_11175;28_31248;28_31243": "Segment",
  "I1_11234;23_39072": "Vehicle Management",
  "I1_11227;21_22137": "Top",
  "I1_11239;21_22137": "Driver Management",
  "1_11217": "Action",
  "1_11243": "Chauffeur Connect",
  "I1_11204;835_98906": "Segment 2",
  "I1_11193;171_66633": "Created Date",
  "I1_11219;581_1954;7882_21971;433_34538": "3",
  "I1_11214;18_21709": "01/01/2024 10:30:00",
  "I1_11219;581_1954;7882_26275;433_34538": "11",
  "I1_11229;23_39072": "Company setting",
  "1_11255": "Notification",
  "I1_11197;18_21709": "All Organization",
  "I1_11206;18_21709": "01/02/2024 10:30:00",
  "I1_11198;18_21709": "01/03/2024 10:30:00",
  "I1_11219;581_1954;7882_26160;433_34538": "...",
  "I1_11245;23_39072": "Work Management",
  "I1_11184;30_32140": "Add",
  "I1_11219;581_1954;7882_21473;497_44116": "1",
  "I1_11192;171_66633": "Segment Type",
  "I1_11188;121_59079": "Search...",
  "1_11225": "Client Portal",
  "1_11209": "Action",
  "I1_11196;835_98906": "Segment 1",
  "I1_11219;581_1954;7882_21853;433_34538": "2",
  "1_11181": "Segment",
  "1_11223": "5",
  "I1_11219;368_110840": "Show 1 to 10 of 100 results",
  "1_11201": "Action",
  "I1_11219;581_1954;7882_22097;433_34538": "4",
  "I1_11205;18_21709": "All Organization",
  "I1_11297;30_32140": "Next",
  "I289_32457;289_32078": "Upload",
  "1_11280": "*",
  "I1_11264;35_49552;28_31243": "Add Notification",
  "I1_11318;21_22137": "Passenger Management",
  "1_11286": "Destination URL",
  "I1_11264;35_49550;28_31236": "Send Notification",
  "1_11301": "5",
  "I1_11265;35_49503": "Add Notification",
  "I1_11282;154_61744": "Message",
  "I1_11264;35_49551;28_31261": ">",
  "289_32522": "Upload Image",
  "1_11271": "Segment",
  "1_11281": "Message",
  "I1_11323;23_39072": "Work Management",
  "I1_11277;75_53807": "Title",
  "1_11275": "*",
  "I1_11305;21_22137": "Top",
  "I1_11325;23_39072": "Approval",
  "I1_11272;75_53865;250_37564": "Select Segment",
  "I1_11317;21_22137": "Driver Management",
  "1_11333": "Notification",
  "1_11303": "Client Portal",
  "I1_11287;75_53807": "Destination URL",
  "286_32097": "Immediately",
  "I1_11312;23_39072": "Vehicle Management",
  "1_11321": "Chauffeur Connect",
  "I1_11307;23_39072": "Company setting",
  "I1_11336;250_58575": "Send Notification",
  "1_11276": "Title",
  "286_32103": "Schedule",
  "I1_11264;35_49548;28_31236": "Notification",
  "1_11290": "When to send",
  "I1_11264;35_49549;28_31261": ">",
  "I1_11337;250_58566": "Segment",
  "1_11270": "*",
  "I1_11296;30_32137": "Cancel",
  "I1_11872;250_58575": "Send Notification",
  "I1_11801;35_49503": "Edit Notification",
  "I1_11854;21_22137": "Passenger Management",
  "I1_11861;23_39072": "Approval",
  "1_11817": "Message",
  "I289_32557;289_32078": "Upload",
  "I1_11800;35_49549;28_31261": ">",
  "I1_11873;250_58566": "Segment",
  "I1_11859;23_39072": "Work Management",
  "1_11857": "Chauffeur Connect",
  "1_11812": "Title",
  "I1_11833;30_32140": "Next",
  "1_11837": "5",
  "I1_11800;35_49550;28_31236": "Send Notification",
  "1_11822": "Destination URL",
  "1_11807": "Segment",
  "286_32159": "Schedule",
  "I1_11823;121_58638": "Destination URL",
  "I1_11800;35_49548;28_31236": "Notification",
  "1_11839": "Client Portal",
  "1_11826": "When to send",
  "I1_11813;121_58638": "Title",
  "I1_11853;21_22137": "Driver Management",
  "1_11816": "*",
  "I1_11818;250_58177": "Message",
  "I1_11832;30_32137": "Cancel",
  "1_11806": "*",
  "286_32153": "Immediately",
  "1_11869": "Notification",
  "1_11811": "*",
  "I1_11800;35_49552;28_31243": "Edit Notification",
  "289_32555": "Upload Image",
  "I1_11841;21_22137": "Top",
  "I1_11800;35_49551;28_31261": ">",
  "I1_11843;23_39072": "Company setting",
  "I1_11848;23_39072": "Vehicle Management",
  "I1_11808;75_53865;250_37573": "Select Segment",
  "I1_12073;23_39072": "Company setting",
  "I1_12071;21_22137": "Top",
  "I1_12103;250_58575": "Segment",
  "I1_11993;35_49552;28_31243": "Edit Segment",
  "258_31980": "Organization",
  "I1_11994;35_49503": "Edit Segment",
  "258_31970": "Specific Organization",
  "258_31956": "Segment Type",
  "258_31988": "User",
  "I1_12078;23_39072": "Vehicle Management",
  "I258_31990;185_68545": "Select User",
  "258_31974": "*",
  "1_12069": "Client Portal",
  "I1_11993;35_49548;28_31236": "Notification",
  "I1_12091;23_39072": "Approval",
  "I258_32017;30_32140": "Next",
  "258_31955": "*",
  "258_31984": "*",
  "I1_12083;21_22137": "Driver Management",
  "I1_11993;35_49550;28_31236": "Segment",
  "I1_11993;35_49549;28_31261": ">",
  "I258_31986;121_59015": "Select Role",
  "1_12087": "Chauffeur Connect",
  "I258_31981;121_59015": "Select Organization",
  "I258_31991;30_32140": "Search",
  "I258_31994;30_32140": "Add Target",
  "I1_12084;21_22137": "Passenger Management",
  "1_12067": "5",
  "258_31951": "Title",
  "I1_12102;250_58566": "Send Notification",
  "258_31975": "Target",
  "I258_32016;30_32137": "Cancel",
  "I1_11993;35_49551;28_31261": ">",
  "I1_12089;23_39072": "Work Management",
  "258_31950": "*",
  "I258_31952;121_58638": "Title",
  "258_31963": "All Organization",
  "258_31979": "*",
  "1_12099": "Notification",
  "258_31985": "Role",
  "I1_12376;35_49548;28_31236": "Notification",
  "I1_12376;35_49549;28_31261": ">",
  "258_31787": "*",
  "I257_31439;75_53807": "Title",
  "258_31780": "*",
  "257_31437": "*",
  "I1_12377;35_49503": "Add Segment",
  "I1_12471;21_22137": "Top",
  "I1_12473;23_39072": "Company setting",
  "1_12469": "Client Portal",
  "I1_12484;21_22137": "Passenger Management",
  "1_12499": "Notification",
  "257_31442": "*",
  "257_31443": "Segment Type",
  "257_31450": "All Organization",
  "258_31781": "Target",
  "1_12487": "Chauffeur Connect",
  "I1_12502;250_58566": "Send Notification",
  "257_31438": "Title",
  "258_31788": "Role",
  "1_12467": "5",
  "I1_12491;23_39072": "Approval",
  "I1_12483;21_22137": "Driver Management",
  "I257_31512;30_32140": "Next",
  "I1_12376;35_49551;28_31261": ">",
  "I1_12489;23_39072": "Work Management",
  "I1_12478;23_39072": "Vehicle Management",
  "I1_12503;250_58575": "Segment",
  "257_31456": "Specific Organization",
  "I258_31792;30_32140": "Add Role",
  "I258_31789;121_59006": "Select Role",
  "I1_12376;35_49552;28_31243": "Add Segment",
  "I257_31511;30_32137": "Cancel",
  "I1_12376;35_49550;28_31236": "Segment",
  "258_31898": "Role",
  "258_31864": "Title",
  "I1_12747;21_22137": "Driver Management",
  "258_31883": "Specific Organization",
  "I258_31904;30_32081": "Search",
  "258_31897": "*",
  "I1_12640;35_49595;28_31236": "Notification",
  "I258_31899;121_59018": "Select Role",
  "258_31893": "Organization",
  "I1_12766;250_58566": "Send Notification",
  "258_31892": "*",
  "258_31868": "*",
  "I1_12737;23_39072": "Company setting",
  "I258_31903;185_68545": "Select User",
  "I1_12735;21_22137": "Top",
  "I1_12641;35_49503": "Preview Segment",
  "1_12763": "Notification",
  "I258_31929;30_32137": "Back",
  "I1_12753;23_39072": "Work Management",
  "I258_31894;121_59018": "Select Organization",
  "I258_31907;30_32081": "Add Target",
  "I1_12640;35_49607;28_31261": ">",
  "I1_12742;23_39072": "Vehicle Management",
  "I1_12640;35_49596;28_31261": ">",
  "I258_31930;30_32140": "Save",
  "I1_12755;23_39072": "Approval",
  "1_12733": "Client Portal",
  "I1_12767;250_58575": "Segment",
  "258_31876": "All Organization",
  "I1_12640;35_49599;28_31236": "Create Segment",
  "258_31887": "*",
  "258_31901": "User",
  "I1_12640;35_49597;28_31236": "Segment",
  "258_31888": "Target",
  "1_12731": "5",
  "I1_12748;21_22137": "Passenger Management",
  "I1_12640;35_49605;28_31243": "Preview Segment",
  "I1_12640;35_49598;28_31261": ">",
  "258_31863": "*",
  "1_12751": "Chauffeur Connect",
  "258_31869": "Segment Type",
  "I258_31865;185_68545": "Title",
  "I1_12867;21_22137": "Top",
  "I258_32067;185_68545": "Select User",
  "258_32065": "User",
  "258_32062": "Role",
  "I258_32073;30_32137": "Back",
  "258_32027": "*",
  "1_12863": "5",
  "258_32040": "All Organization",
  "258_32028": "Title",
  "I1_12880;21_22137": "Passenger Management",
  "I1_12772;35_49605;28_31243": "Preview Segment",
  "I1_12887;23_39072": "Approval",
  "I1_12772;35_49598;28_31261": ">",
  "258_32061": "*",
  "1_12883": "Chauffeur Connect",
  "I258_32029;185_68545": "Title",
  "I258_32058;121_59018": "Select Organization",
  "I258_32074;30_32140": "Save",
  "I1_12772;35_49607;28_31261": ">",
  "I1_12898;250_58566": "Send Notification",
  "258_32033": "Segment Type",
  "I1_12879;21_22137": "Driver Management",
  "I258_32068;30_32081": "Search",
  "I1_12773;35_49503": "Preview Segment",
  "I1_12899;250_58575": "Segment",
  "258_32056": "*",
  "258_32057": "Organization",
  "I1_12772;35_49599;28_31236": "Edit Segment",
  "I1_12772;35_49596;28_31261": ">",
  "258_32032": "*",
  "1_12895": "Notification",
  "I1_12885;23_39072": "Work Management",
  "I1_12874;23_39072": "Vehicle Management",
  "258_32052": "Target",
  "258_32047": "Specific Organization",
  "258_32051": "*",
  "I258_32071;30_32081": "Add Target",
  "I1_12772;35_49595;28_31236": "Notification",
  "1_12865": "Client Portal",
  "I1_12869;23_39072": "Company setting",
  "I1_12772;35_49597;28_31236": "Segment",
  "I258_32063;121_59018": "Select Role",
  "1_12916": "Title",
  "I1_12937;30_32137": "Save as draft",
  "I1_12959;21_22137": "Passenger Management",
  "1_12915": "*",
  "I1_12904;35_49607;28_31261": ">",
  "I1_12938;30_32140": "Publish",
  "I1_12936;30_32137": "Back",
  "I1_12958;21_22137": "Driver Management",
  "I1_12917;185_68545": "Title",
  "I1_12927;185_68545": "Destination URL",
  "I1_12948;23_39072": "Company setting",
  "I1_12905;35_49503": "Preview Notification",
  "I1_12966;23_39072": "Approval",
  "I1_12912;75_53865;250_37580": "Select Segment",
  "I1_12904;35_49596;28_31261": ">",
  "I1_12977;250_58575": "Send Notification",
  "I289_32547;289_32078": "Upload",
  "1_12962": "Chauffeur Connect",
  "1_12944": "Client Portal",
  "1_12930": "When to send",
  "I1_12964;23_39072": "Work Management",
  "I1_12922;185_68601": "Message",
  "1_12910": "*",
  "286_32111": "Immediately",
  "1_12942": "5",
  "I1_12978;250_58566": "Segment",
  "I1_12904;35_49605;28_31243": "Preview Notification",
  "1_12926": "Destination URL",
  "I1_12953;23_39072": "Vehicle Management",
  "1_12911": "Segment",
  "286_32117": "Specific Organization",
  "I1_12904;35_49598;28_31261": ">",
  "I1_12904;35_49599;28_31236": "Add Notification",
  "1_12974": "Notification",
  "I1_12904;35_49597;28_31236": "Send Notification",
  "I1_12946;21_22137": "Top",
  "1_12921": "Message",
  "I1_12904;35_49595;28_31236": "Notification",
  "1_12920": "*",
  "289_32545": "Upload Image",
  "I1_13015;30_32137": "Cancel",
  "I1_13006;185_68545": "Destination URL",
  "1_12995": "Title",
  "1_12989": "*",
  "I1_13041;23_39072": "Work Management",
  "I1_13030;23_39072": "Vehicle Management",
  "1_13000": "Message",
  "I1_13055;250_58566": "Segment",
  "1_13039": "Chauffeur Connect",
  "1_12990": "Segment",
  "I1_13036;21_22137": "Passenger Management",
  "1_13051": "Notification",
  "1_13009": "When to send",
  "I1_12983;35_49551;28_31261": ">",
  "I289_32537;289_32078": "Upload",
  "I1_12996;185_68545": "Title",
  "1_13019": "5",
  "286_32131": "Specific Organization",
  "I1_12983;35_49549;28_31261": ">",
  "I1_12983;35_49548;28_31236": "Notification",
  "1_12999": "*",
  "I1_12983;35_49552;28_31243": "View Notification",
  "I1_13054;250_58575": "Send Notification",
  "I1_13043;23_39072": "Approval",
  "1_12994": "*",
  "286_32125": "Immediately",
  "I1_13001;185_68601": "Message",
  "289_32535": "Upload Image",
  "I1_12991;75_53865;250_37580": "Select Segment",
  "1_13021": "Client Portal",
  "I1_12984;35_49503": "View Notification",
  "I1_13023;21_22137": "Top",
  "1_13005": "Destination URL",
  "I1_13025;23_39072": "Company setting",
  "I1_13035;21_22137": "Driver Management",
  "I1_12983;35_49550;28_31236": "Send Notification",
  "1_13116": "Sa",
  "I1_13121;8068_76053": "27",
  "I1_13170;8068_76055": "20",
  "1_13104": "Su",
  "I1_13080;611_95467;30_32137": "Cancel",
  "I1_13163;8068_76055": "5",
  "I1_13131;8068_76053": "7",
  "I1_13080;611_93748": "Warning",
  "I1_13161;8068_76055": "3",
  "I1_13173;8068_76055": "23",
  "I1_13083;75_53939;497_42334": "Status",
  "I1_13151;8068_76053": "24",
  "I1_13143;8068_76053": "17",
  "I1_13188;8068_76055": "13",
  "1_13114": "Fr",
  "I1_13175;8068_76055": "",
  "I1_13082;75_53940;75_53852;75_53818": "Immediatly",
  "I1_13146;8068_76053": "20",
  "I1_13136;8068_76053": "11",
  "1_13112": "Th",
  "I1_13127;8068_76053": "3",
  "I1_13074;7636_13405;121_58638": "Updated",
  "I1_13072;7636_13403": "Requestor",
  "I1_13147;8068_76053": "21",
  "I1_13192;30_32018": "Now",
  "I1_13144;8068_76053": "18",
  "I1_13183;8068_76055": "08",
  "I1_13133;8068_76053": "9",
  "I1_13193;30_32021": "Ok",
  "I1_13172;8068_76055": "22",
  "I1_13181;8068_76055": "06",
  "I1_13089;30_32128": "End User",
  "I1_13080;611_93749": "Are you sure to delete Notification ?",
  "1_13106": "Mo",
  "I1_13165;8068_76055": "7",
  "I1_13082;75_53939;497_42334": "Type",
  "I1_13171;8068_76055": "21",
  "I1_13120;8068_76055": "26",
  "I1_13071;7636_13405;121_58638": "235",
  "I1_13185;8068_76055": "10",
  "I1_13174;8068_76055": "",
  "I1_13186;8068_76055": "11",
  "I1_13145;8068_76053": "19",
  "1_13065": "Approval Confirmation",
  "I1_13177;8068_76055": "",
  "I1_13157;8068_76055": "30",
  "I1_13159;8068_76055": "1",
  "I1_13153;8068_76053": "26",
  "I1_13132;8068_76053": "8",
  "I1_13070;7636_13403": "Approval Flow",
  "1_13108": "Tu",
  "I1_13075;7636_13405;121_58638": "2023-12-23 14:24:30",
  "I1_13073;7636_13405;121_58638": "Pending",
  "I1_13128;8068_76053": "4",
  "I1_13160;8068_76055": "2",
  "I1_13187;8068_76055": "12",
  "1_13098": "Mar 2024",
  "I1_13080;611_95468;611_95349": "Confirm",
  "I1_13135;8068_76053": "10",
  "I1_13087;30_32140": "Add",
  "I1_13083;75_53940;75_53852;75_53818": "Publish",
  "I1_13138;8068_76053": "13",
  "I1_13060;30_31919": "Delete",
  "I1_13061;30_32128": "View Statistics",
  "I1_13156;8068_76055": "29",
  "I1_13149;8068_76053": "23",
  "I1_13155;8068_76053": "28",
  "I1_13141;8068_76053": "16",
  "I1_13083;75_53940;75_53853;75_53818": "Draft",
  "I1_13189;8068_76055": "14",
  "I1_13164;8068_76055": "6",
  "I1_13059;30_32128": "Edit",
  "I1_13078;30_32137": "Cancel",
  "I1_13119;8068_76055": "25",
  "I1_13122;8068_76053": "28",
  "I1_13137;8068_76053": "12",
  "I1_13140;8068_76053": "15",
  "I1_13079;30_32140": "Request for Approval",
  "I1_13070;7636_13405;121_58638": "Flow Name (Click to view)",
  "I1_13075;7636_13403": "Request date",
  "I1_13148;8068_76053": "22",
  "I1_13130;8068_76051": "6",
  "I1_13090;30_32128": "Portal User",
  "I1_13074;7636_13403": "After status",
  "I1_13071;7636_13403": "Approval ID",
  "I1_13124;8068_76053": "1",
  "I1_13082;75_53940;75_53853;75_53818": "Schedule",
  "I1_13176;8068_76055": "",
  "I1_13129;8068_76053": "5",
  "I1_13125;8068_76053": "2",
  "I1_13162;8068_76055": "4",
  "I1_13152;8068_76053": "25",
  "I1_13072;7636_13405;121_58638": "OM Staff - Bien Hoang",
  "I1_13184;8068_76055": "09",
  "I1_13139;8068_76053": "14",
  "I1_13178;8068_76055": "",
  "1_13110": "We",
  "I1_13073;7636_13403": "Current status",
  "I1_13182;8068_76055": "07",
  "I1_13123;8068_76053": "29",
  "I1_13154;8068_76053": "27",
  "1_13209": "Title",
  "1_13208": "*",
  "I1_13239;21_22137": "Top",
  "I1_13197;35_49598;28_31261": ">",
  "I1_13231;30_32140": "Send",
  "1_13203": "*",
  "1_13237": "Client Portal",
  "I1_13252;21_22137": "Passenger Management",
  "I1_13220;185_68545": "Destination URL",
  "1_13214": "Message",
  "1_13219": "Destination URL",
  "I1_13210;185_68545": "Title",
  "289_32565": "Upload Image",
  "I1_13197;35_49597;28_31236": "Send Notification",
  "1_13235": "5",
  "I1_13251;21_22137": "Driver Management",
  "I1_13270;250_58575": "Send Notification",
  "1_13255": "Chauffeur Connect",
  "I1_13197;35_49605;28_31243": "Preview Notification",
  "I289_32567;289_32078": "Upload",
  "1_13204": "Segment",
  "I1_13241;23_39072": "Company setting",
  "I1_13197;35_49599;28_31236": "Edit Notification",
  "I1_13197;35_49607;28_31261": ">",
  "1_13223": "When to send",
  "I1_13230;30_32137": "Save as draft",
  "I1_13215;185_68601": "Message",
  "I1_13229;30_32137": "Back",
  "I1_13197;35_49595;28_31236": "Notification",
  "1_13267": "Notification",
  "I1_13271;250_58566": "Segment",
  "1_13213": "*",
  "I1_13259;23_39072": "Approval",
  "I1_13257;23_39072": "Work Management",
  "I1_13197;35_49596;28_31261": ">",
  "I1_13246;23_39072": "Vehicle Management",
  "286_32173": "Specific Organization",
  "I1_13198;35_49503": "Preview Notification",
  "I1_13205;75_53865;250_37580": "Select Segment",
  "286_32167": "Immediately",
  "1_13343": "5",
  "I1_13360;21_22137": "Passenger Management",
  "I1_13296;171_66633": "Title",
  "I1_13305;835_98906": "Notification 1",
  "I1_13347;21_22137": "Top",
  "I1_13322;18_21709": "Draft",
  "I1_13359;21_22137": "Driver Management",
  "I1_13310;18_21709": "Publish",
  "I1_13299;171_66633": "Send Date",
  "I1_13317;835_98906": "Notification 2",
  "I1_13276;28_31263;28_31261": ">",
  "I1_13295;171_66633": "ID",
  "1_13345": "Client Portal",
  "I1_13339;581_1954;7882_26275;433_34538": "11",
  "1_13313": "Action",
  "I1_13339;581_1954;7882_26160;433_34538": "...",
  "I1_13308;18_21709": "01/03/2024",
  "1_13337": "Action",
  "I1_13309;33_33258;33_33254": "Immediately",
  "I1_13349;23_39072": "Company setting",
  "1_13363": "Chauffeur Connect",
  "I1_13321;33_33258;33_33254": "Schedule",
  "I1_13304;18_21709": "30",
  "I1_13354;23_39072": "Vehicle Management",
  "I1_13339;581_1954;7882_21853;433_34538": "2",
  "I1_13328;18_21709": "49",
  "I1_13290;433_34538": "Type",
  "I1_13339;581_1954;7882_21473;497_44116": "1",
  "I1_13365;23_39072": "Work Management",
  "I1_13339;368_110840": "Show 1 to 10 of 100 results",
  "I1_13331;18_21709": "Segment 3",
  "I1_13339;581_1954;7882_21971;433_34538": "3",
  "I1_13285;30_32140": "Add",
  "I1_13300;171_66633": "Type",
  "I1_13292;121_59079": "Search...",
  "I1_13339;581_1954;7882_22097;433_34538": "4",
  "I1_13320;18_21709": "01/02/2024",
  "I1_13302;171_66633": "Action",
  "I1_13316;18_21709": "17",
  "I1_13276;28_31246;28_31236": "Notification",
  "1_13375": "Notification",
  "I1_13334;18_21709": "Publish",
  "I1_13276;28_31248;28_31243": "Send Notification",
  "I1_13339;581_1954;7882_19131;497_44116": "10 / page",
  "I1_13367;23_39072": "Approval",
  "I1_13298;171_66633": "Segment",
  "I1_13329;835_98906": "Notification 3",
  "I1_13332;18_21709": "01/01/2024",
  "I1_13319;18_21709": "Segment 2",
  "I1_13333;33_33258;33_33254": "Immediately",
  "I1_13301;171_66633": "Status",
  "I1_13339;581_1954;7882_26229;433_34538": "10",
  "I1_13378;250_58575": "Send Notification",
  "1_13325": "Action",
  "I1_13379;250_58566": "Segment",
  "I1_13307;18_21709": "Segment 1",
  "1_13282": "Send Notification",
  "I1_13291;433_34538": "Status",
  "1_13615": "Chauffeur Connect",
  "I1_13630;250_58575": "Send Notification",
  "I1_13586;35_49550;28_31236": "Send Notification",
  "1_13627": "Notification",
  "I1_13606;23_39072": "Vehicle Management",
  "I1_13611;21_22137": "Driver Management",
  "I1_13601;23_39072": "Company setting",
  "1_13597": "Client Portal",
  "I1_13587;35_49503": "View Statistics",
  "I1_13619;23_39072": "Approval",
  "1_13590": "Coming Soon",
  "1_13595": "5",
  "I1_13617;23_39072": "Work Management",
  "I1_13586;35_49551;28_31261": ">",
  "I1_13612;21_22137": "Passenger Management",
  "I1_13591;30_32137": "Cancel",
  "I1_13586;35_49549;28_31261": ">",
  "I1_13631;250_58566": "Segment",
  "I1_13586;35_49548;28_31236": "Notification",
  "I1_13586;35_49552;28_31243": "View Statistics",
  "I1_13599;21_22137": "Top",
  "I1_13738;30_32128": "Portal User",
  "I1_13741;30_31919": "Delete",
  "I258_32606;433_34538": "Activity Type",
  "I258_32748;18_21709": "Brian Baker",
  "I258_32626;18_21709": "Jennifer Robinson",
  "I258_32617;171_66633": "Vehicle",
  "I258_32637;18_21709": "xxxx@gmail.com",
  "I258_32611;171_66633": "ID",
  "I258_32648;581_1954;7882_19131;497_44116": "10 / page",
  "I1_13742;611_95468;611_95349": "Confirm",
  "I258_32648;581_1954;7882_26275;433_34538": "11",
  "I258_32607;121_59079": "Search...",
  "I258_32648;581_1954;7882_26160;433_34538": "...",
  "I1_13742;611_93748": "Warning",
  "I1_13737;30_32128": "End User",
  "I258_32747;835_98906": "45",
  "I258_32648;581_1954;7882_22097;433_34538": "4",
  "258_32602": "Select User",
  "I258_32738;18_21709": "xxxx@gmail.com",
  "I258_32648;368_110840": "Show 1 to 10 of 100 results",
  "I258_32736;835_98906": "38",
  "I258_32616;171_66633": "Driver",
  "I1_13740;30_32128": "Edit",
  "I258_32627;18_21709": "xxxx@gmail.com",
  "I258_32648;581_1954;7882_21473;497_44116": "1",
  "I258_32651;30_32137": "Cancel",
  "I258_32621;835_98906": "98",
  "I258_32749;18_21709": "xxxx@gmail.com",
  "I258_32648;581_1954;7882_21971;433_34538": "3",
  "I258_32631;835_98906": "51",
  "I258_32652;30_32140": "Save",
  "I258_32648;581_1954;7882_21853;433_34538": "2",
  "I258_32737;18_21709": "Joshua Davis",
  "I258_32648;581_1954;7882_26229;433_34538": "10",
  "I258_32636;18_21709": "Maria White",
  "I1_13742;611_93749": "Are you sure to delete Segment ?",
  "I258_32196;30_32128": "Driver",
  "I1_13742;611_95467;30_32137": "Cancel",
  "I258_32172;30_32128": "Admin",
  "I258_32174;30_32128": "Passenger",
  "1_13809": "File Name 3 (1.246 KB)",
  "I1_13840;23_39084": "Vehicle Management",
  "I1_13854;23_39072": "Notification",
  "1_13818": "File Name 4 (1.246 KB)",
  "I1_13815;33_33254": "Completed",
  "1_13822": "03/01/2024 09:52:39",
  "1_13831": "Client Portal",
  "I1_13835;23_39072": "Company setting",
  "1_13776": "Status",
  "I1_13849;23_39072": "Approval",
  "I1_13824;33_33254": "Cancel",
  "1_13829": "5",
  "I1_13825;581_1954;7882_21971;433_34538": "3",
  "1_13800": "File Name 2 (1.246 KB)",
  "I1_13847;23_39072": "Work Management",
  "I1_13825;581_1954;7882_26160;433_34538": "...",
  "1_13788": "Device Status",
  "1_13791": "File Name 1 (1.246 KB)",
  "I1_13825;581_1954;7882_21853;433_34538": "2",
  "1_13795": "10/02/2024 09:52:39",
  "I1_13825;581_1954;7882_26229;433_34538": "10",
  "1_13811": "User ID 3",
  "1_13771": "Export Management",
  "1_13804": "10/01/2024 09:52:39",
  "1_13793": "User ID 1",
  "1_13786": "Assigned Organization",
  "I1_13825;581_1954;7882_21473;497_44116": "1",
  "I1_13833;21_27541": "Top",
  "I1_13825;581_1954;7882_22097;433_34538": "4",
  "1_13802": "User ID 2",
  "1_13782": "Device Name",
  "I1_13825;368_110840": "Show 1 to 10 of 100 results",
  "I1_13797;33_33254": "Completed",
  "I1_13806;33_33254": "In Progress",
  "I1_13825;581_1954;7882_26275;433_34538": "11",
  "I1_13825;581_1954;7882_19131;497_44116": "10 / page",
  "1_13813": "05/01/2024 09:52:39",
  "1_13820": "User ID 4",
  "1_13845": "Chauffeur Connect",
  "1_13784": "Device Number",
  "I1_13841;21_22137": "Driver Management",
  "I1_13842;21_22137": "Passenger Management",
  "I1_13959;21_22137": "Driver Management",
  "I1_13887;18_21709": "Ride",
  "I1_13876;171_66633": "Active Type",
  "I1_13949;23_39072": "Company setting",
  "I1_13926;33_33258;33_33254": "Completed",
  "I1_13939;581_1954;7882_19131;497_44116": "10 / page",
  "I1_13878;171_66633": "Passenger",
  "1_13943": "5",
  "1_13945": "Client Portal",
  "I1_13973;23_39072": "Work Management",
  "I1_13893;33_33258;33_33254": "On-going",
  "I1_13879;171_66633": "Department",
  "I1_13890;18_21709": "Sales",
  "I1_13939;368_110840": "Show 1 to 10 of 100 results",
  "I1_13903;18_21709": "Mazda CX-5",
  "1_13964": "Chauffeur Connect",
  "I1_13925;18_21709": "Mazda CX-5",
  "I1_13886;835_98906": "39",
  "I1_13927;528_68898;30_31886": "Delete",
  "I1_13902;18_21709": "Jennyfer Edwards",
  "I1_13880;171_66633": "Driver",
  "I1_13904;33_33258;33_33254": "Upcoming",
  "I1_13921;18_21709": "01/02/2024 08:30",
  "1_13972": "Setting",
  "I1_13936;18_21709": "Mazda CX-5",
  "I1_13931;18_21709": "Package",
  "I1_13947;21_22137": "Top",
  "I1_13915;33_33258;33_33254": "Upcoming",
  "I1_13954;23_39072": "Vehicle Management",
  "I1_13911;18_21709": "Anthony Moore",
  "I1_13939;581_1954;7882_21971;433_34538": "3",
  "I1_13939;581_1954;7882_22097;433_34538": "4",
  "I1_13881;171_66633": "Vehicle",
  "I1_13905;528_68898;30_31886": "Delete",
  "I1_13898;18_21709": "Meeting",
  "I1_13980;23_39072": "Notification",
  "I1_13868;30_32021": "Export",
  "I1_13900;18_21709": "Anthony Davis",
  "I1_13922;18_21709": "Anthony White",
  "I1_13862;28_31248;28_31243": "Activity List",
  "I1_13862;28_31246;28_31236": "Chauffeur Connect",
  "I1_13889;18_21709": "Jennifer Lopez",
  "I1_13871;121_59079": "Search...",
  "I1_13960;21_22137": "Passenger Management",
  "I1_13924;18_21709": "Sergio Fernandez",
  "I1_13939;581_1954;7882_21473;497_44116": "1",
  "I1_13912;18_21709": "Sales",
  "I1_13869;433_34538": "Active Type",
  "I1_13939;581_1954;7882_26160;433_34538": "...",
  "I1_13914;18_21709": "Mazda CX-5",
  "I1_13919;835_98906": "65",
  "I1_13865;75_53865;433_34534": "List ",
  "I1_13862;28_31263;28_31261": ">",
  "I1_13935;18_21709": "Emily Johnson",
  "I1_13909;18_21709": "Airport",
  "I1_13863;388_23148;30_32140": "Create",
  "I1_13975;23_39072": "Approval",
  "I1_13870;433_34538": "Status",
  "I1_13899;18_21709": "01/02/2024 08:30",
  "I1_13923;18_21709": "Sales",
  "I1_13939;581_1954;7882_21853;433_34538": "2",
  "I1_13901;18_21709": "Sales",
  "I1_13939;581_1954;7882_26229;433_34538": "10",
  "1_13969": "Activity List",
  "I1_13913;18_21709": "Brandon Taylor",
  "I1_13908;835_98906": "10",
  "I1_13932;18_21709": "01/02/2024 08:30",
  "I1_13916;528_68898;30_31886": "Delete",
  "I1_13891;18_21709": "Lauren Martinez",
  "I1_13934;18_21709": "Sales",
  "I1_13920;18_21709": "Rental",
  "I1_13937;33_33258;33_33254": "Cancelled",
  "I1_13897;835_98906": "75",
  "I1_13883;171_66633": "Action",
  "I1_13875;171_66633": "ID",
  "I1_13888;18_21709": "01/02/2024 08:30",
  "I1_13894;528_68898;30_31886": "Delete",
  "I1_13863;388_23101": "Activity List",
  "I1_13930;835_98906": "63",
  "I1_13933;18_21709": "Brian Harris",
  "I1_13877;171_66633": "Pick Up ",
  "I1_13882;171_66633": "Status",
  "I1_13938;528_68898;30_31886": "Delete",
  "I1_13892;18_21709": "Mazda CX-5",
  "I1_13939;581_1954;7882_26275;433_34538": "11",
  "I1_13910;18_21709": "01/02/2024 08:30",
  "I1_14046;75_53940;75_53854;75_53818": "Week ",
  "I1_14049;30_32128": "Ride",
  "I1_14053;30_32128": "Package",
  "I1_14052;30_32128": "Rental",
  "I1_14046;75_53940;75_53852;75_53818": "List ",
  "I1_14050;30_32128": "Meeting",
  "I1_14046;75_53940;75_53853;75_53818": "Day ",
  "I1_14046;75_53940;75_53855;75_53818": "Month ",
  "I1_14051;30_32128": "Airport",
  "I1_14171;23_39072": "Vehicle Management",
  "I1_14197;23_39072": "Notification",
  "I1_14100;7757_34969;7757_23303": "09:00 - 17:00 ",
  "I1_14076;999_148537": "Vehicle",
  "I1_14109;7757_39677": "+3 more",
  "I1_14156;8071_88467": "11",
  "1_14134": "09:00 - 17:00 ",
  "I1_14119;8071_88467": "19",
  "1_14189": "Setting",
  "1_14085": "Sun",
  "I1_14118;7757_34592;7757_23302": "Ride",
  "I1_14154;8071_88467": "9",
  "I1_14066;30_32140": "Create",
  "I1_14111;8071_88467": "12",
  "I1_14142;8071_88467": "29",
  "I1_14100;7757_39677": "+3 more",
  "1_14063": "Activity List",
  "1_14162": "Client Portal",
  "I1_14105;8071_88467": "7",
  "1_14091": "Wed",
  "I1_14146;8071_88467": "2",
  "I1_14116;8071_88467": "16",
  "1_14089": "Tue",
  "I1_14109;7757_34969;7757_23302": "Ride",
  "I1_14057;28_31246;28_31236": "Chauffeur Connect",
  "I1_14125;8071_88467": "24",
  "I1_14073;30_32137": "Today",
  "I1_14118;7757_34592;7757_23303": "09:00 - 17:00 ",
  "1_14136": "+3 more",
  "I1_14112;8071_88467": "13",
  "I1_14099;8071_88467": "1",
  "I1_14144;8071_88467": "31",
  "1_14097": "Sat",
  "I1_14100;7757_34809;7757_23303": "09:00 - 17:00 ",
  "I1_14057;28_31248;28_31243": "Activity List",
  "I1_14120;8071_88467": "20",
  "I1_14190;23_39072": "Work Management",
  "I1_14176;21_22137": "Driver Management",
  "I1_14101;8071_88467": "3",
  "I1_14124;8071_88467": "23",
  "I1_14103;8071_88467": "5",
  "I1_14102;8071_88467": "4",
  "I1_14079;75_53865;497_44120": "Month ",
  "I1_14117;8071_88467": "17",
  "I1_14057;28_31263;28_31261": ">",
  "I1_14177;21_22137": "Passenger Management",
  "I1_14151;8071_88467": "6",
  "I1_14155;8071_88467": "10",
  "I1_14123;8071_88467": "22",
  "I1_14115;8071_88467": "15",
  "I1_14143;8071_88467": "30",
  "I1_14078;75_53865;433_34534": "xxx-xxx",
  "1_14181": "Chauffeur Connect",
  "I1_14118;7757_34809;7757_23302": "Ride",
  "I1_14108;8071_88467": "9",
  "I1_14118;7757_34809;7757_23303": "09:00 - 17:00 ",
  "I1_14077;121_59049": "Search...",
  "1_14074": "January 2024",
  "I1_14100;7757_34592;7757_23302": "Ride",
  "I1_14129;7757_23303": "09:00 - 17:00 ",
  "I1_14109;7757_34969;7757_23303": "09:00 - 17:00 ",
  "I1_14100;8071_88467": "2",
  "I1_14110;8071_88467": "11",
  "I1_14153;8071_88467": "8",
  "I1_14166;23_39072": "Company setting",
  "I1_14109;7757_34809;7757_23303": "09:00 - 17:00 ",
  "I1_14129;7757_23302": "Ride",
  "I1_14139;8071_88467": "27",
  "1_14095": "Fri",
  "I1_14100;7757_34592;7757_23303": "09:00 - 17:00 ",
  "1_14186": "Activity List",
  "I1_14076;999_148541": "Driver",
  "I1_14147;8071_88467": "3",
  "1_14160": "5",
  "I1_14109;7757_34809;7757_23302": "Ride",
  "I1_14109;7757_34592;7757_23303": "09:00 - 17:00 ",
  "I1_14109;7757_34592;7757_23302": "Ride",
  "1_14131": "Ride",
  "I1_14126;8071_88467": "25",
  "I1_14107;8071_88467": "8",
  "I1_14104;8071_88467": "6",
  "I1_14121;8071_88467": "21",
  "I1_14145;8071_88467": "1",
  "1_14138": "26",
  "1_14087": "Mon",
  "I1_14113;8071_88467": "14",
  "I1_14118;8071_88467": "18",
  "I1_14152;8071_88467": "7",
  "I1_14140;8071_88467": "28",
  "I1_14192;23_39072": "Approval",
  "I1_14109;8071_88467": "10",
  "I1_14164;21_22137": "Top",
  "I1_14148;8071_88467": "4",
  "I1_14100;7757_34969;7757_23302": "Ride",
  "I1_14100;7757_34809;7757_23302": "Ride",
  "1_14093": "Thu",
  "I1_14150;8071_88467": "5",
  "1_14239": "Tue",
  "I1_14294;8071_88467": "31",
  "I1_14300;8071_88467": "5",
  "I1_14206;28_31248;28_31243": "Activity List",
  "I1_14253;8071_88467": "5",
  "I1_14250;7757_34809;7757_23303": "09:00 - 17:00 ",
  "1_14339": "Setting",
  "I1_14206;28_31246;28_31236": "Chauffeur Connect",
  "I1_14290;8071_88467": "28",
  "I1_14268;7757_34592;7757_23303": "09:00 - 17:00 ",
  "I1_14259;7757_34969;7757_23302": "Ride",
  "1_14243": "Thu",
  "I1_14327;21_22137": "Passenger Management",
  "I1_14289;8071_88467": "27",
  "I1_14270;8071_88467": "20",
  "I1_14279;7757_23303": "09:00 - 17:00 ",
  "I1_14229;75_53865;433_34534": "Month ",
  "I1_14254;8071_88467": "6",
  "I1_14222;30_32137": "Today",
  "I1_14269;8071_88467": "19",
  "I1_14263;8071_88467": "14",
  "I1_14262;8071_88467": "13",
  "I1_14276;8071_88467": "25",
  "I1_14259;7757_34969;7757_23303": "09:00 - 17:00 ",
  "I1_14226;999_148513": "Driver",
  "I1_14250;7757_39677": "+3 more",
  "I1_14265;8071_88467": "15",
  "I1_14279;7757_23302": "Ride",
  "I1_14259;8071_88467": "10",
  "I1_14316;23_39072": "Company setting",
  "I1_14268;7757_34809;7757_23303": "09:00 - 17:00 ",
  "I1_14314;21_22137": "Top",
  "1_14235": "Sun",
  "I1_14259;7757_34809;7757_23302": "Ride",
  "I1_14292;8071_88467": "29",
  "I1_14266;8071_88467": "16",
  "I1_14305;8071_88467": "10",
  "I1_14227;121_59049": "Search...",
  "I1_14273;8071_88467": "22",
  "1_14245": "Fri",
  "I1_14250;8071_88467": "2",
  "I1_14259;7757_34592;7757_23302": "Ride",
  "1_14212": "Activity List",
  "1_14286": "+3 more",
  "1_14331": "Chauffeur Connect",
  "I1_14261;8071_88467": "12",
  "1_14237": "Mon",
  "I1_14226;999_148509": "Vehicle",
  "I1_14206;28_31263;28_31261": ">",
  "I1_14342;23_39072": "Approval",
  "I1_14215;30_32140": "Create",
  "I1_14258;8071_88467": "9",
  "1_14247": "Sat",
  "I1_14268;7757_34809;7757_23302": "Ride",
  "1_14310": "5",
  "I1_14302;8071_88467": "7",
  "I1_14297;8071_88467": "3",
  "I1_14306;8071_88467": "11",
  "I1_14268;8071_88467": "18",
  "1_14288": "26",
  "I1_14271;8071_88467": "21",
  "I1_14304;8071_88467": "9",
  "I1_14257;8071_88467": "8",
  "I1_14259;7757_34809;7757_23303": "09:00 - 17:00 ",
  "1_14284": "09:00 - 17:00 ",
  "I1_14228;75_53865;433_34534": "xxx-xxx",
  "1_14281": "Ride",
  "I1_14259;7757_34592;7757_23303": "09:00 - 17:00 ",
  "I1_14252;8071_88467": "4",
  "I1_14274;8071_88467": "23",
  "I1_14268;7757_34592;7757_23302": "Ride",
  "I1_14303;8071_88467": "8",
  "I1_14298;8071_88467": "4",
  "I1_14260;8071_88467": "11",
  "I1_14250;7757_34809;7757_23302": "Ride",
  "1_14312": "Client Portal",
  "I1_14250;7757_34969;7757_23302": "Ride",
  "I1_14340;23_39072": "Work Management",
  "I1_14347;23_39072": "Notification",
  "1_14241": "Wed",
  "I1_14250;7757_34969;7757_23303": "09:00 - 17:00 ",
  "I1_14259;7757_39677": "+3 more",
  "I1_14255;8071_88467": "7",
  "I1_14301;8071_88467": "6",
  "I1_14251;8071_88467": "3",
  "I1_14250;7757_34592;7757_23303": "09:00 - 17:00 ",
  "I1_14275;8071_88467": "24",
  "I1_14267;8071_88467": "17",
  "I1_14295;8071_88467": "1",
  "I1_14296;8071_88467": "2",
  "I1_14321;23_39072": "Vehicle Management",
  "1_14336": "Activity List",
  "I1_14326;21_22137": "Driver Management",
  "1_14223": "January 2024",
  "I1_14249;8071_88467": "1",
  "I1_14250;7757_34592;7757_23302": "Ride",
  "I1_14293;8071_88467": "30",
  "I1_14438;8071_88467": "4",
  "1_14373": "January 2024",
  "1_14384": "Sun",
  "I1_14409;8071_88467": "10",
  "1_14392": "Thu",
  "I1_14459;23_39072": "Vehicle Management",
  "I1_14376;121_59049": "Search...",
  "1_14448": "5",
  "I1_14427;8071_88467": "26",
  "1_14474": "Activity List",
  "I1_14408;8071_88467": "9",
  "I1_14377;75_53865;433_34534": "Number Plate",
  "I1_14454;23_39072": "Company setting",
  "I1_14426;8071_88467": "25",
  "1_14394": "Fri",
  "I1_14403;8071_88467": "5",
  "I1_14430;8071_88467": "28",
  "I1_14404;8071_88467": "6",
  "I1_14407;8071_88467": "8",
  "1_14386": "Mon",
  "I1_14402;8071_88467": "4",
  "I1_14424;8071_88467": "23",
  "I1_14400;8071_88467": "2",
  "I1_14375;999_148537": "Vehicle",
  "I1_14415;8071_88467": "15",
  "1_14469": "Chauffeur Connect",
  "I1_14365;30_32140": "Create",
  "I1_14480;23_39072": "Approval",
  "I1_14435;8071_88467": "2",
  "I1_14378;75_53865;433_34534": "Month ",
  "I1_14411;8071_88467": "12",
  "I1_14398;8071_88467": "31",
  "I1_14356;28_31246;28_31236": "Chauffeur Connect",
  "I1_14465;21_22137": "Passenger Management",
  "I1_14432;8071_88467": "30",
  "1_14477": "Setting",
  "I1_14434;8071_88467": "1",
  "I1_14418;8071_88467": "18",
  "I1_14423;8071_88467": "22",
  "I1_14422;8071_88467": "21",
  "I1_14436;8071_88467": "3",
  "I1_14440;8071_88467": "6",
  "I1_14444;8071_88467": "10",
  "I1_14356;28_31263;28_31261": ">",
  "I1_14401;8071_88467": "13",
  "I1_14420;8071_88467": "20",
  "I1_14412;8071_88467": "13",
  "I1_14452;21_22137": "Top",
  "I1_14441;8071_88467": "7",
  "I1_14439;8071_88467": "5",
  "I1_14431;8071_88467": "29",
  "I1_14417;8071_88467": "17",
  "I1_14356;28_31248;28_31243": "Activity List",
  "I1_14414;8071_88467": "14",
  "I1_14478;23_39072": "Work Management",
  "I1_14410;8071_88467": "11",
  "I1_14464;21_22137": "Driver Management",
  "1_14396": "Sat",
  "I1_14372;30_32137": "Today",
  "I1_14428;8071_88467": "27",
  "1_14390": "Wed",
  "I1_14399;8071_88467": "1",
  "I1_14375;999_148541": "Driver",
  "I1_14416;8071_88467": "16",
  "I1_14433;8071_88467": "31",
  "I1_14442;8071_88467": "8",
  "I1_14485;23_39072": "Notification",
  "I1_14419;8071_88467": "19",
  "1_14362": "Activity List",
  "I1_14425;8071_88467": "24",
  "I1_14406;8071_88467": "7",
  "1_14450": "Client Portal",
  "1_14388": "Tue",
  "I1_14443;8071_88467": "9",
  "I1_14602;21_22137": "Driver Management",
  "1_14522": "Sun",
  "1_14588": "Client Portal",
  "1_14524": "Mon",
  "I1_14579;8071_88467": "7",
  "I1_14494;28_31246;28_31236": "Chauffeur Connect",
  "I1_14547;8071_88467": "10",
  "I1_14597;23_39072": "Vehicle Management",
  "I1_14546;8071_88467": "9",
  "1_14500": "Activity List",
  "I1_14571;8071_88467": "31",
  "I1_14544;8071_88467": "7",
  "I1_14561;8071_88467": "22",
  "I1_14570;8071_88467": "30",
  "I1_14510;30_32137": "Today",
  "1_14607": "Chauffeur Connect",
  "I1_14513;999_148509": "Vehicle",
  "I1_14549;8071_88467": "12",
  "I1_14623;23_39072": "Notification",
  "I1_14618;23_39072": "Approval",
  "I1_14550;8071_88467": "13",
  "I1_14516;75_53865;433_34534": "Month ",
  "I1_14545;8071_88467": "8",
  "I1_14580;8071_88467": "8",
  "I1_14581;8071_88467": "9",
  "I1_14555;8071_88467": "17",
  "I1_14573;8071_88467": "2",
  "1_14530": "Thu",
  "1_14528": "Wed",
  "I1_14553;8071_88467": "15",
  "I1_14566;8071_88467": "27",
  "I1_14554;8071_88467": "16",
  "1_14532": "Fri",
  "I1_14576;8071_88467": "4",
  "I1_14557;8071_88467": "19",
  "I1_14494;28_31248;28_31243": "Activity List",
  "I1_14572;8071_88467": "1",
  "I1_14582;8071_88467": "10",
  "I1_14563;8071_88467": "24",
  "I1_14562;8071_88467": "23",
  "I1_14548;8071_88467": "11",
  "I1_14603;21_22137": "Passenger Management",
  "I1_14542;8071_88467": "6",
  "1_14586": "5",
  "I1_14537;8071_88467": "1",
  "I1_14538;8071_88467": "2",
  "1_14526": "Tue",
  "I1_14494;28_31263;28_31261": ">",
  "1_14511": "January 2024",
  "I1_14592;23_39072": "Company setting",
  "I1_14536;8071_88467": "31",
  "1_14534": "Sat",
  "I1_14578;8071_88467": "6",
  "I1_14515;75_53865;433_34534": "Employee No",
  "I1_14540;8071_88467": "4",
  "1_14612": "Activity List",
  "I1_14569;8071_88467": "29",
  "I1_14565;8071_88467": "26",
  "I1_14574;8071_88467": "3",
  "I1_14541;8071_88467": "5",
  "1_14615": "Setting",
  "I1_14560;8071_88467": "21",
  "I1_14577;8071_88467": "5",
  "I1_14558;8071_88467": "20",
  "I1_14564;8071_88467": "25",
  "I1_14556;8071_88467": "18",
  "I1_14514;121_59049": "Search...",
  "I1_14616;23_39072": "Work Management",
  "I1_14503;30_32140": "Create",
  "I1_14590;21_22137": "Top",
  "I1_14568;8071_88467": "28",
  "I1_14552;8071_88467": "14",
  "I1_14539;8071_88467": "13",
  "I1_14513;999_148513": "Driver",
  "1_14662": "Client Portal",
  "1_14686": "Activity List",
  "I1_14641;881_101975": "Select Passenger",
  "I1_14638;881_101975": "Drop-off Location",
  "I1_14632;35_49550;28_31236": "Activity List",
  "I1_14676;21_22137": "Driver Management",
  "I1_14632;35_49549;28_31261": ">",
  "1_14681": "Chauffeur Connect",
  "I1_14671;23_39072": "Vehicle Management",
  "I1_14639;30_32137": "Add a stop",
  "I1_14643;30_32081": "Confirm and search",
  "I1_14666;23_39072": "Company setting",
  "1_14689": "Setting",
  "I1_14692;23_39072": "Approval",
  "I1_14632;35_49552;28_31243": "Create Ride",
  "I1_14644;30_32137": "Cancel",
  "I1_14690;23_39072": "Work Management",
  "I1_14697;23_39072": "Notification",
  "1_14645": "Frame 1000003169",
  "I1_14632;35_49548;28_31236": "Chauffeur Connect",
  "I1_14637;881_101975": "Pickup Location",
  "I1_14632;35_49551;28_31261": ">",
  "1_14660": "5",
  "I1_14664;21_22137": "Top",
  "I1_14677;21_22137": "Passenger Management",
  "I1_14640;881_101975": "Pickup Now",
  "I1_14760;23_39072": "Work Management",
  "I1_14705;35_49503": "Create Meeting",
  "1_14756": "Activity List",
  "I1_14712;881_101975": "End Date",
  "1_14751": "Chauffeur Connect",
  "I1_14709;881_101975": "Meeting Location",
  "I1_14746;21_22137": "Driver Management",
  "1_14730": "5",
  "I1_14716;30_32137": "Cancel",
  "I1_14704;35_49549;28_31261": ">",
  "I1_14704;35_49552;28_31243": "Create Meeting",
  "I1_14736;23_39072": "Company setting",
  "I1_14767;23_39072": "Notification",
  "I1_14704;35_49550;28_31236": "Activity List",
  "I1_14715;30_32081": "Next",
  "I1_14762;23_39072": "Approval",
  "I1_14704;35_49551;28_31261": ">",
  "I1_14704;35_49548;28_31236": "Chauffeur Connect",
  "I1_14741;23_39072": "Vehicle Management",
  "1_14759": "Setting",
  "I1_14711;881_101975": "Start Date",
  "I1_14713;30_32137": "Add a meeting",
  "1_14717": "Frame 1000003169",
  "1_14732": "Client Portal",
  "I1_14747;21_22137": "Passenger Management",
  "I1_14734;21_22137": "Top",
  "I1_14850;23_39072": "Notification",
  "I1_14775;35_49550;28_31236": "Activity List",
  "I1_14817;21_22137": "Top",
  "1_14788": "Vehicle Return Depot",
  "1_14800": "Frame 1000003169",
  "I1_14829;21_22137": "Driver Management",
  "I1_14830;21_22137": "Passenger Management",
  "1_14796": "Additional Driver",
  "I1_14819;23_39072": "Company setting",
  "I1_14799;30_32137": "Cancel",
  "1_14834": "Chauffeur Connect",
  "I1_14776;35_49503": "Create Rental",
  "I1_14824;23_39072": "Vehicle Management",
  "I1_14798;30_32081": "Confirm and search",
  "I1_14793;881_101975": "Select Passenger",
  "1_14839": "Activity List",
  "1_14813": "5",
  "I1_14843;23_39072": "Work Management",
  "1_14783": "Vehicle Pickup Depot",
  "I1_14792;881_101975": "End Date",
  "I1_14775;35_49548;28_31236": "Chauffeur Connect",
  "I1_14791;881_101975": "Start Date",
  "I1_14775;35_49551;28_31261": ">",
  "1_14842": "Setting",
  "1_14815": "Client Portal",
  "I1_14775;35_49549;28_31261": ">",
  "I1_14775;35_49552;28_31243": "Create Rental",
  "I1_14845;23_39072": "Approval",
  "I1_14881;30_32137": "Cancel",
  "I1_14912;21_22137": "Passenger Management",
  "1_14921": "Activity List",
  "I1_14872;881_101975": "Drop-off Location",
  "I1_14925;23_39072": "Work Management",
  "I1_14859;35_49503": "Create Package",
  "I1_14858;35_49550;28_31236": "Activity List",
  "I1_14878;881_101975": "Note For Driver",
  "I1_14880;30_32081": "Confirm and search",
  "1_14863": "Sender Information",
  "I1_14866;881_101975": "Sender Name",
  "1_14870": "Recipient Information",
  "I1_14906;23_39072": "Vehicle Management",
  "1_14916": "Chauffeur Connect",
  "1_14895": "5",
  "I1_14868;881_101975": "Pickup Now",
  "I1_14858;35_49551;28_31261": ">",
  "1_14882": "Frame 1000003169",
  "I1_14858;35_49549;28_31261": ">",
  "I1_14858;35_49548;28_31236": "Chauffeur Connect",
  "I1_14865;881_101975": "Pickup Location",
  "I1_14858;35_49552;28_31243": "Create Package",
  "I1_14899;21_22137": "Top",
  "I1_14911;21_22137": "Driver Management",
  "I1_14901;23_39072": "Company setting",
  "1_14897": "Client Portal",
  "I1_14927;23_39072": "Approval",
  "I1_14875;881_101975": "Recipient Phone Number",
  "1_14877": "Note For Driver",
  "I1_14873;881_101975": "Recipient Name",
  "1_14924": "Setting",
  "I1_14932;23_39072": "Notification",
  "I1_14956;881_101975": "Recipient Name",
  "I1_15020;23_39072": "Approval",
  "1_15009": "Chauffeur Connect",
  "I1_14940;35_49551;28_31261": ">",
  "I1_14955;881_101975": "Drop-off Location",
  "I1_14951;881_101975": "Pickup Now",
  "1_14990": "Client Portal",
  "I1_14992;21_22137": "Top",
  "1_15014": "Activity List",
  "I1_15005;21_22137": "Passenger Management",
  "I1_14940;35_49549;28_31261": ">",
  "I1_14949;881_101975": "Sender Name",
  "I1_15018;23_39072": "Work Management",
  "I1_15004;21_22137": "Driver Management",
  "1_14946": "Sender Information",
  "I1_14963;30_32140": "Confirm and search",
  "1_14953": "Recipient Information",
  "I1_15025;23_39072": "Notification",
  "I1_14958;881_101975": "Recipient Phone Number",
  "I1_14940;35_49552;28_31243": "Create Package",
  "I1_14940;35_49548;28_31236": "Chauffeur Connect",
  "I1_14999;23_39072": "Vehicle Management",
  "1_14983": "To Drop-off Location B",
  "1_14978": "From Pickup Location A",
  "I1_14941;35_49503": "Create Package",
  "1_14988": "5",
  "1_15017": "Setting",
  "1_14960": "Note For Driver",
  "I1_14948;881_101975": "Pickup Location",
  "I1_14961;881_101975": "Note For Driver",
  "I1_14994;23_39072": "Company setting",
  "I1_14940;35_49550;28_31236": "Activity List",
  "1_14965": "Frame 1000003169",
  "I1_14964;30_32137": "Cancel",
  "I1_15111;23_39072": "Work Management",
  "1_15083": "Client Portal",
  "1_15076": "Vehicle Pickup Depot",
  "1_15107": "Activity List",
  "I1_15118;23_39072": "Notification",
  "1_15071": "Vehicle Pickup Depot",
  "1_15058": "Frame 1000003169",
  "I1_15033;35_49548;28_31236": "Chauffeur Connect",
  "1_15041": "Vehicle Pickup Depot",
  "I1_15033;35_49551;28_31261": ">",
  "I1_15057;30_32137": "Cancel",
  "1_15046": "Vehicle Pickup Depot",
  "I1_15085;21_22137": "Top",
  "I1_15033;35_49550;28_31236": "Activity List",
  "I1_15098;21_22137": "Passenger Management",
  "I1_15049;881_102097": "01/03/2024 07:00",
  "I1_15056;30_32140": "Confirm and search",
  "I1_15033;35_49549;28_31261": ">",
  "I1_15087;23_39072": "Company setting",
  "1_15054": "Additional Driver",
  "I1_15034;35_49503": "Create Rental",
  "I1_15097;21_22137": "Driver Management",
  "I1_15033;35_49552;28_31243": "Create Rental",
  "1_15110": "Setting",
  "I1_15050;881_102097": "01/03/2024 08:00",
  "I1_15051;881_101975": "Select Passenger",
  "1_15102": "Chauffeur Connect",
  "1_15081": "5",
  "I1_15113;23_39072": "Approval",
  "I1_15092;23_39072": "Vehicle Management",
  "I1_15126;35_49548;28_31236": "Chauffeur Connect",
  "I1_15135;30_32137": "Add a meeting",
  "I1_15138;30_32137": "Cancel",
  "I1_15161;21_22137": "Top",
  "I1_15131;881_102097": "Meeting Location A",
  "1_15139": "Frame 1000003169",
  "I1_15126;35_49552;28_31243": "Create Meeting",
  "I1_15137;30_32140": "Next",
  "I1_15126;35_49551;28_31261": ">",
  "I1_15126;35_49549;28_31261": ">",
  "I1_15173;21_22137": "Driver Management",
  "I1_15134;881_102097": "01/03/2024 08:00",
  "I1_15127;35_49503": "Create Meeting",
  "1_15186": "Setting",
  "I1_15163;23_39072": "Company setting",
  "I1_15126;35_49550;28_31236": "Activity List",
  "I1_15133;881_102097": "01/03/2024 07:00",
  "I1_15189;23_39072": "Approval",
  "1_15152": "To Meeting Location A",
  "1_15178": "Chauffeur Connect",
  "I1_15194;23_39072": "Notification",
  "I1_15174;21_22137": "Passenger Management",
  "1_15157": "5",
  "I1_15187;23_39072": "Work Management",
  "I1_15168;23_39072": "Vehicle Management",
  "1_15159": "Client Portal",
  "1_15183": "Activity List",
  "I1_15224;881_101975": "Start Date",
  "1_15240": "Frame 1000003169",
  "1_15230": "Meeting Location",
  "1_15255": "Client Portal",
  "I1_15238;30_32081": "Next",
  "I1_15215;881_101975": "End Date",
  "I1_15202;35_49550;28_31236": "Activity List",
  "I1_15270;21_22137": "Passenger Management",
  "I1_15259;23_39072": "Company setting",
  "I1_15257;21_22137": "Top",
  "1_15220": "Meeting Location",
  "I1_15285;23_39072": "Approval",
  "1_15253": "5",
  "I1_15235;881_101975": "End Date",
  "I1_15202;35_49551;28_31261": ">",
  "1_15274": "Chauffeur Connect",
  "I1_15283;23_39072": "Work Management",
  "I1_15202;35_49548;28_31236": "Chauffeur Connect",
  "I1_15239;30_32137": "Cancel",
  "I1_15214;881_101975": "Start Date",
  "I1_15269;21_22137": "Driver Management",
  "I1_15264;23_39072": "Vehicle Management",
  "I1_15236;30_32137": "Add a meeting",
  "1_15282": "Setting",
  "I1_15225;881_101975": "End Date",
  "1_15210": "Meeting Location",
  "I1_15234;881_101975": "Start Date",
  "I1_15203;35_49503": "Create Meeting",
  "I1_15202;35_49549;28_31261": ">",
  "I1_15290;23_39072": "Notification",
  "I1_15202;35_49552;28_31243": "Create Meeting",
  "1_15279": "Activity List",
  "I1_15378;23_39072": "Work Management",
  "I1_15352;21_22137": "Top",
  "1_15325": "Arrival",
  "I1_15364;21_22137": "Driver Management",
  "1_15324": "08:45",
  "I1_15298;35_49548;28_31236": "Chauffeur Connect",
  "1_15369": "Chauffeur Connect",
  "1_15316": "Pickup",
  "I1_15354;23_39072": "Company setting",
  "I1_15298;35_49550;28_31236": "Activity List",
  "I1_15304;881_102097": "London Heathrow Airport",
  "I1_15359;23_39072": "Vehicle Management",
  "I1_15298;35_49552;28_31243": "Create Airport",
  "I1_15329;30_32137": "Back",
  "1_15348": "5",
  "I1_15298;35_49551;28_31261": ">",
  "I1_15298;35_49549;28_31261": ">",
  "1_15343": "From London Heathrow Airport",
  "1_15374": "Activity List",
  "I1_15306;30_32137": "Add a stop",
  "I1_15305;881_101975": "Drop-off Location",
  "1_15350": "Client Portal",
  "I1_15385;23_39072": "Notification",
  "1_15377": "Setting",
  "I1_15380;23_39072": "Approval",
  "I1_15328;30_32081": "Confirm and search",
  "I1_15299;35_49503": "Create Airport",
  "1_15315": "08:25",
  "I1_15326;881_101975": "Select Passenger",
  "1_15330": "Frame 1000003169",
  "I1_15365;21_22137": "Passenger Management",
  "I1_15393;35_49550;28_31236": "Activity List",
  "I1_15423;30_32081": "Confirm and search",
  "I1_15459;21_22137": "Driver Management",
  "I1_15475;23_39072": "Approval",
  "1_15445": "Client Portal",
  "1_15469": "Activity List",
  "1_15472": "Setting",
  "I1_15460;21_22137": "Passenger Management",
  "I1_15449;23_39072": "Company setting",
  "1_15443": "5",
  "I1_15424;30_32137": "Back",
  "1_15410": "08:25",
  "I1_15393;35_49549;28_31261": ">",
  "1_15411": "Pickup",
  "I1_15399;881_101975": "Pickup Location",
  "1_15425": "Frame 1000003169",
  "I1_15421;881_101975": "Select Passenger",
  "I1_15400;881_102097": "Nashville International Airport",
  "1_15464": "Chauffeur Connect",
  "I1_15447;21_22137": "Top",
  "I1_15394;35_49503": "Create Airport",
  "I1_15473;23_39072": "Work Management",
  "1_15438": "To Nashville International Airport",
  "I1_15454;23_39072": "Vehicle Management",
  "I1_15393;35_49552;28_31243": "Create Airport",
  "I1_15393;35_49548;28_31236": "Chauffeur Connect",
  "I1_15401;30_32137": "Add a stop",
  "I1_15480;23_39072": "Notification",
  "1_15419": "18:45",
  "1_15420": "Departure",
  "I1_15393;35_49551;28_31261": ">",
  "I1_15547;21_22137": "Top",
  "I1_15554;23_39072": "Vehicle Management",
  "I1_15573;23_39072": "Work Management",
  "1_15572": "Setting",
  "I1_15488;35_49548;28_31236": "Chauffeur Connect",
  "1_15520": "Frame 1000003169",
  "I1_15516;881_101975": "Select Passenger",
  "1_15514": "18:45",
  "1_15505": "08:25",
  "I1_15559;21_22137": "Driver Management",
  "I1_15488;35_49552;28_31243": "Create Airport",
  "1_15569": "Activity List",
  "I1_15495;881_102097": "Nashville International Airport",
  "1_15533": "From Pickup Location A",
  "I1_15488;35_49551;28_31261": ">",
  "1_15543": "5",
  "I1_15518;30_32081": "Confirm and search",
  "1_15545": "Client Portal",
  "1_15564": "Chauffeur Connect",
  "I1_15494;881_102097": "Pickup Location",
  "1_15515": "Departure",
  "I1_15488;35_49549;28_31261": ">",
  "I1_15489;35_49503": "Create Airport",
  "I1_15549;23_39072": "Company setting",
  "I1_15519;30_32137": "Back",
  "1_15506": "Pickup",
  "I1_15496;30_32137": "Add a stop",
  "I1_15488;35_49550;28_31236": "Activity List",
  "I1_15560;21_22137": "Passenger Management",
  "I1_15575;23_39072": "Approval",
  "1_15538": "To Nashville International Airport",
  "I1_15580;23_39072": "Notification",
  "1_15672": "Setting",
  "1_15605": "08:25",
  "I1_15588;35_49548;28_31236": "Chauffeur Connect",
  "1_15643": "5",
  "I1_15595;881_102097": "Drop-off Location B",
  "I1_15675;23_39072": "Approval",
  "I1_15589;35_49503": "Create Airport",
  "I1_15588;35_49550;28_31236": "Activity List",
  "I1_15588;35_49551;28_31261": ">",
  "I1_15680;23_39072": "Notification",
  "I1_15616;881_101975": "Select Passenger",
  "1_15664": "Chauffeur Connect",
  "I1_15618;30_32140": "Confirm and search",
  "I1_15649;23_39072": "Company setting",
  "I1_15619;30_32137": "Back",
  "I1_15673;23_39072": "Work Management",
  "I1_15588;35_49549;28_31261": ">",
  "I1_15654;23_39072": "Vehicle Management",
  "I1_15588;35_49552;28_31243": "Create Airport",
  "1_15614": "08:45",
  "I1_15660;21_22137": "Passenger Management",
  "1_15633": "From London Heathrow Airport",
  "1_15669": "Activity List",
  "1_15615": "Arrival",
  "I1_15659;21_22137": "Driver Management",
  "1_15638": "To Drop-off Location B",
  "I1_15594;881_102097": "London Heathrow Airport",
  "1_15645": "Client Portal",
  "1_15620": "Frame 1000003169",
  "I1_15596;30_32137": "Add a stop",
  "1_15606": "Pickup",
  "I1_15647;21_22137": "Top",
  "1_15749": "Stop 1",
  "I1_15722;30_32137": "Add a meeting",
  "1_15775": "Chauffeur Connect",
  "I1_15765;23_39072": "Vehicle Management",
  "I1_15786;23_39072": "Approval",
  "I1_15688;35_49548;28_31236": "Chauffeur Connect",
  "I1_15688;35_49549;28_31261": ">",
  "I1_15784;23_39072": "Work Management",
  "1_15756": "Client Portal",
  "I1_15701;881_101975": "End Date",
  "I1_15689;35_49503": "Create Meeting",
  "I1_15760;23_39072": "Company setting",
  "I1_15724;30_32081": "Next",
  "1_15706": "Meeting Location B",
  "1_15716": "Meeting Location C",
  "I1_15758;21_22137": "Top",
  "I1_15721;881_101975": "End Date",
  "I1_15791;23_39072": "Notification",
  "I1_15711;881_101975": "End Date",
  "I1_15720;881_101975": "Start Date",
  "1_15754": "5",
  "1_15783": "Setting",
  "1_15696": "Meeting Location A",
  "1_15739": "To Meeting Location C",
  "I1_15688;35_49552;28_31243": "Create Meeting",
  "1_15780": "Activity List",
  "1_15726": "Frame 1000003169",
  "1_15744": "Stop 2",
  "I1_15710;881_101975": "Start Date",
  "I1_15770;21_22137": "Driver Management",
  "I1_15688;35_49551;28_31261": ">",
  "I1_15700;881_101975": "Start Date",
  "I1_15771;21_22137": "Passenger Management",
  "I1_15688;35_49550;28_31236": "Activity List",
  "I1_15725;30_32137": "Cancel",
  "1_15811": "Frame 1000003169",
  "I1_15840;23_39072": "Vehicle Management",
  "I1_15805;881_102097": "Meeting Location A",
  "I1_15846;21_22137": "Passenger Management",
  "I1_15804;881_101975": "Pickup Location",
  "1_15831": "Client Portal",
  "1_15824": "To Meeting Location A",
  "I1_15799;35_49551;28_31261": ">",
  "I1_15833;21_22137": "Top",
  "I1_15845;21_22137": "Driver Management",
  "I1_15810;30_32137": "Back",
  "I1_15807;881_101975": "Select Passenger",
  "I1_15799;35_49548;28_31236": "Chauffeur Connect",
  "I1_15800;35_49503": "Create Meeting",
  "I1_15799;35_49552;28_31243": "Create Meeting",
  "I1_15835;23_39072": "Company setting",
  "I1_15861;23_39072": "Approval",
  "1_15850": "Chauffeur Connect",
  "I1_15806;30_32137": "Add a stop",
  "I1_15799;35_49550;28_31236": "Activity List",
  "I1_15799;35_49549;28_31261": ">",
  "I1_15809;30_32081": "Confirm and search",
  "I1_15866;23_39072": "Notification",
  "I1_15859;23_39072": "Work Management",
  "1_15858": "Setting",
  "1_15855": "Activity List",
  "1_15829": "5",
  "I1_15939;23_39072": "Work Management",
  "1_15938": "Setting",
  "I1_15874;35_49549;28_31261": ">",
  "I1_15880;881_102097": "Meeting Location A",
  "I1_15885;30_32137": "Bạck",
  "I1_15920;23_39072": "Vehicle Management",
  "I1_15874;35_49548;28_31236": "Chauffeur Connect",
  "I1_15946;23_39072": "Notification",
  "1_15911": "Client Portal",
  "I1_15913;21_22137": "Top",
  "I1_15925;21_22137": "Driver Management",
  "I1_15941;23_39072": "Approval",
  "I1_15874;35_49551;28_31261": ">",
  "I1_15874;35_49552;28_31243": "Create Meeting",
  "I1_15881;30_32137": "Add a stop",
  "I1_15879;881_102097": "Pickup Location A",
  "I1_15874;35_49550;28_31236": "Activity List",
  "1_15904": "From Pickup Location A",
  "I1_15884;30_32140": "Confirm and search",
  "1_15899": "To Meeting Location A",
  "I1_15926;21_22137": "Passenger Management",
  "I1_15882;881_101975": "Select Passenger",
  "1_15886": "Frame 1000003169",
  "1_15935": "Activity List",
  "I1_15915;23_39072": "Company setting",
  "1_15909": "5",
  "I1_15875;35_49503": "Create Meeting",
  "1_15930": "Chauffeur Connect",
  "I1_15959;881_102097": "Pickup location A",
  "I1_15963;881_101975": "Select Passenger",
  "I1_16003;21_22137": "Driver Management",
  "I1_15954;35_49549;28_31261": ">",
  "I1_16004;21_22137": "Passenger Management",
  "I1_16017;23_39072": "Work Management",
  "I1_16019;23_39072": "Approval",
  "I1_15962;881_101975": "Pickup Now",
  "I1_15991;21_22137": "Top",
  "I1_15954;35_49548;28_31236": "Chauffeur Connect",
  "1_16013": "Activity List",
  "I1_16024;23_39072": "Notification",
  "1_16008": "Chauffeur Connect",
  "1_16016": "Setting",
  "I1_15961;30_32137": "Add a stop",
  "I1_15966;30_32137": "Cancel",
  "I1_15954;35_49551;28_31261": ">",
  "I1_15954;35_49550;28_31236": "Activity List",
  "I1_15998;23_39072": "Vehicle Management",
  "I1_15960;881_101975": "Drop-off Location",
  "1_15967": "Frame 1000003169",
  "I1_15993;23_39072": "Company setting",
  "1_15987": "5",
  "1_15989": "Client Portal",
  "1_15971": "From Pickup location A",
  "I1_15965;30_32081": "Confirm and search",
  "1_16048": "From Pickup location A",
  "1_16062": "To Drop-off location B",
  "1_16098": "Setting",
  "I1_16086;21_22137": "Passenger Management",
  "I1_16075;23_39072": "Company setting",
  "I1_16099;23_39072": "Work Management",
  "I1_16036;881_102097": "Pickup location A",
  "I1_16040;881_101975": "Select Passenger",
  "I1_16085;21_22137": "Driver Management",
  "I1_16037;881_102097": "Pickup location B",
  "I1_16031;35_49551;28_31261": ">",
  "I1_16031;35_49549;28_31261": ">",
  "I1_16031;35_49548;28_31236": "Chauffeur Connect",
  "1_16069": "5",
  "1_16095": "Activity List",
  "1_16090": "Chauffeur Connect",
  "I1_16042;30_32140": "Confirm and search",
  "I1_16101;23_39072": "Approval",
  "I1_16043;30_32137": "Cancel",
  "1_16044": "Frame 1000003169",
  "I1_16106;23_39072": "Notification",
  "I1_16073;21_22137": "Top",
  "I1_16038;30_32137": "Add a stop",
  "I1_16039;881_101975": "Pickup Now",
  "I1_16031;35_49550;28_31236": "Activity List",
  "1_16071": "Client Portal",
  "I1_16080;23_39072": "Vehicle Management",
  "1_16186": "30 min",
  "1_16242": "Setting",
  "I1_16141;30_32140": "Request Vehicle",
  "1_16213": "5",
  "1_16154": "Pickup Location A",
  "1_16215": "Client Portal",
  "1_16125": "Driver Name",
  "I1_16217;21_22137": "Top",
  "1_16170": "*",
  "1_16150": "Pickup Date Time: ",
  "1_16118": "Choose a Vehicle",
  "I1_16176;75_53807": "Note For Driver",
  "1_16161": "01/02/2024 09:30 ",
  "I1_16113;35_49549;28_31261": ">",
  "I1_16243;23_39072": "Work Management",
  "1_16123": "Brand Model",
  "1_16131": "Group Description",
  "I1_16142;30_32137": "Back",
  "1_16146": "Activity Detail",
  "1_16160": "Drop-off Date Time: ",
  "1_16179": "Route Summary",
  "1_16156": "Passenger: ",
  "I1_16230;21_22137": "Passenger Management",
  "1_16124": "Number Plate",
  "I1_16113;35_49550;28_31236": "Activity List",
  "1_16205": "From Pickup location B",
  "I1_16113;35_49548;28_31236": "Chauffeur Connect",
  "I1_16250;23_39072": "Notification",
  "1_16163": "Drop-off Date Time: ",
  "1_16200": "From Pickup location A",
  "1_16234": "Chauffeur Connect",
  "1_16164": "Drop-off Location B",
  "I1_16219;23_39072": "Company setting",
  "1_16137": "Vehicle Group Name",
  "1_16175": "Note For Driver",
  "1_16187": "Frame 1000003169",
  "1_16182": "Total Distance: ",
  "I1_16224;23_39072": "Vehicle Management",
  "I1_16113;35_49551;28_31261": ">",
  "I1_16245;23_39072": "Approval",
  "1_16239": "Activity List",
  "1_16171": "Usage Type",
  "1_16130": "Vehicle Group Name",
  "1_16153": "Pickup Location: ",
  "1_16166": "Department: ",
  "1_16167": "Sales",
  "I1_16172;75_53865;250_37564": "Business",
  "1_16151": "01/02/2024 08:30 ",
  "1_16185": "Travel Time: ",
  "I1_16229;21_22137": "Driver Management",
  "1_16138": "Group Description",
  "1_16157": "Passenger A",
  "1_16183": "20km",
  "1_16315": "Pickup Location A",
  "I1_16257;35_49550;28_31236": "Activity List",
  "1_16328": "Sales",
  "1_16289": "Number Plate",
  "1_16282": "Brand Model",
  "1_16311": "Pickup Date Time: ",
  "1_16307": "Activity Detail",
  "1_16344": "20km",
  "1_16312": "01/02/2024 08:30 ",
  "1_16294": "Brand Model",
  "1_16343": "Total Distance: ",
  "1_16376": "Client Portal",
  "1_16267": "Brand Model",
  "I1_16406;23_39072": "Approval",
  "1_16269": "Driver Name",
  "1_16331": "*",
  "1_16324": "Drop-off Date Time: ",
  "1_16283": "Number Plate",
  "I1_16411;23_39072": "Notification",
  "1_16314": "Pickup Location: ",
  "1_16348": "Frame 1000003169",
  "1_16366": "From Pickup location B",
  "I1_16257;35_49551;28_31261": ">",
  "1_16288": "Brand Model",
  "I1_16298;30_32140": "Request Vehicle",
  "1_16317": "Passenger: ",
  "I1_16299;30_32137": "Back",
  "1_16340": "Route Summary",
  "1_16275": "Vehicle Group Name",
  "1_16296": "Driver Name",
  "1_16321": "Drop-off Date Time: ",
  "1_16332": "Usage Type",
  "I1_16380;23_39072": "Company setting",
  "1_16290": "Driver Name",
  "I1_16391;21_22137": "Passenger Management",
  "I1_16257;35_49549;28_31261": ">",
  "I1_16333;75_53865;250_37564": "Business",
  "1_16400": "Activity List",
  "1_16276": "Group Description",
  "I1_16337;75_53807": "Note For Driver",
  "1_16374": "5",
  "1_16361": "From Pickup location A",
  "1_16336": "Note For Driver",
  "1_16395": "Chauffeur Connect",
  "1_16403": "Setting",
  "1_16268": "Number Plate",
  "I1_16390;21_22137": "Driver Management",
  "I1_16404;23_39072": "Work Management",
  "I1_16385;23_39072": "Vehicle Management",
  "1_16295": "Number Plate",
  "1_16347": "30 min",
  "1_16318": "Passenger A",
  "1_16262": "Choose a Vehicle",
  "I1_16378;21_22137": "Top",
  "I1_16257;35_49548;28_31236": "Chauffeur Connect",
  "1_16284": "Driver Name",
  "1_16322": "01/02/2024 09:30 ",
  "1_16327": "Department: ",
  "1_16325": "Drop-off Location B",
  "I1_16418;35_49551;28_31261": ">",
  "1_16428": "Frame 1000003129",
  "1_16467": "Chauffeur Connect",
  "I1_16418;35_49548;28_31236": "Chauffeur Connect",
  "I1_16426;30_32140": "Confirm Pickup Location",
  "I1_16457;23_39072": "Vehicle Management",
  "1_16448": "Client Portal",
  "1_16472": "Activity List",
  "1_16475": "Setting",
  "1_16424": "Pickup spot address",
  "I1_16450;21_22137": "Top",
  "I1_16478;23_39072": "Approval",
  "1_16446": "5",
  "I1_16427;30_32137": "Cancel",
  "I1_16452;23_39072": "Company setting",
  "I1_16418;35_49549;28_31261": ">",
  "I1_16463;21_22137": "Passenger Management",
  "I1_16483;23_39072": "Notification",
  "I1_16462;21_22137": "Driver Management",
  "1_16423": "Choose your pickup location",
  "I1_16476;23_39072": "Work Management",
  "I1_16418;35_49550;28_31236": "Activity List",
  "I1_16516;30_32137": "Add a stop",
  "1_16568": "Activity List",
  "I1_16520;30_32081": "Confirm and search",
  "I1_16517;881_101975": "Pickup Now",
  "I1_16579;23_39072": "Notification",
  "I1_16553;23_39072": "Vehicle Management",
  "I1_16558;21_22137": "Driver Management",
  "I1_16490;35_49550;28_31236": "Activity List",
  "1_16535": "From Pickup Location A",
  "1_16563": "Chauffeur Connect",
  "I1_16518;881_101975": "Select Passenger",
  "1_16499": "Stop location",
  "I1_16559;21_22137": "Passenger Management",
  "I1_16490;35_49549;28_31261": ">",
  "1_16522": "Frame 1000003169",
  "I1_16574;23_39072": "Approval",
  "1_16506": "Stop location",
  "1_16542": "5",
  "1_16571": "Setting",
  "I1_16546;21_22137": "Top",
  "I1_16572;23_39072": "Work Management",
  "I1_16490;35_49548;28_31236": "Chauffeur Connect",
  "I1_16495;881_102097": "Pickup location A",
  "1_16544": "Client Portal",
  "I1_16521;30_32137": "Cancel",
  "I1_16490;35_49551;28_31261": ">",
  "1_16513": "Stop location",
  "I1_16548;23_39072": "Company setting",
  "I1_16616;30_32140": "Confirm and search",
  "I1_16613;881_101975": "Pickup Now",
  "1_16653": "5",
  "I1_16586;35_49550;28_31236": "Activity List",
  "1_16636": "Stop 1",
  "I1_16586;35_49549;28_31261": ">",
  "I1_16586;35_49548;28_31236": "Chauffeur Connect",
  "1_16682": "Setting",
  "I1_16617;30_32137": "Cancel",
  "1_16609": "Drop-off Location D",
  "I1_16683;23_39072": "Work Management",
  "I1_16670;21_22137": "Passenger Management",
  "1_16618": "Frame 1000003169",
  "I1_16669;21_22137": "Driver Management",
  "I1_16657;21_22137": "Top",
  "I1_16690;23_39072": "Notification",
  "I1_16614;881_101975": "Select Passenger",
  "I1_16612;30_32137": "Add a stop",
  "1_16646": "To Drop-off Location D",
  "I1_16591;881_102097": "Pickup location A",
  "1_16595": "Stop Location B",
  "I1_16685;23_39072": "Approval",
  "I1_16659;23_39072": "Company setting",
  "1_16679": "Activity List",
  "1_16641": "Stop 2",
  "1_16655": "Client Portal",
  "I1_16586;35_49551;28_31261": ">",
  "1_16602": "Stop Location C",
  "I1_16664;23_39072": "Vehicle Management",
  "1_16674": "Chauffeur Connect",
  "1_16631": "From Pickup Location A",
  "I1_16725;185_68545": "Departure Time",
  "I1_16703;35_49503": "Create Airport",
  "I1_16702;35_49551;28_31261": ">",
  "I1_16777;21_22137": "Driver Management",
  "1_16734": "Arrival Date",
  "I1_16741;185_68545": "Arrival Terminal",
  "1_16754": "Airport Drop-off",
  "1_16745": "Select Type",
  "1_16782": "Chauffeur Connect",
  "1_16727": "Departure Terminal",
  "1_16787": "Activity List",
  "1_16761": "5",
  "I1_16702;35_49548;28_31236": "Chauffeur Connect",
  "I1_16791;23_39072": "Work Management",
  "1_16724": "Departure Time",
  "I1_16728;185_68545": "Departure Terminal",
  "I1_16708;881_101975": "Flight Date",
  "1_16740": "Arrival Terminal",
  "I1_16702;35_49552;28_31243": "Create Airport",
  "1_16763": "Client Portal",
  "I1_16738;185_68545": "Arrival Time",
  "1_16744": "*",
  "1_16790": "Setting",
  "I1_16767;23_39072": "Company setting",
  "1_16721": "Departure Date",
  "I1_16772;23_39072": "Vehicle Management",
  "I1_16719;185_68545": "Departure Airport",
  "I1_16793;23_39072": "Approval",
  "I1_16722;185_68545": "Departure Date",
  "I1_16715;185_68545": "Airline",
  "I1_16778;21_22137": "Passenger Management",
  "I1_16709;30_32140": "Search",
  "I1_16732;185_68545": "Arrival Airport",
  "I1_16702;35_49549;28_31261": ">",
  "1_16750": "Airport Pickup",
  "I1_16707;881_101975": "Flight Number",
  "1_16718": "Departure Airport",
  "I1_16735;185_68545": "Arrival Date",
  "I1_16757;30_32081": "Next",
  "I1_16798;23_39072": "Notification",
  "I1_16756;30_32137": "Cancel",
  "1_16714": "Airline",
  "1_16731": "Arrival Airport",
  "I1_16765;21_22137": "Top",
  "I1_16702;35_49550;28_31236": "Activity List",
  "1_16737": "Arrival Time",
  "1_16711": "Flight Detail",
  "I1_16846;23_39072": "Work Management",
  "I492_31981;75_53865;250_37564": "Minute",
  "I492_31984;75_53865;250_37564": "Minute",
  "I1_16807;35_49503": "Setting",
  "1_16818": "Client Portal",
  "I1_16827;23_39072": "Vehicle Management",
  "1_16837": "Chauffeur Connect",
  "1_16845": "Setting",
  "1_16842": "Activity List",
  "492_31980": "Timeout Setting",
  "492_31983": "Standby Time Setting",
  "I1_16820;21_22137": "Top",
  "I1_16822;23_39072": "Company setting",
  "I492_31976;35_49548;28_31236": "Chauffeur Connect",
  "I492_31976;35_49552;28_31243": "Setting",
  "I1_16848;23_39072": "Approval",
  "1_16816": "5",
  "I492_31985;30_32140": "Save",
  "I1_16806;35_49549;28_31261": ">",
  "I1_16832;21_22137": "Driver Management",
  "I1_16833;21_22137": "Passenger Management",
  "I1_16853;23_39072": "Notification",
  "I1_16861;35_49548;28_31236": "Chauffeur Connect",
  "I1_16915;30_32137": "Cancel",
  "I1_16861;35_49549;28_31261": ">",
  "I1_16874;185_68545": "British Airways 222",
  "1_16899": "Arrival Terminal",
  "1_16886": "Departure Terminal",
  "I1_16867;881_102097": "01/03/2024",
  "I1_16866;881_102097": "BA 222",
  "1_16903": "*",
  "I1_16957;23_39072": "Notification",
  "I1_16861;35_49552;28_31243": "Create Airport",
  "1_16913": "Airport Drop-off",
  "1_16941": "Chauffeur Connect",
  "I1_16916;30_32140": "Next",
  "I1_16887;185_68545": "Terrminal 2",
  "1_16870": "Flight Detail",
  "1_16946": "Activity List",
  "I1_16878;185_68545": "Nashville International Airport",
  "1_16873": "Airline",
  "I1_16897;185_68545": "8:45",
  "1_16877": "Departure Airport",
  "I1_16891;185_68545": "London Heathrow Airport",
  "I1_16894;185_68545": "02/03/2024",
  "1_16909": "Airport Pickup",
  "I1_16881;185_68545": "01/03/2024",
  "1_16920": "5",
  "I1_16926;23_39072": "Company setting",
  "I1_16937;21_22137": "Passenger Management",
  "1_16893": "Arrival Date",
  "I1_16862;35_49503": "Create Airport",
  "I1_16861;35_49550;28_31236": "Activity List",
  "I1_16952;23_39072": "Approval",
  "I1_16868;30_32140": "Search",
  "I1_16900;185_68545": "Terminal 5",
  "1_16922": "Client Portal",
  "I1_16861;35_49551;28_31261": ">",
  "I1_16936;21_22137": "Driver Management",
  "I1_16884;185_68545": "18:45",
  "1_16949": "Setting",
  "1_16880": "Departure Date",
  "I1_16931;23_39072": "Vehicle Management",
  "1_16904": "Select Type",
  "1_16896": "Arrival Time",
  "I1_16924;21_22137": "Top",
  "1_16883": "Departure Time",
  "I1_16950;23_39072": "Work Management",
  "1_16890": "Arrival Airport",
  "I1_17048;21_22137": "Top",
  "I1_17038;154_61744": "Driver Feedback",
  "I1_16972;154_64382": "Activity Type",
  "I1_16980;154_64383;75_53865;250_37616": "Business",
  "I1_16975;154_64382": "Passenger Name",
  "I1_17061;21_22137": "Passenger Management",
  "I1_16976;154_64382": "Driver Name",
  "I1_16965;35_49551;28_31261": ">",
  "I1_16982;154_64382": "Drop-off Location",
  "I1_16983;154_64382": "Department",
  "I1_16974;154_64383;75_53865;250_37616": "Pickup Location 1",
  "1_17031": "Driver Evaluation",
  "I1_16973;154_64383;75_53865;250_37616": "01/02/2024 08:30",
  "1_17065": "Chauffeur Connect",
  "I1_16994;30_32137": "Back",
  "I1_16975;154_64383;75_53865;250_37616": "Passenger A",
  "I1_16983;154_64383;75_53865;250_37616": "Sales",
  "1_17046": "Client Portal",
  "1_16988": "Expense Amount",
  "I1_17081;23_39072": "Notification",
  "1_17002": "20km",
  "I1_16972;154_64383;75_53865;250_37616": "Ride",
  "1_17001": "Total Distance: ",
  "I1_16965;35_49550;28_31236": "Activity List",
  "I1_17050;23_39072": "Company setting",
  "I1_16978;154_64382": "Expense Category",
  "I1_16974;154_64382": "Pickup Location",
  "I1_16981;154_64382": "Drop-off Date time",
  "I1_16985;154_64382": "Drop-off Location",
  "I1_17033;154_61744": "Passenger Feedback",
  "I1_16966;35_49503": "View Activity",
  "I1_16984;154_64383;75_53865;250_37616": "1232654",
  "I1_17055;23_39072": "Vehicle Management",
  "I1_16976;154_64383;75_53865;250_37616": "Driver A",
  "I1_16965;35_49549;28_31261": ">",
  "1_17005": "30 min",
  "I1_17076;23_39072": "Approval",
  "1_17019": "From Pickup location 1",
  "I1_16982;154_64383;75_53865;250_37616": "Drop-off Location 1",
  "I1_16965;35_49552;28_31243": "View Activity",
  "1_16998": "Route Summary",
  "I1_16991;185_68545": "100",
  "I1_16978;154_64383;75_53865;250_37616": "Toll Fee",
  "1_17024": "From Pickup location 1",
  "I1_16977;154_64383;75_53865;250_37616": "Pickup Location 1",
  "1_17044": "5",
  "I1_16980;154_64382": "Usage Type",
  "I1_16981;154_64383;75_53865;250_37616": "01/02/2024 09:30",
  "1_17073": "Setting",
  "I1_16985;154_64383;75_53865;250_37616": "Drop-off Location 1",
  "I1_17060;21_22137": "Driver Management",
  "I1_16977;154_64382": "Pickup Location",
  "1_17006": "Frame 1000003169",
  "1_17070": "Activity List",
  "I1_17074;23_39072": "Work Management",
  "I1_16973;154_64382": "Pickup Date Time",
  "1_17036": "Passenger Evaluation",
  "I1_16984;154_64382": "Vehicle Number Plate",
  "I1_16965;35_49548;28_31236": "Chauffeur Connect",
  "I1_17089;35_49503": "Create Meeting",
  "I1_17196;23_39072": "Vehicle Management",
  "I1_17191;23_39072": "Company setting",
  "I1_17116;30_32140": "Request Vehicle",
  "1_17135": "Drop-off Date Time: ",
  "I1_17151;75_53807": "Note For Driver",
  "1_17105": "Vehicle Group Name",
  "I1_17088;35_49548;28_31236": "Chauffeur Connect",
  "1_17131": "Passenger: ",
  "1_17142": "Sales",
  "I1_17147;75_53865;250_37564": "Business",
  "1_17121": "Activity Detail",
  "1_17180": "From Pickup location B",
  "1_17185": "5",
  "1_17113": "Group Description",
  "1_17100": "Driver Name",
  "1_17126": "01/02/2024 08:30 ",
  "1_17206": "Chauffeur Connect",
  "1_17157": "Total Distance: ",
  "I1_17215;23_39072": "Work Management",
  "I1_17202;21_22137": "Passenger Management",
  "I1_17088;35_49551;28_31261": ">",
  "1_17146": "Usage Type",
  "1_17125": "Pickup Date Time: ",
  "1_17162": "Frame 1000003169",
  "1_17187": "Client Portal",
  "1_17175": "From Pickup location A",
  "I1_17117;30_32137": "Back",
  "1_17141": "Department: ",
  "I1_17222;23_39072": "Notification",
  "1_17154": "Route Summary",
  "1_17129": "Pickup Location A",
  "I1_17088;35_49549;28_31261": ">",
  "I1_17201;21_22137": "Driver Management",
  "1_17093": "Choose a Vehicle",
  "1_17132": "Passenger A",
  "1_17099": "Number Plate",
  "1_17150": "Note For Driver",
  "1_17098": "Brand Model",
  "1_17138": "Drop-off Date Time: ",
  "I1_17189;21_22137": "Top",
  "I1_17088;35_49550;28_31236": "Activity List",
  "I1_17088;35_49552;28_31243": "Create Meeting",
  "1_17211": "Activity List",
  "1_17139": "Drop-off Location B",
  "1_17106": "Group Description",
  "I1_17217;23_39072": "Approval",
  "1_17136": "01/02/2024 09:30 ",
  "1_17158": "20km",
  "1_17214": "Setting",
  "1_17128": "Pickup Location: ",
  "1_17112": "Vehicle Group Name",
  "1_17161": "30 min",
  "1_17145": "*",
  "I1_17365;23_39072": "Notification",
  "1_17273": "Passenger: ",
  "1_17270": "Pickup Location: ",
  "1_17296": "Route Summary",
  "1_17240": "Brand Model",
  "I1_17259;30_32137": "Back",
  "1_17354": "Activity List",
  "I1_17230;35_49552;28_31243": "Create Airport",
  "1_17284": "Sales",
  "1_17255": "Group Description",
  "I1_17360;23_39072": "Approval",
  "1_17299": "Total Distance: ",
  "I1_17334;23_39072": "Company setting",
  "1_17241": "Number Plate",
  "I1_17332;21_22137": "Top",
  "1_17317": "From Pickup location A",
  "1_17288": "Usage Type",
  "I1_17358;23_39072": "Work Management",
  "1_17271": "Pickup Location A",
  "1_17283": "Department: ",
  "1_17303": "30 min",
  "I1_17345;21_22137": "Passenger Management",
  "1_17277": "Drop-off Date Time: ",
  "1_17280": "Drop-off Date Time: ",
  "1_17248": "Group Description",
  "1_17349": "Chauffeur Connect",
  "I1_17344;21_22137": "Driver Management",
  "1_17330": "Client Portal",
  "1_17287": "*",
  "1_17278": "01/02/2024 09:30 ",
  "1_17242": "Driver Name",
  "I1_17339;23_39072": "Vehicle Management",
  "1_17322": "From Pickup location B",
  "1_17274": "Passenger A",
  "1_17268": "01/02/2024 08:30 ",
  "I1_17230;35_49549;28_31261": ">",
  "I1_17230;35_49550;28_31236": "Activity List",
  "1_17235": "Choose a Vehicle",
  "1_17328": "5",
  "1_17254": "Vehicle Group Name",
  "I1_17230;35_49551;28_31261": ">",
  "1_17304": "Frame 1000003169",
  "1_17357": "Setting",
  "1_17267": "Pickup Date Time: ",
  "1_17292": "Note For Driver",
  "I1_17231;35_49503": "Create Airport",
  "1_17247": "Vehicle Group Name",
  "I1_17230;35_49548;28_31236": "Chauffeur Connect",
  "1_17281": "Drop-off Location B",
  "1_17300": "20km",
  "I1_17289;75_53865;250_37564": "Business",
  "1_17263": "Activity Detail",
  "I1_17258;30_32140": "Request Vehicle",
  "I1_17293;75_53807": "Note For Driver",
  "1_17495": "Setting",
  "1_17417": "Passenger A",
  "1_17466": "5",
  "1_17397": "Vehicle Group Name",
  "1_17378": "Choose a Vehicle",
  "I1_17503;23_39072": "Notification",
  "I1_17401;30_32140": "Request Vehicle",
  "1_17487": "Chauffeur Connect",
  "1_17427": "Sales",
  "I1_17373;35_49551;28_31261": ">",
  "I1_17373;35_49550;28_31236": "Activity List",
  "1_17391": "Group Description",
  "1_17416": "Passenger: ",
  "I1_17373;35_49552;28_31243": "Create Retal",
  "I1_17477;23_39072": "Vehicle Management",
  "1_17414": "Pickup Location A",
  "1_17406": "Activity Detail",
  "1_17435": "Route Summary",
  "I1_17496;23_39072": "Work Management",
  "1_17411": "01/02/2024 08:30 ",
  "1_17398": "Group Description",
  "1_17410": "Pickup Date Time: ",
  "1_17430": "*",
  "1_17439": "20km",
  "I1_17402;30_32137": "Back",
  "1_17420": "Drop-off Date Time: ",
  "I1_17472;23_39072": "Company setting",
  "I1_17498;23_39072": "Approval",
  "I1_17483;21_22137": "Passenger Management",
  "I1_17470;21_22137": "Top",
  "1_17461": "From Pickup location B",
  "1_17492": "Activity List",
  "1_17413": "Pickup Location: ",
  "1_17390": "Vehicle Group Name",
  "1_17431": "Usage Type",
  "1_17456": "From Pickup location A",
  "1_17383": "Brand Model",
  "1_17421": "01/02/2024 09:30 ",
  "1_17424": "Drop-off Location B",
  "1_17385": "Driver Name",
  "I1_17482;21_22137": "Driver Management",
  "1_17443": "Frame 1000003169",
  "I1_17373;35_49549;28_31261": ">",
  "1_17442": "30 min",
  "I1_17432;75_53865;250_37564": "Business",
  "1_17438": "Total Distance: ",
  "1_17423": "Drop-off Date Time: ",
  "1_17384": "Number Plate",
  "I1_17373;35_49548;28_31236": "Chauffeur Connect",
  "1_17468": "Client Portal",
  "1_17426": "Department: ",
  "I1_17374;35_49503": "Create Retal",
  "1_17528": "Vehicle Group Name",
  "1_17569": "Usage Type",
  "1_17522": "Number Plate",
  "1_17577": "20km",
  "1_17555": "Passenger A",
  "I1_17539;30_32140": "Request Vehicle",
  "I1_17615;23_39072": "Vehicle Management",
  "1_17576": "Total Distance: ",
  "1_17562": "Drop-off Location B",
  "I1_17512;35_49503": "Create Package",
  "I1_17540;30_32137": "Back",
  "I1_17634;23_39072": "Work Management",
  "1_17568": "*",
  "1_17521": "Brand Model",
  "1_17606": "Client Portal",
  "I1_17570;75_53865;250_37564": "Business",
  "1_17630": "Activity List",
  "1_17604": "5",
  "I1_17641;23_39072": "Notification",
  "1_17594": "From Pickup location A",
  "1_17625": "Chauffeur Connect",
  "I1_17511;35_49551;28_31261": ">",
  "1_17554": "Passenger: ",
  "I1_17610;23_39072": "Company setting",
  "1_17536": "Group Description",
  "1_17516": "Choose a Vehicle",
  "I1_17621;21_22137": "Passenger Management",
  "I1_17620;21_22137": "Driver Management",
  "I1_17511;35_49549;28_31261": ">",
  "1_17573": "Route Summary",
  "1_17535": "Vehicle Group Name",
  "I1_17636;23_39072": "Approval",
  "1_17561": "Drop-off Date Time: ",
  "1_17633": "Setting",
  "1_17549": "01/02/2024 08:30 ",
  "1_17552": "Pickup Location A",
  "1_17564": "Department: ",
  "1_17558": "Drop-off Date Time: ",
  "1_17599": "From Pickup location B",
  "1_17559": "01/02/2024 09:30 ",
  "I1_17511;35_49550;28_31236": "Activity List",
  "1_17581": "Frame 1000003169",
  "1_17580": "30 min",
  "I1_17511;35_49552;28_31243": "Create Package",
  "1_17548": "Pickup Date Time: ",
  "1_17551": "Pickup Location: ",
  "1_17529": "Group Description",
  "1_17565": "Sales",
  "1_17523": "Driver Name",
  "I1_17608;21_22137": "Top",
  "1_17544": "Activity Detail",
  "I1_17511;35_49548;28_31236": "Chauffeur Connect",
  "1_17659": "Brand Model",
  "1_17711": "Note For Driver",
  "1_17702": "Department: ",
  "1_17667": "Group Description",
  "1_17696": "Drop-off Date Time: ",
  "1_17715": "Route Summary",
  "1_17768": "Chauffeur Connect",
  "1_17736": "From Pickup location A",
  "1_17687": "01/02/2024 08:30 ",
  "1_17697": "01/02/2024 09:30 ",
  "I1_17678;30_32137": "Back",
  "I1_17649;35_49552;28_31243": "Create Airport",
  "I1_17650;35_49503": "Create Airport",
  "I1_17712;75_53807": "Note For Driver",
  "1_17773": "Activity List",
  "I1_17708;75_53865;250_37564": "Business",
  "1_17682": "Activity Detail",
  "1_17690": "Pickup Location A",
  "I1_17777;23_39072": "Work Management",
  "1_17700": "Drop-off Location B",
  "1_17723": "Frame 1000003169",
  "1_17707": "Usage Type",
  "I1_17758;23_39072": "Vehicle Management",
  "1_17660": "Number Plate",
  "1_17699": "Drop-off Date Time: ",
  "I1_17649;35_49550;28_31236": "Activity List",
  "1_17776": "Setting",
  "I1_17677;30_32140": "Request Vehicle",
  "1_17654": "Choose a Vehicle",
  "1_17673": "Vehicle Group Name",
  "1_17689": "Pickup Location: ",
  "1_17686": "Pickup Date Time: ",
  "1_17703": "Sales",
  "1_17706": "*",
  "1_17661": "Driver Name",
  "1_17747": "5",
  "1_17666": "Vehicle Group Name",
  "1_17722": "30 min",
  "1_17718": "Total Distance: ",
  "I1_17649;35_49549;28_31261": ">",
  "I1_17764;21_22137": "Passenger Management",
  "I1_17784;23_39072": "Notification",
  "I1_17649;35_49551;28_31261": ">",
  "1_17674": "Group Description",
  "I1_17779;23_39072": "Approval",
  "1_17741": "From Pickup location B",
  "I1_17753;23_39072": "Company setting",
  "I1_17649;35_49548;28_31236": "Chauffeur Connect",
  "1_17719": "20km",
  "1_17749": "Client Portal",
  "1_17692": "Passenger: ",
  "I1_17751;21_22137": "Top",
  "I1_17763;21_22137": "Driver Management",
  "1_17693": "Passenger A",
  "1_17850": "ID 6",
  "1_17856": "Number Plate",
  "I1_18746;23_39072": "Vehicle Management",
  "1_17862": "ID 9",
  "1_18008": "Ride",
  "1_17867": "Brand Model",
  "1_17866": "ID 10",
  "I1_18741;23_39072": "Company setting",
  "1_17877": "02:00 AM",
  "1_17836": "Number Plate",
  "1_17889": "08:00 AM",
  "1_17879": "03:00 AM",
  "1_17875": "01:00 AM",
  "1_17844": "Number Plate",
  "1_17832": "Number Plate",
  "1_17955": "Passenger Name / Driver Name /  Destination Area",
  "I1_18730;28_31263;28_31261": ">",
  "1_18027": "Passenger Name / Driver Name /  Destination Area",
  "1_18077": "Passenger Name / Driver Name /  Destination Area",
  "1_17802": "January 01, 2024",
  "1_18009": "Passenger Name / Driver Name /  Destination Area",
  "1_17846": "ID 5",
  "1_17895": "11:00 AM",
  "1_18026": "Meeting",
  "1_17859": "Brand Model",
  "1_17847": "Brand Model",
  "I1_18772;23_39072": "Notification",
  "I1_18765;23_39072": "Work Management",
  "I1_18731;388_23148;30_32140": "Create",
  "1_18756": "Chauffeur Connect",
  "1_17873": "00:00 AM",
  "1_18059": "Passenger Name / Driver Name /  Destination Area",
  "1_18761": "Activity List",
  "1_17893": "10:00 AM",
  "1_17933": "Passenger Name / Driver Name /  Destination Area",
  "1_17840": "Number Plate",
  "1_18058": "Retal",
  "1_17972": "Ride",
  "1_17843": "Brand Model",
  "1_17848": "Number Plate",
  "1_17817": "Search...",
  "1_17883": "05:00 AM",
  "1_17801": "Today",
  "1_17835": "Brand Model",
  "1_17831": "Brand Model",
  "1_17932": "Ride",
  "1_17990": "Ride",
  "1_18076": "Ride",
  "1_18735": "5",
  "1_17915": "Passenger Name / Driver Name /  Destination Area",
  "1_17891": "09:00 AM",
  "1_17973": "Passenger Name / Driver Name /  Destination Area",
  "1_17863": "Brand Model",
  "I1_18731;388_23101": "Activity List",
  "1_17834": "ID 2",
  "1_17954": "Ride",
  "1_17881": "04:00 AM",
  "1_17868": "Number Plate",
  "1_17897": "12:00 AM",
  "I1_18751;21_22137": "Driver Management",
  "1_17809": "Vehicle",
  "1_17855": "Brand Model",
  "I1_18739;21_22137": "Top",
  "1_17839": "Brand Model",
  "1_17887": "07:00 AM",
  "1_17813": "Driver",
  "1_17842": "ID 4",
  "I1_18752;21_22137": "Passenger Management",
  "I1_18767;23_39072": "Approval",
  "1_18737": "Client Portal",
  "1_17951": "Passenger Name / Driver Name /  Destination Area",
  "I1_18730;28_31246;28_31236": "Chauffeur Connect",
  "1_17885": "06:00 AM",
  "1_17854": "ID 7",
  "1_17820": "Day",
  "I1_18730;28_31248;28_31243": "Activity List",
  "1_17864": "Number Plate",
  "1_17851": "Brand Model",
  "1_17852": "Number Plate",
  "1_18764": "Setting",
  "1_17950": "Airport",
  "1_17914": "Ride",
  "1_17858": "ID 8",
  "1_17830": "ID 1",
  "1_17860": "Number Plate",
  "1_17991": "Passenger Name / Driver Name /  Destination Area",
  "1_17838": "ID 3",
  "I1_19710;28_31263;28_31261": ">",
  "I1_19711;388_23148;30_32140": "Create",
  "1_18912": "Ride",
  "1_18827": "Brand Model",
  "I1_19747;23_39072": "Approval",
  "I1_18792;999_148509": "Vehicle",
  "1_19007": "Passenger Name / Driver Name /  Destination Area",
  "I1_19721;23_39072": "Company setting",
  "1_18935": "Passenger Name / Driver Name /  Destination Area",
  "1_19006": "Meeting",
  "1_18818": "ID 3",
  "1_18863": "05:00 AM",
  "1_19717": "Client Portal",
  "1_18873": "10:00 AM",
  "1_18877": "12:00 AM",
  "1_19744": "Setting",
  "1_19039": "Passenger Name / Driver Name /  Destination Area",
  "1_18810": "ID 1",
  "1_18811": "Brand Model",
  "1_18971": "Passenger Name / Driver Name /  Destination Area",
  "1_18820": "Number Plate",
  "I1_19732;21_22137": "Passenger Management",
  "1_19736": "Chauffeur Connect",
  "I1_19710;28_31246;28_31236": "Chauffeur Connect",
  "1_18828": "Number Plate",
  "1_19038": "Retal",
  "1_18838": "ID 8",
  "1_18846": "ID 10",
  "1_18834": "ID 7",
  "1_18796": "Search...",
  "1_18875": "11:00 AM",
  "1_18789": "Today",
  "1_18826": "ID 5",
  "I1_19731;21_22137": "Driver Management",
  "1_18953": "Passenger Name / Driver Name /  Destination Area",
  "1_18913": "Passenger Name / Driver Name /  Destination Area",
  "I1_19745;23_39072": "Work Management",
  "1_18895": "Passenger Name / Driver Name /  Destination Area",
  "1_18952": "Ride",
  "1_19056": "Ride",
  "1_18970": "Ride",
  "I1_18792;999_148513": "Driver",
  "1_18861": "04:00 AM",
  "1_18815": "Brand Model",
  "1_18819": "Brand Model",
  "1_18989": "Passenger Name / Driver Name /  Destination Area",
  "1_18867": "07:00 AM",
  "1_18894": "Ride",
  "1_18816": "Number Plate",
  "1_19741": "Activity List",
  "1_18930": "Airport",
  "1_18836": "Number Plate",
  "1_18843": "Brand Model",
  "1_18812": "Number Plate",
  "I1_19711;388_23101": "Activity List",
  "1_18848": "Number Plate",
  "I1_19719;21_22137": "Top",
  "1_18831": "Brand Model",
  "1_18859": "03:00 AM",
  "1_18824": "Number Plate",
  "1_18790": "January 01, 2024",
  "1_18855": "01:00 AM",
  "1_18934": "Ride",
  "1_18865": "06:00 AM",
  "1_18869": "08:00 AM",
  "1_18814": "ID 2",
  "I1_19710;28_31248;28_31243": "Activity List",
  "1_18847": "Brand Model",
  "1_19057": "Passenger Name / Driver Name /  Destination Area",
  "1_18857": "02:00 AM",
  "1_18842": "ID 9",
  "1_18830": "ID 6",
  "1_18853": "00:00 AM",
  "1_18839": "Brand Model",
  "1_19715": "5",
  "1_18931": "Passenger Name / Driver Name /  Destination Area",
  "1_18822": "ID 4",
  "1_18844": "Number Plate",
  "1_18799": "Day",
  "1_18823": "Brand Model",
  "1_18840": "Number Plate",
  "1_18988": "Ride",
  "1_18871": "09:00 AM",
  "I1_19726;23_39072": "Vehicle Management",
  "I1_19752;23_39072": "Notification",
  "1_18835": "Brand Model",
  "1_18832": "Number Plate",
  "1_19817": "Sunday 31/12",
  "I1_19833;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "I1_19882;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "1_19783": "ID 3",
  "I1_19865;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "I1_19915;28_31246;28_31236": "Chauffeur Connect",
  "I1_19877;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "I1_19858;8071_153418;7757_23302": "Ride",
  "1_19946": "Activity List",
  "I1_19897;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "I1_19833;8071_153419;7757_23302": "Ride",
  "I1_19957;23_39072": "Notification",
  "I1_19865;8071_153418;7757_23302": "Ride",
  "1_19821": "Tuesday 02/01",
  "I1_19904;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "I1_19937;21_22137": "Passenger Management",
  "I1_19833;8071_153421": "+3 more",
  "1_19796": "Brand Model",
  "1_19789": "Number Plate",
  "I1_19904;8071_153418;7757_23302": "Ride",
  "I1_19833;8071_153420;7757_23302": "Ride",
  "1_19780": "Brand Model",
  "1_19807": "ID 9",
  "I1_19926;23_39072": "Company setting",
  "1_19795": "ID 6",
  "I1_19851;8071_153418;7757_23302": "Ride",
  "1_19779": "ID 2",
  "1_19801": "Number Plate",
  "I1_19767;999_148537": "Vehicle",
  "I1_19764;30_32137": "Today",
  "1_19765": "31/12/2023 - 06/01/2024",
  "I1_19768;121_59049": "Search...",
  "1_19809": "Number Plate",
  "1_19787": "ID 4",
  "1_19799": "ID 7",
  "I1_19950;23_39072": "Work Management",
  "I1_19833;8071_153418;7757_23302": "Ride",
  "I1_19882;8071_153418;7757_23302": "Ride",
  "1_19800": "Brand Model",
  "1_19829": "Saturday 06/01",
  "1_19812": "Brand Model",
  "1_19949": "Setting",
  "I1_19924;21_22137": "Top",
  "I1_19931;23_39072": "Vehicle Management",
  "1_19803": "ID 8",
  "I1_19915;28_31248;28_31243": "Activity List",
  "1_19811": "ID 10",
  "I1_19877;8071_153418;7757_23302": "Ride",
  "1_19804": "Brand Model",
  "1_19784": "Brand Model",
  "I1_19844;8071_153418;7757_23302": "Ride",
  "1_19920": "5",
  "1_19922": "Client Portal",
  "1_19775": "ID 1",
  "1_19781": "Number Plate",
  "1_19797": "Number Plate",
  "I1_19915;28_31263;28_31261": ">",
  "I1_19952;23_39072": "Approval",
  "1_19813": "Number Plate",
  "I1_19844;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "1_19793": "Number Plate",
  "1_19805": "Number Plate",
  "1_19785": "Number Plate",
  "1_19788": "Brand Model",
  "I1_19851;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "I1_19767;999_148541": "Driver",
  "1_19827": "Friday 05/01",
  "I1_19833;8071_153419;7757_23303": "09:00 - 17:00 / Passenger Name",
  "1_19808": "Brand Model",
  "1_19941": "Chauffeur Connect",
  "I1_19916;388_23101": "Activity List",
  "I1_19916;388_23148;30_32140": "Create",
  "1_19819": "Monday 01/01",
  "1_19791": "ID 5",
  "1_19776": "Brand Model",
  "1_19823": "Wednesday 03/01",
  "I1_19936;21_22137": "Driver Management",
  "I1_19858;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "I1_19897;8071_153418;7757_23302": "Ride",
  "1_19777": "Number Plate",
  "1_19825": "Thursday 04/01",
  "I1_19769;75_53865;497_44120": "Week",
  "I1_19833;8071_153420;7757_23303": "09:00 - 17:00 / Passenger Name",
  "1_19792": "Brand Model",
  "I1_20038;8071_153418;7757_23302": "Ride",
  "1_19989": "Brand Model",
  "I1_20120;28_31248;28_31243": "Activity List",
  "I1_20120;28_31246;28_31236": "Chauffeur Connect",
  "1_19984": "ID 2",
  "1_20017": "Brand Model",
  "1_20022": "Sunday 31/12",
  "1_20010": "Number Plate",
  "1_20028": "Wednesday 03/01",
  "I1_19973;121_59049": "Search...",
  "1_19993": "Brand Model",
  "1_20005": "Brand Model",
  "1_20006": "Number Plate",
  "1_19988": "ID 3",
  "I1_20056;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "1_20024": "Monday 01/01",
  "1_20127": "Client Portal",
  "I1_20070;8071_153418;7757_23302": "Ride",
  "1_20154": "Setting",
  "1_20146": "Chauffeur Connect",
  "1_20013": "Brand Model",
  "1_19998": "Number Plate",
  "1_19990": "Number Plate",
  "I1_20087;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "I1_20102;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "I1_20063;8071_153418;7757_23302": "Ride",
  "I1_20082;8071_153418;7757_23302": "Ride",
  "I1_20109;8071_153418;7757_23302": "Ride",
  "I1_20136;23_39072": "Vehicle Management",
  "1_20026": "Tuesday 02/01",
  "1_20000": "ID 6",
  "I1_20049;8071_153418;7757_23302": "Ride",
  "1_20004": "ID 7",
  "I1_20038;8071_153419;7757_23302": "Ride",
  "1_20018": "Number Plate",
  "I1_20038;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "I1_19974;75_53865;497_44120": "Week",
  "I1_20155;23_39072": "Work Management",
  "I1_20157;23_39072": "Approval",
  "1_19981": "Brand Model",
  "I1_20121;388_23148;30_32140": "Create",
  "I1_20056;8071_153418;7757_23302": "Ride",
  "1_20030": "Thursday 04/01",
  "1_20125": "5",
  "1_20032": "Friday 05/01",
  "I1_19972;999_148509": "Vehicle",
  "I1_20109;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "I1_20038;8071_153420;7757_23303": "09:00 - 17:00 / Passenger Name",
  "I1_20038;8071_153419;7757_23303": "09:00 - 17:00 / Passenger Name",
  "1_20008": "ID 8",
  "I1_20131;23_39072": "Company setting",
  "1_20009": "Brand Model",
  "1_20034": "Saturday 06/01",
  "1_19982": "Number Plate",
  "I1_20038;8071_153420;7757_23302": "Ride",
  "1_19992": "ID 4",
  "1_20002": "Number Plate",
  "1_20001": "Brand Model",
  "1_20012": "ID 9",
  "I1_20070;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "I1_20142;21_22137": "Passenger Management",
  "1_20014": "Number Plate",
  "I1_20087;8071_153418;7757_23302": "Ride",
  "1_19994": "Number Plate",
  "I1_20141;21_22137": "Driver Management",
  "1_19997": "Brand Model",
  "I1_20038;8071_153421": "+3 more",
  "1_19985": "Brand Model",
  "1_19970": "31/12/2023 - 06/01/2024",
  "I1_20082;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "I1_20129;21_22137": "Top",
  "I1_20121;388_23101": "Activity List",
  "I1_20120;28_31263;28_31261": ">",
  "I1_20102;8071_153418;7757_23302": "Ride",
  "I1_19969;30_32137": "Today",
  "1_19980": "ID 1",
  "I1_20162;23_39072": "Notification",
  "1_20016": "ID 10",
  "1_19996": "ID 5",
  "I1_19972;999_148513": "Driver",
  "1_20151": "Activity List",
  "I1_20049;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "1_19986": "Number Plate",
  "I1_20063;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "1_20201": "Client Portal",
  "I1_20191;956_83002": "Phone code",
  "I1_20170;28_31248;28_31243": "Emergency Contact",
  "I1_20171;35_49503": "Emergency Contact",
  "I1_20211;250_58575": "Emergency Contact",
  "1_20181": "Contact Name",
  "I1_20210;250_58566": "Holidays",
  "I1_20218;21_22137": "Driver Management",
  "1_20180": "*",
  "1_20222": "Chauffeur Connect",
  "I1_20203;21_22137": "Top",
  "I1_20184;75_53807": "Phone Number",
  "I1_20235;23_39072": "Notification",
  "I1_20208;250_58566": "Business Hours",
  "I1_20219;21_22137": "Passenger Management",
  "I1_20207;250_58566": "Department Management",
  "I1_20209;250_58566": "Overtime Rules",
  "I1_20205;23_39084": "Company setting",
  "I1_20192;75_53807": "Phone Number",
  "I1_20230;23_39072": "Approval",
  "I1_20195;30_32140": "Save",
  "I1_20170;28_31263;28_31261": ">",
  "I1_20225;23_39072": "Work Management",
  "1_20199": "5",
  "1_20187": "*",
  "1_20188": "Phone Number",
  "1_20174": "SOS",
  "I1_20170;28_31246;28_31236": "Company Settings",
  "I1_20213;23_39072": "Vehicle Management",
  "1_20248": "SOS",
  "I1_20311;23_39072": "Vehicle Management",
  "I1_20308;250_58566": "Holidays",
  "I1_20288;75_53807": "Phone Number",
  "I1_20265;956_83002": "Phone code",
  "I1_20323;23_39072": "Work Management",
  "1_20261": "*",
  "I1_20244;35_49503": "Emergency Contact",
  "1_20284": "Phone Number",
  "I1_20266;75_53807": "Phone Number",
  "I1_20258;75_53807": "Phone Number",
  "1_20270": "Contact",
  "I1_20243;28_31246;28_31236": "Company Settings",
  "I1_20309;250_58575": "Emergency Contact",
  "1_20255": "Contact Name",
  "1_20262": "Phone Number",
  "I1_20305;250_58566": "Department Management",
  "I1_20333;23_39072": "Notification",
  "I1_20287;956_83002": "Phone code",
  "I1_20316;21_22137": "Driver Management",
  "I1_20293;30_32140": "Save",
  "1_20320": "Chauffeur Connect",
  "1_20283": "*",
  "1_20277": "Contact Name",
  "1_20276": "*",
  "I1_20243;28_31248;28_31243": "Emergency Contact",
  "I1_20306;250_58566": "Business Hours",
  "I1_20328;23_39072": "Approval",
  "I1_20307;250_58566": "Overtime Rules",
  "I1_20317;21_22137": "Passenger Management",
  "I1_20280;75_53807": "Phone Number",
  "I1_20301;21_22137": "Top",
  "1_20299": "Client Portal",
  "I1_20303;23_39084": "Company setting",
  "1_20254": "*",
  "I1_20243;28_31263;28_31261": ">",
  "1_20297": "5",
  "I1_20407;956_83002": "Phone code",
  "1_20404": "Phone Number",
  "I1_20342;35_49503": "Emergency Contact",
  "I1_20429;23_39072": "Vehicle Management",
  "I1_20451;23_39072": "Notification",
  "1_20396": "*",
  "1_20438": "Chauffeur Connect",
  "I1_20435;21_22137": "Passenger Management",
  "1_20382": "Phone Number",
  "I1_20419;21_22137": "Top",
  "I1_20341;28_31263;28_31261": ">",
  "1_20397": "Contact Name",
  "I1_20446;23_39072": "Approval",
  "I1_20421;23_39084": "Company setting",
  "I1_20341;28_31248;28_31243": "Emergency Contact",
  "I1_20363;956_83002": "Phone code",
  "1_20346": "SOS",
  "I1_20341;28_31246;28_31236": "Company Settings",
  "1_20415": "5",
  "I1_20441;23_39072": "Work Management",
  "1_20353": "Contact Name",
  "I1_20408;75_53807": "Phone Number",
  "I1_20426;250_58566": "Holidays",
  "1_20368": "Contact 1",
  "1_20359": "*",
  "I1_20427;250_58575": "Emergency Contact",
  "I1_20386;75_53807": "Phone Number",
  "I1_20356;75_53807": "Phone Number",
  "1_20375": "Contact Name",
  "I1_20434;21_22137": "Driver Management",
  "1_20352": "*",
  "1_20417": "Client Portal",
  "1_20390": "Contact 2",
  "I1_20423;250_58566": "Department Management",
  "I1_20400;75_53807": "Phone Number",
  "I1_20424;250_58566": "Business Hours",
  "I1_20411;30_32140": "Save",
  "I1_20425;250_58566": "Overtime Rules",
  "1_20374": "*",
  "I1_20364;75_53807": "Phone Number",
  "I1_20385;956_83002": "Phone code",
  "1_20360": "Phone Number",
  "1_20381": "*",
  "I1_20378;75_53807": "Phone Number",
  "1_20403": "*",
  "I1_20575;21_22137": "Passenger Management",
  "I1_20518;75_53807": "Phone Number",
  "I1_20540;75_53807": "Phone Number",
  "I1_20496;75_53807": "Phone Number",
  "I1_20567;250_58575": "Emergency Contact",
  "I1_20547;956_83002": "Phone code",
  "1_20492": "*",
  "1_20555": "5",
  "1_20464": "SOS",
  "I1_20574;21_22137": "Driver Management",
  "I1_20569;23_39072": "Vehicle Management",
  "I1_20481;956_83002": "Phone code",
  "1_20578": "Chauffeur Connect",
  "I1_20559;21_22137": "Top",
  "1_20514": "*",
  "I1_20548;75_53807": "Phone Number",
  "1_20470": "*",
  "I1_20503;956_83002": "Phone code",
  "I1_20460;35_49503": "Emergency Contact",
  "1_20521": "*",
  "1_20544": "Phone Number",
  "I1_20591;23_39072": "Notification",
  "1_20500": "Phone Number",
  "1_20493": "Contact Name",
  "I1_20564;250_58566": "Business Hours",
  "I1_20482;75_53807": "Phone Number",
  "I1_20563;250_58566": "Department Management",
  "I1_20504;75_53807": "Phone Number",
  "1_20508": "Contact 2",
  "I1_20459;28_31248;28_31243": "Emergency Contact",
  "I1_20561;23_39084": "Company setting",
  "1_20530": "Contact 3",
  "I1_20526;75_53807": "Phone Number",
  "1_20522": "Phone Number",
  "1_20499": "*",
  "1_20471": "Contact Name",
  "1_20557": "Client Portal",
  "I1_20551;30_32140": "Save",
  "I1_20581;23_39072": "Work Management",
  "I1_20525;956_83002": "Phone code",
  "1_20543": "*",
  "1_20478": "Phone Number",
  "I1_20566;250_58566": "Holidays",
  "1_20477": "*",
  "I1_20586;23_39072": "Approval",
  "I1_20459;28_31246;28_31236": "Company Settings",
  "I1_20474;75_53807": "Phone Number",
  "1_20536": "*",
  "1_20537": "Contact Name",
  "I1_20459;28_31263;28_31261": ">",
  "I1_20565;250_58566": "Overtime Rules",
  "1_20515": "Contact Name",
  "1_20486": "Contact 1",
  "1_20684": "Start",
  "I1_20632;8068_76053": "27",
  "1_20701": "14:15",
  "I1_20643;8068_76053": "8",
  "I1_20660;8068_76053": "23",
  "I1_20644;8068_76053": "9",
  "I1_20639;8068_76053": "4",
  "I1_20630;8068_76055": "25",
  "1_20722": "Distance",
  "I1_20635;8068_76053": "1",
  "1_20700": "14:00",
  "I1_20751;250_58566": "Vehicle Depot",
  "I1_20668;8068_76055": "30",
  "I1_20602;758_37670;758_37454": "Vehicle Information",
  "I1_20634;8068_76053": "29",
  "I1_20652;8068_76053": "16",
  "I1_20648;8068_76053": "12",
  "I1_20651;8068_76051": "15",
  "I1_20675;8068_76055": "5",
  "I1_20673;8068_76055": "3",
  "1_20679": "Driving History",
  "I1_20599;35_49550;28_31236": "Manage Vehicle",
  "1_20723": "90.5 km",
  "I1_20599;35_49551;28_31261": ">",
  "1_20690": "Idling",
  "1_20707": "14:50",
  "I1_20638;8068_76053": "3",
  "1_20615": "Su",
  "I1_20599;35_49548;28_31236": "Vehicle Management",
  "1_20603": "Frame 1000003129",
  "1_20619": "Tu",
  "I1_20636;8068_76053": "2",
  "I1_20631;8068_76055": "26",
  "I1_20657;8068_76053": "20",
  "1_20693": "15 mins",
  "I1_20656;8068_76053": "19",
  "I1_20642;8068_76053": "7",
  "1_20725": "Duration",
  "I1_20649;8068_76053": "13",
  "1_20756": "Chauffeur Connect",
  "I1_20676;8068_76055": "6",
  "I1_20602;758_37671;758_37460": "Vehicle Usage",
  "I1_20664;8068_76053": "26",
  "1_20733": "Client Portal",
  "I1_20747;23_39084": "Vehicle Management",
  "1_20685": "13:00",
  "I1_20752;21_22137": "Driver Management",
  "I1_20659;8068_76053": "22",
  "I1_20641;8068_76053": "6",
  "I1_20650;8068_76053": "14",
  "I1_20666;8068_76053": "28",
  "I1_20609;956_83002": "2024",
  "1_20617": "Mo",
  "I1_20667;8068_76055": "29",
  "I1_20611;999_148541": "Year",
  "1_20625": "Fr",
  "I1_20671;8068_76055": "1",
  "I1_20735;21_22137": "Top",
  "I1_20769;23_39072": "Notification",
  "1_20623": "Th",
  "I1_20611;999_148537": "Month",
  "I1_20658;8068_76053": "21",
  "I1_20749;250_58575": "Manage Vehicle",
  "I1_20753;21_22137": "Passenger Management",
  "I1_20737;23_39072": "Company setting",
  "I1_20655;8068_76053": "18",
  "1_20706": "End",
  "I1_20750;250_58566": "Vehicle Group",
  "I1_20640;8068_76053": "5",
  "I1_20764;23_39072": "Approval",
  "I1_20727;30_32137": "Cancel",
  "I1_20610;956_83002": "Feb",
  "I1_20670;8068_76055": "31",
  "I1_20663;8068_76053": "25",
  "I1_20654;8068_76053": "17",
  "I1_20599;35_49549;28_31261": ">",
  "I1_20646;8068_76053": "10",
  "1_20621": "We",
  "1_20726": "01:50:35",
  "I1_20674;8068_76055": "4",
  "1_20627": "Sa",
  "I1_20647;8068_76053": "11",
  "1_20731": "5",
  "I1_20633;8068_76053": "28",
  "I1_20672;8068_76055": "2",
  "I1_20665;8068_76053": "27",
  "I1_20662;8068_76053": "24",
  "I1_20759;23_39072": "Work Management",
  "I1_20848;8080_62383": "08:30 - 17:30",
  "1_20831": "Sun",
  "I1_20876;8080_62384": "Day Shift",
  "1_20819": "2h",
  "I1_20872;8080_62383": "08:30 - 17:30",
  "I1_20847;8080_62591": "1",
  "I1_20859;8080_62591": "12",
  "I1_20911;21_22137": "Passenger Management",
  "I1_20863;8080_62383": "08:30 - 17:30",
  "I1_20881;8080_62384": "Day Shift",
  "I1_20872;8080_62591": "23",
  "I1_20850;8080_62383": "08:30 - 17:30",
  "I1_20847;8080_62383": "08:30 - 17:30",
  "1_20815": "Operation Rate",
  "I1_20857;8080_62384": "Day Shift",
  "I1_20872;8080_62384": "Day Shift",
  "I1_20878;8080_62384": "Close",
  "I1_20868;8080_62383": "08:30 - 17:30",
  "I1_20863;8080_62384": "Day Shift",
  "I1_20857;8080_62383": "08:30 - 17:30",
  "I1_20802;996_50815;758_37460": "Work Report",
  "I1_20867;8080_62384": "Day Shift",
  "I1_20849;8080_62384": "All Day",
  "I1_20854;8080_62591": "7",
  "1_20841": "Fri",
  "I1_20880;8080_62384": "Day Shift",
  "I1_20880;8080_62591": "30",
  "I1_20871;8080_62384": "Day Shift",
  "I1_20865;8080_62383": "08:30 - 17:30",
  "I1_20855;8080_62384": "Day Shift",
  "I1_20858;8080_62383": "08:30 - 17:30",
  "I1_20855;8080_62589;7757_23302": "Approved",
  "I1_20871;8080_62383": "08:30 - 17:30",
  "I1_20802;996_50816;758_37454": "Driver Evaluation",
  "I1_20879;8080_62591": "29",
  "I1_20858;8080_62591": "11",
  "I1_20893;21_22137": "Top",
  "I1_20847;8080_62384": "Day Shift",
  "I1_20876;8080_62591": "27",
  "1_20808": "Driver Department",
  "I1_20866;8080_62383": "07:45 - _____",
  "I1_20873;8080_62591": "24",
  "1_20891": "Client Portal",
  "I1_20917;23_39072": "Work Management",
  "I1_20850;8080_62384": "Day Shift",
  "I1_20910;21_27541": "Driver Management",
  "I1_20851;8080_62591": "5",
  "I1_20859;8080_62383": "08:30 - 17:30",
  "I1_20866;8080_62384": "Day Shift",
  "I1_20871;8080_62591": "22",
  "1_20824": "Holidays Worked",
  "I1_20865;8080_62384": "Day Shift",
  "I1_20865;8080_62591": "17",
  "I1_20864;8080_62384": "Day Shift",
  "I1_20866;8080_62591": "18",
  "1_20822": "2h",
  "I1_20858;8080_62384": "Day Shift",
  "1_20835": "Tue",
  "I1_20855;8080_62383": "08:30 - 17:30",
  "1_20825": "2",
  "I1_20860;8080_62383": "08:30 - 17:30",
  "I1_20881;8080_62591": "31",
  "I1_20895;23_39072": "Company setting",
  "I1_20875;8080_62384": "Day Shift",
  "I1_20880;8080_62383": "08:30 - 17:30",
  "I1_20780;28_31261": ">",
  "I1_20927;23_39072": "Notification",
  "I1_20880;8080_62385": "Absence",
  "I1_20879;8080_62383": "08:30 - 17:30",
  "I1_20849;8080_62383": "08:30 - 17:30",
  "I1_20856;8080_62591": "9",
  "I1_20873;8080_62383": "08:30 - 17:30",
  "I1_20873;8080_62384": "Day Shift",
  "I1_20860;8080_62384": "Day Shift",
  "I1_20874;8080_62384": "Day Shift",
  "1_20843": "Sat",
  "I1_20864;8080_62383": "08:30 - 17:30",
  "I1_20862;8080_62383": "08:30 - 17:30",
  "I1_20863;8080_62591": "15",
  "I1_20857;8080_62591": "10",
  "I1_20850;8080_62591": "4",
  "1_20914": "Chauffeur Connect",
  "I1_20868;8080_62384": "Day Shift",
  "1_20821": "Overtime",
  "1_20818": "Hour Worked",
  "I1_20852;8080_62591": "6",
  "I1_20859;8080_62384": "Day Shift",
  "I1_20854;8080_62384": "Close",
  "I1_20855;8080_62591": "8",
  "1_20782": "View Driver",
  "1_20809": "Employee Number",
  "I1_20870;8080_62384": "Close",
  "I1_20862;8080_62591": "14",
  "I1_20856;8080_62384": "Day Shift",
  "289_32253": "uploadFile",
  "I1_20851;8080_62384": "Day Shift",
  "I1_20868;8080_62591": "20",
  "1_20837": "Wed",
  "I1_20862;8080_62384": "Day Shift",
  "I1_20870;8080_62591": "21",
  "I1_20826;497_44890": "01/2024",
  "I1_20851;8080_62383": "08:30 - 17:30",
  "I1_20880;8080_62589;7757_23302": "Pending",
  "1_20839": "Thu",
  "I1_20867;8080_62591": "19",
  "1_20889": "5",
  "I1_20875;8080_62591": "26",
  "I1_20881;8080_62383": "08:30 - 17:30",
  "I1_20864;8080_62591": "16",
  "I1_20878;8080_62591": "28",
  "I1_20856;8080_62383": "08:30 - 17:30",
  "I1_20879;8080_62384": "Day Shift",
  "I1_20875;8080_62383": "08:30 - 17:30",
  "1_20833": "Mon",
  "1_20800": "View Driver",
  "1_20908": "Vehicle Management",
  "I1_20874;8080_62591": "25",
  "I1_20922;23_39072": "Approval",
  "I1_20885;30_32137": "Cancel",
  "1_20779": "Driver Management",
  "I1_20859;8080_62385": "Absence",
  "I1_20848;8080_62384": "Evening Shift",
  "I1_20848;8080_62591": "2",
  "I1_20859;8080_62589;7757_23302": "Disapproved",
  "I1_20855;8080_62385": "Absence",
  "I1_20802;996_50814;758_37454": "Driver Information",
  "I1_20860;8080_62591": "13",
  "I1_20874;8080_62383": "08:30 - 17:30",
  "I1_20849;8080_62591": "3",
  "1_20816": "2%",
  "I1_20876;8080_62383": "08:30 - 17:30",
  "1_20812": "Worksheet",
  "1_20806": "Driver Name",
  "I1_20867;8080_62383": "08:30 - 17:30",
  "I1_21044;8068_76053": "9",
  "I1_21157;611_95467;30_32137": "Cancel",
  "I1_21085;8068_76055": "04",
  "1_21184": "Saved place name",
  "1_20940": "Set location on map",
  "I1_21092;8068_76051": "00",
  "I1_21205;30_32137": "Cancel",
  "I1_21118;171_66633": "Passenger ID",
  "I1_21187;30_32140": "Edit",
  "I1_21064;8068_76053": "26",
  "1_21106": "Ok",
  "I1_21055;8068_76053": "18",
  "I1_21157;611_95468;611_95349": "Confirm",
  "I1_21066;8068_76053": "28",
  "I1_21057;8068_76053": "20",
  "I1_21049;8068_76053": "13",
  "1_21027": "Sa",
  "I1_21167;7636_13405;121_58638": "Name",
  "I1_21081;8068_76051": "00",
  "I1_21159;611_93748": "Warning",
  "I1_21202;7636_13403": "* Address",
  "1_20991": "13",
  "1_21019": "Tu",
  "I1_21076;8068_76053": "6",
  "I1_21084;8068_76055": "03",
  "I1_21152;368_110840": "Show 1 to 10 of 100 results",
  "I1_21152;581_1954;7882_26229;433_34538": "10",
  "I1_21114;121_59079": "Search...",
  "1_21110": "Select Passenger",
  "I1_21126;18_21709": "Content",
  "I1_21062;8068_76053": "24",
  "I1_21149;18_21709": "Content",
  "I1_21144;18_21709": "Content",
  "I1_21034;8068_76053": "29",
  "I1_21202;7636_13405;75_53807": "Address",
  "1_20952": "Recent address detail, street, city, country",
  "I1_21071;8068_76053": "1",
  "I1_21096;8068_76055": "04",
  "1_20969": "15",
  "I1_21152;581_1954;7882_21473;497_44116": "1",
  "I1_21168;7636_13403": "* Address",
  "I1_21158;611_95467;30_32137": "Cancel",
  "I1_21156;30_32140": "Save",
  "1_21223": "Reservation ",
  "1_20946": "Your current location",
  "I1_21075;8068_76053": "5",
  "I1_21052;8068_76053": "16",
  "1_21212": "Sorry, no available vehicles now",
  "1_20965": "13",
  "I1_21042;8068_76053": "7",
  "I1_21050;8068_76053": "14",
  "I1_21235;30_32140": "Ok",
  "1_20985": "10",
  "1_21198": "Add location",
  "1_21021": "We",
  "1_20957": "Recent address name",
  "I1_21158;611_93749": "Are you sure you want to delete Saved Place ?",
  "1_20971": "16",
  "I1_21038;8068_76053": "3",
  "I1_21040;8068_76053": "5",
  "I1_21167;7636_13403": "* Name",
  "I1_21047;8068_76053": "11",
  "I1_21127;18_21709": "Content",
  "1_20983": "09",
  "1_21023": "Th",
  "I1_21137;18_21709": "Content",
  "1_20973": "17",
  "I1_21054;8068_76053": "17",
  "1_20995": "15",
  "I1_21120;171_66633": "Email Address",
  "I1_21083;8068_76055": "02",
  "1_20963": "12",
  "I1_21245;30_32137": "Cancel",
  "I1_21088;8068_76055": "07",
  "1_21015": "Su",
  "1_20987": "11",
  "I1_21152;581_1954;7882_21853;433_34538": "2",
  "1_21193": "Add a new place",
  "I1_21145;18_21709": "Content",
  "I1_21086;8068_76055": "05",
  "I1_21131;18_21709": "Content",
  "I1_21059;8068_76053": "22",
  "I1_21148;18_21709": "Content",
  "1_20945": "Current location name",
  "I1_21151;18_21709": "Content",
  "I1_21063;8068_76053": "25",
  "I1_21159;611_95468;611_95349": "Confirm",
  "I1_21031;8068_76055": "26",
  "I1_21201;7636_13405;75_53807": "Name",
  "1_21164": "Edit Location",
  "I1_21159;611_95467;30_32137": "Cancel",
  "I1_21056;8068_76053": "19",
  "I1_21168;7636_13405;121_58638": "Address",
  "I1_21150;18_21709": "Content",
  "I1_21152;581_1954;7882_22097;433_34538": "4",
  "I1_21072;8068_76053": "2",
  "I1_21032;8068_76053": "27",
  "I1_21139;18_21709": "Content",
  "I1_21087;8068_76055": "06",
  "I1_21157;611_93749": "Are you sure you want to delete Activity ?",
  "I1_21138;18_21709": "Content",
  "I1_21121;171_66633": "Department",
  "I1_21067;8068_76055": "29",
  "I1_21036;8068_76053": "2",
  "1_20993": "14",
  "I1_21046;8068_76053": "10",
  "I1_21143;18_21709": "Content",
  "I1_21035;8068_76053": "1",
  "I1_21041;8068_76053": "6",
  "I1_21152;581_1954;7882_26275;433_34538": "11",
  "I1_21152;581_1954;7882_19131;497_44116": "10 / page",
  "1_21025": "Fr",
  "I1_21206;30_32140": "Save",
  "1_20958": "Recent address detail, street, city, country",
  "1_20981": "08",
  "I1_21158;611_93748": "Warning",
  "1_21226": "Successfully Confirmed",
  "I1_21099;8068_76055": "07",
  "1_21009": "Mar 2024",
  "I1_21159;611_93749": "Are you sure you want to cancel ?",
  "I1_21132;18_21709": "Content",
  "I1_21158;611_95468;611_95349": "Confirm",
  "I1_21065;8068_76053": "27",
  "I1_21125;18_21709": "Content",
  "I1_21033;8068_76053": "28",
  "I1_21068;8068_76055": "30",
  "I1_21119;171_66633": "Passenger Name",
  "I1_21030;8068_76055": "25",
  "I1_21094;8068_76055": "02",
  "1_21233": "Driver Name",
  "I1_21133;18_21709": "Content",
  "I1_21098;8068_76055": "06",
  "1_21241": "Finding vehicles...",
  "1_20967": "14",
  "I1_21130;18_21709": "Content",
  "1_21176": "Choose a Location",
  "I1_21082;8068_76055": "01",
  "I1_21124;18_21709": "Content",
  "I1_21157;611_93748": "Warning",
  "1_20975": "18",
  "I1_21089;8068_76055": "08",
  "1_21185": "Address detail, street, city, country",
  "I1_21058;8068_76053": "21",
  "I1_21074;8068_76053": "4",
  "1_20977": "19",
  "I1_21201;7636_13403": "* Name",
  "I1_21136;18_21709": "Content",
  "1_21231": "Brand Model",
  "I1_21051;8068_76051": "15",
  "I1_21093;8068_76055": "01",
  "I1_21095;8068_76055": "03",
  "I1_21152;581_1954;7882_26160;433_34538": "...",
  "1_21001": "Ok",
  "1_20989": "12",
  "1_21232": "Number Plate",
  "I1_21172;30_32140": "Save",
  "I1_21073;8068_76053": "3",
  "I1_20935;403_60848": "Saved Location",
  "I1_21043;8068_76053": "8",
  "I1_21039;8068_76053": "4",
  "I1_21152;581_1954;7882_21971;433_34538": "3",
  "I1_21142;18_21709": "Content",
  "I1_21100;8068_76055": "08",
  "I1_21097;8068_76055": "05",
  "I1_21155;30_32137": "Cancel",
  "I1_21070;8068_76053": "31",
  "1_20951": "Recent address name",
  "I1_21217;30_32137": "Back",
  "I1_21048;8068_76053": "12",
  "I1_21216;30_32140": "Keep Looking ( Up tp 3 Min )",
  "I1_21060;8068_76053": "23",
  "I1_21171;30_31931": "Delete",
  "1_21017": "Mo",
  "1_21360": "Successfully Confirmed",
  "I1_21339;8068_76055": "02",
  "I1_21305;8068_76053": "23",
  "I1_21330;8068_76055": "04",
  "I1_21281;8068_76053": "2",
  "1_21254": "Mar 2024",
  "I1_21344;8068_76055": "07",
  "1_21260": "Su",
  "I1_21320;8068_76053": "5",
  "I1_21337;8068_76051": "00",
  "1_21367": "Driver Name",
  "I1_21278;8068_76053": "28",
  "I1_21329;8068_76055": "03",
  "I1_21289;8068_76053": "9",
  "1_21375": "Finding vehicles...",
  "I1_21316;8068_76053": "1",
  "I1_21311;8068_76053": "28",
  "1_21270": "Fr",
  "I1_21300;8068_76053": "18",
  "I1_21343;8068_76055": "06",
  "I1_21286;8068_76053": "6",
  "I1_21283;8068_76053": "3",
  "I1_21299;8068_76053": "17",
  "I1_21294;8068_76053": "13",
  "I1_21292;8068_76053": "11",
  "I1_21327;8068_76055": "01",
  "I1_21284;8068_76053": "4",
  "I1_21326;8068_76051": "00",
  "I1_21276;8068_76055": "26",
  "1_21365": "Brand Model",
  "I1_21302;8068_76053": "20",
  "I1_21340;8068_76055": "03",
  "I1_21291;8068_76053": "10",
  "I1_21285;8068_76053": "5",
  "I1_21313;8068_76055": "30",
  "I1_21296;8068_76051": "15",
  "I1_21332;8068_76055": "06",
  "I1_21321;8068_76053": "6",
  "1_21262": "Mo",
  "I1_21319;8068_76053": "4",
  "I1_21312;8068_76055": "29",
  "I1_21341;8068_76055": "04",
  "I1_21307;8068_76053": "24",
  "I1_21293;8068_76053": "12",
  "I1_21334;8068_76055": "08",
  "1_21357": "Reservation ",
  "I1_21333;8068_76055": "07",
  "I1_21295;8068_76053": "14",
  "I1_21310;8068_76053": "27",
  "1_21266": "We",
  "I1_21301;8068_76053": "19",
  "I1_21308;8068_76053": "25",
  "I1_21279;8068_76053": "29",
  "I1_21287;8068_76053": "7",
  "I1_21304;8068_76053": "22",
  "1_21272": "Sa",
  "I1_21328;8068_76055": "02",
  "I1_21275;8068_76055": "25",
  "I1_21277;8068_76053": "27",
  "1_21366": "Number Plate",
  "1_21351": "Ok",
  "I1_21338;8068_76055": "01",
  "1_21264": "Tu",
  "1_21268": "Th",
  "I1_21315;8068_76053": "31",
  "I1_21369;30_32140": "Ok",
  "I1_21303;8068_76053": "21",
  "I1_21297;8068_76053": "16",
  "I1_21309;8068_76053": "26",
  "I1_21331;8068_76055": "05",
  "I1_21345;8068_76055": "08",
  "I1_21379;30_32137": "Cancel",
  "I1_21288;8068_76053": "8",
  "I1_21280;8068_76053": "1",
  "I1_21318;8068_76053": "3",
  "I1_21342;8068_76055": "05",
  "I1_21317;8068_76053": "2",
  "I1_21408;30_32137": "Cancel",
  "I1_21398;30_32140": "Ok",
  "1_21395": "Number Plate",
  "1_21389": "Successfully Confirmed",
  "1_21394": "Brand Model",
  "1_21396": "Driver Name",
  "1_21386": "Reservation ",
  "1_21404": "Finding vehicles...",
  "I1_21427;30_32140": "Ok",
  "1_21415": "Reservation ",
  "I1_21437;30_32137": "Cancel",
  "1_21423": "Brand Model",
  "1_21425": "Driver Name",
  "1_21418": "Successfully Confirmed",
  "1_21424": "Number Plate",
  "1_21454": "Driver Name",
  "I1_21456;30_32140": "Ok",
  "1_21452": "Brand Model",
  "1_21462": "Finding vehicles...",
  "1_21453": "Number Plate",
  "I1_21466;30_32137": "Cancel",
  "1_21444": "Reservation ",
  "1_21447": "Successfully Confirmed",
  "1_3547": "Nick Name",
  "1_3554": "Number Plate",
  "I1_3582;21_22137": "Passenger Management",
  "I1_3594;23_39072": "Notification",
  "1_3551": "Number Plate",
  "1_3544": "Nick Name",
  "1_3585": "Chauffeur Connect",
  "1_3527": "Active Vehicles",
  "1_3569": "5",
  "1_3550": "Nick Name",
  "1_3548": "Number Plate",
  "1_3532": "Nick Name",
  "1_3557": "Number Plate",
  "1_3553": "Nick Name",
  "I1_3589;23_39072": "Approval",
  "1_3542": "Number Plate",
  "1_3530": "Number Plate",
  "1_3533": "Number Plate",
  "I1_3580;23_39084": "Vehicle Management",
  "1_3571": "Client Portal",
  "I1_3524;121_59040": "Search...",
  "1_3522": "image 1",
  "1_3529": "Nick Name",
  "1_3536": "Number Plate",
  "I1_3587;23_39072": "Work Management",
  "1_3535": "Nick Name",
  "I1_3573;21_27541": "Top",
  "I1_3575;23_39072": "Company setting",
  "1_3538": "Nick Name",
  "1_3539": "Number Plate",
  "I1_3581;21_22137": "Driver Management",
  "1_3556": "Nick Name",
  "1_3545": "Number Plate",
  "1_3541": "Nick Name",
  "I1_3520;35_49503": "Top",
  "I1_3602;28_31246;28_31236": "Company Settings",
  "I1_3622;18_21709": "Manages company operations and resources for seamless efficiency",
  "1_3618": "Action",
  "I1_3629;18_21709": "Safely transports goods, maintains vehicles, and enhances logistical flow",
  "I1_3634;581_1954;7882_19131;497_44116": "10 / page",
  "I1_3650;250_58566": "Emergency Contact",
  "I1_3634;581_1954;7882_21473;497_44116": "1",
  "I1_3602;28_31248;28_31243": "Department Management",
  "I1_3634;581_1954;7882_22097;433_34538": "4",
  "I1_3652;23_39072": "Vehicle Management",
  "I1_3628;835_98906": "Driver Department",
  "1_3661": "Chauffeur Connect",
  "I1_3634;581_1954;7882_26275;433_34538": "11",
  "I1_3614;835_98906": "Sales Department",
  "I1_3603;388_23101": "Department Management ",
  "I1_3634;581_1954;7882_21971;433_34538": "3",
  "I1_3647;250_58566": "Business Hours",
  "I1_3612;171_66633": "Action",
  "I1_3664;23_39072": "Work Management",
  "I1_3634;581_1954;7882_26229;433_34538": "10",
  "1_3632": "Action",
  "I1_3602;28_31263;28_31261": ">",
  "I1_3674;23_39072": "Notification",
  "I1_3648;250_58566": "Overtime Rules",
  "I1_3669;23_39072": "Approval",
  "I1_3610;171_66633": "Department Name",
  "1_3638": "5",
  "I1_3603;388_23148;30_32140": "Add",
  "I1_3657;21_22137": "Driver Management",
  "1_3625": "Action",
  "I1_3615;18_21709": "Boosts revenue through client engagement and product promotion",
  "I1_3634;581_1954;7882_21853;433_34538": "2",
  "I1_3607;121_59079": "Search...",
  "I1_3658;21_22137": "Passenger Management",
  "I1_3649;250_58566": "Holidays",
  "I1_3642;21_22137": "Top",
  "I1_3634;368_110840": "Show 1 to 10 of 100 results",
  "1_3640": "Client Portal",
  "I1_3646;250_58575": "Department Management",
  "I1_3644;23_39084": "Company setting",
  "I1_3611;171_66633": "Description",
  "I1_3621;835_98906": "Adminnistration Department",
  "I1_3634;581_1954;7882_26160;433_34538": "...",
  "I1_3682;35_49551;28_31261": ">",
  "I1_3705;23_39084": "Company setting",
  "1_3701": "Client Portal",
  "I1_3708;250_58566": "Business Hours",
  "I1_3718;21_22137": "Driver Management",
  "1_3722": "Chauffeur Connect",
  "1_3688": "*",
  "I1_3730;23_39072": "Approval",
  "I1_3719;21_22137": "Passenger Management",
  "I1_3682;35_49550;28_31236": "Department Management",
  "I1_3707;250_58575": "Department Management",
  "I1_3713;23_39072": "Vehicle Management",
  "I1_3735;23_39072": "Notification",
  "I1_3725;23_39072": "Work Management",
  "I1_3692;7700_22673;154_61744": "Description",
  "I1_3695;30_32140": "Save",
  "I1_3682;35_49548;28_31236": "Company Settings",
  "I1_3683;35_49503": "Add Department",
  "1_3689": "Department Name",
  "I1_3703;21_22137": "Top",
  "I1_3710;250_58566": "Holidays",
  "I1_3691;75_53807": "Department Name",
  "I1_3694;30_32137": "Cancel",
  "I1_3709;250_58566": "Overtime Rules",
  "1_3699": "5",
  "I1_3711;250_58566": "Emergency Contact",
  "I1_3692;7700_22671": "Description",
  "I1_3682;35_49549;28_31261": ">",
  "I1_3682;35_49552;28_31243": "Add Department",
  "1_3760": "Client Portal",
  "I1_3754;30_32137": "Cancel",
  "I1_3794;23_39072": "Notification",
  "1_3781": "Chauffeur Connect",
  "1_3758": "5",
  "I1_3764;23_39084": "Company setting",
  "I1_3766;250_58575": "Department Management",
  "I1_3769;250_58566": "Holidays",
  "I1_3743;35_49551;28_31261": ">",
  "I1_3743;35_49550;28_31236": "Department Management",
  "I1_3768;250_58566": "Overtime Rules",
  "I1_3789;23_39072": "Approval",
  "I1_3772;23_39072": "Vehicle Management",
  "I1_3744;35_49503": "View Department",
  "I1_3753;7700_22673;185_68601": "Description",
  "1_3749": "*",
  "I1_3762;21_22137": "Top",
  "I1_3743;35_49549;28_31261": ">",
  "1_3750": "Department Name",
  "I1_3770;250_58566": "Emergency Contact",
  "I1_3767;250_58566": "Business Hours",
  "I1_3777;21_22137": "Driver Management",
  "I1_3753;7700_22671": "Description",
  "I1_3778;21_22137": "Passenger Management",
  "I1_3743;35_49548;28_31236": "Company Settings",
  "I1_3743;35_49552;28_31243": "View Department",
  "I1_3784;23_39072": "Work Management",
  "I1_3752;185_68545": "Department Name",
  "I1_3812;7700_22671": "Description",
  "I1_3828;250_58566": "Business Hours",
  "I1_3823;21_22137": "Top",
  "I1_3830;250_58566": "Holidays",
  "I1_3802;35_49552;28_31243": "Edit Department",
  "I1_3815;30_32140": "Update",
  "I1_3803;35_49503": "Edit Department",
  "I1_3829;250_58566": "Overtime Rules",
  "I1_3845;23_39072": "Work Management",
  "1_3808": "*",
  "I1_3827;250_58575": "Department Management",
  "1_3809": "Department Name",
  "I1_3833;23_39072": "Vehicle Management",
  "I1_3812;7700_22673;250_58177": "Description",
  "I1_3839;21_22137": "Passenger Management",
  "I1_3838;21_22137": "Driver Management",
  "1_3842": "Chauffeur Connect",
  "I1_3855;23_39072": "Notification",
  "I1_3802;35_49550;28_31236": "Department Management",
  "I1_3802;35_49551;28_31261": ">",
  "I1_3850;23_39072": "Approval",
  "I1_3802;35_49549;28_31261": ">",
  "I1_3811;121_58638": "Department Name",
  "1_3821": "Client Portal",
  "I1_3825;23_39084": "Company setting",
  "I1_3814;30_32137": "Cancel",
  "1_3819": "5",
  "I1_3802;35_49548;28_31236": "Company Settings",
  "I1_3831;250_58566": "Emergency Contact",
  "I1_3908;250_58575": "Business Hours",
  "I1_3895;581_1954;7882_21473;497_44116": "1",
  "1_3922": "Chauffeur Connect",
  "I1_3895;581_1954;7882_21853;433_34538": "2",
  "I1_3864;388_23101": "Business Hours",
  "I1_3863;28_31248;28_31243": "Business Hours",
  "I1_3935;23_39072": "Notification",
  "I1_3909;250_58566": "Overtime Rules",
  "I1_3895;368_110840": "Show 1 to 10 of 100 results",
  "I1_3895;581_1954;7882_26160;433_34538": "...",
  "I1_3889;835_98906": "Flexi-time",
  "1_3901": "Client Portal",
  "I1_3895;581_1954;7882_26275;433_34538": "11",
  "I1_3863;28_31263;28_31261": ">",
  "I1_3872;171_66633": "Description",
  "I1_3903;21_22137": "Top",
  "I1_3907;250_58566": "Department Management",
  "I1_3875;835_98906": "Day Shift",
  "I1_3868;121_59079": "Search...",
  "I1_3873;171_66633": "Action",
  "I1_3910;250_58566": "Holidays",
  "I1_3905;23_39084": "Company setting",
  "I1_3883;18_21709": "Operation during nighttime hours",
  "I1_3876;18_21709": "Standard working hours during the day",
  "I1_3871;171_66633": "Business Hour",
  "I1_3913;23_39072": "Vehicle Management",
  "I1_3864;388_23148;30_32140": "Add",
  "1_3893": "Action",
  "I1_3925;23_39072": "Work Management",
  "I1_3919;21_22137": "Passenger Management",
  "I1_3895;581_1954;7882_22097;433_34538": "4",
  "1_3899": "5",
  "I1_3895;581_1954;7882_26229;433_34538": "10",
  "I1_3895;581_1954;7882_19131;497_44116": "10 / page",
  "I1_3863;28_31246;28_31236": "Company Settings",
  "I1_3911;250_58566": "Emergency Contact",
  "I1_3895;581_1954;7882_21971;433_34538": "3",
  "1_3886": "Action",
  "I1_3930;23_39072": "Approval",
  "I1_3882;835_98906": "Night Shift",
  "I1_3918;21_22137": "Driver Management",
  "1_3879": "Action",
  "I1_3890;18_21709": "Offer flexible working hours",
  "I1_4004;23_39084": "Company setting",
  "I1_3986;33_33258;33_33254": "Closed",
  "I1_4002;21_22137": "Top",
  "I1_3994;30_32140": "Save",
  "I1_3943;35_49551;28_31261": ">",
  "I1_3965;970_61896": "Tuesday",
  "I1_3993;30_32137": "Cancel",
  "1_3949": "*",
  "I1_3943;35_49552;28_31243": "Add Business Hour",
  "I1_3991;33_33258;33_33254": "Closed",
  "1_3950": "Business Hour",
  "I1_3971;33_33258;33_33254": "Closed",
  "I1_3966;33_33258;33_33254": "Closed",
  "I1_3975;970_61896": "Thursday",
  "I1_4012;23_39072": "Vehicle Management",
  "1_3956": "Working Time",
  "I1_3951;75_53807": "Business Hour",
  "1_4021": "Chauffeur Connect",
  "I1_4034;23_39072": "Notification",
  "I1_3976;33_33258;33_33254": "Closed",
  "I1_4006;250_58566": "Department Management",
  "I1_3943;35_49549;28_31261": ">",
  "I1_4024;23_39072": "Work Management",
  "I1_3981;33_33258;33_33254": "Closed",
  "I1_3985;970_61896": "Saturday",
  "I1_3961;33_33258;33_33254": "Closed",
  "I1_3952;7700_22671": "Description",
  "I1_3944;35_49503": "Add Business Hour",
  "I1_3952;7700_22673;154_61744": "Description",
  "I1_3943;35_49550;28_31236": "Business Hours",
  "I1_4010;250_58566": "Emergency Contact",
  "1_3998": "5",
  "1_3955": "*",
  "I1_4008;250_58566": "Overtime Rules",
  "1_4000": "Client Portal",
  "I1_3990;970_61896": "Sunday",
  "I1_4018;21_22137": "Passenger Management",
  "I1_3960;970_61896": "Monday",
  "I1_4009;250_58566": "Holidays",
  "I1_3943;35_49548;28_31236": "Company Settings",
  "I1_4029;23_39072": "Approval",
  "I1_3970;970_61896": "Wednesday",
  "I1_3980;970_61896": "Friday",
  "I1_4007;250_58575": "Business Hours",
  "I1_4017;21_22137": "Driver Management",
  "I1_4144;21_22137": "Top",
  "I1_4103;970_61896": "Thursday",
  "I1_4042;35_49552;28_31243": "Add Business Hour",
  "I1_4108;30_32021": "Add",
  "I1_4078;75_53865;318_97298": "12:00",
  "1_4048": "*",
  "I1_4159;21_22137": "Driver Management",
  "I1_4043;35_49503": "Add Business Hour",
  "I1_4166;23_39072": "Work Management",
  "I1_4118;75_53865;318_97298": "8:30",
  "1_4142": "Client Portal",
  "I1_4082;970_61896": "To",
  "I1_4051;7700_22673;154_61744": "Description",
  "I1_4087;30_32021": "Add",
  "I1_4150;250_58566": "Overtime Rules",
  "I1_4042;35_49548;28_31236": "Company Settings",
  "I1_4084;75_53865;318_97298": "17:30",
  "1_4055": "Working Time",
  "I1_4148;250_58566": "Department Management",
  "I1_4123;75_53865;318_97298": "17:30",
  "I1_4050;75_53807": "Business Hour",
  "I1_4120;30_32021": "Add",
  "I1_4146;23_39084": "Company setting",
  "I1_4076;970_61896": "To",
  "I1_4111;75_53865;318_97298": "17:30",
  "1_4140": "5",
  "I1_4128;33_33258;33_33254": "Closed",
  "I1_4081;75_53865;318_97298": "13:00",
  "I1_4051;7700_22671": "Description",
  "I1_4096;30_32021": "Add",
  "I1_4176;23_39072": "Notification",
  "I1_4091;970_61896": "Wednesday",
  "I1_4151;250_58566": "Holidays",
  "I1_4064;30_32021": "Add",
  "1_4163": "Chauffeur Connect",
  "I1_4171;23_39072": "Approval",
  "1_4054": "*",
  "I1_4160;21_22137": "Passenger Management",
  "I1_4067;75_53865;318_97289": "End Time",
  "I1_4132;970_61896": "Sunday",
  "I1_4149;250_58575": "Business Hours",
  "I1_4109;970_61896": "To",
  "I1_4135;30_32137": "Cancel",
  "1_4049": "Business Hour",
  "I1_4154;23_39072": "Vehicle Management",
  "I1_4136;30_32140": "Save",
  "I1_4075;75_53865;318_97298": "8:30",
  "I1_4042;35_49549;28_31261": ">",
  "I1_4097;970_61896": "To",
  "I1_4059;970_61896": "Monday",
  "I1_4065;970_61896": "To",
  "I1_4071;970_61896": "Tuesday",
  "I1_4127;970_61896": "Saturday",
  "I1_4133;33_33258;33_33254": "Closed",
  "I1_4115;970_61896": "Friday",
  "I1_4152;250_58566": "Emergency Contact",
  "I1_4099;75_53865;318_97298": "17:30",
  "I1_4094;75_53865;318_97298": "8:30",
  "I1_4042;35_49551;28_31261": ">",
  "I1_4062;75_53865;318_97289": "Start Time",
  "I1_4106;75_53865;318_97298": "8:30",
  "I1_4121;970_61896": "To",
  "I1_4042;35_49550;28_31236": "Business Hours",
  "1_4284": "Client Portal",
  "I1_4269;970_61896": "Saturday",
  "I1_4184;35_49552;28_31243": "Edit Business Hour",
  "I1_4220;75_53865;318_97298": "12:00",
  "I1_4204;75_53865;318_97289": "Start Time",
  "I1_4260;75_53865;318_97298": "8:30",
  "1_4305": "Chauffeur Connect",
  "I1_4229;30_32021": "Add",
  "I1_4293;250_58566": "Holidays",
  "I1_4275;33_33258;33_33254": "Closed",
  "I1_4193;7700_22673;154_61744": "Description",
  "I1_4250;30_32021": "Add",
  "I1_4274;970_61896": "Sunday",
  "1_4197": "Working Time",
  "I1_4263;970_61896": "To",
  "I1_4278;30_32140": "Update",
  "I1_4185;35_49503": "Edit Business Hour",
  "I1_4236;75_53865;318_97298": "8:30",
  "I1_4239;970_61896": "To",
  "I1_4251;970_61896": "To",
  "I1_4233;970_61896": "Wednesday",
  "I1_4257;970_61896": "Friday",
  "I1_4292;250_58566": "Overtime Rules",
  "I1_4201;970_61896": "Monday",
  "I1_4193;7700_22671": "Description",
  "1_4191": "Business Hour",
  "I1_4291;250_58575": "Business Hours",
  "I1_4270;33_33258;33_33254": "Closed",
  "I1_4184;35_49551;28_31261": ">",
  "I1_4301;21_22137": "Driver Management",
  "I1_4296;23_39072": "Vehicle Management",
  "I1_4209;75_53865;318_97289": "End Time",
  "I1_4192;75_53807": "Business Hour",
  "I1_4265;75_53865;318_97298": "17:30",
  "I1_4223;75_53865;318_97298": "13:00",
  "I1_4238;30_32021": "Add",
  "I1_4294;250_58566": "Emergency Contact",
  "I1_4318;23_39072": "Notification",
  "I1_4184;35_49549;28_31261": ">",
  "I1_4288;23_39084": "Company setting",
  "I1_4290;250_58566": "Department Management",
  "1_4196": "*",
  "I1_4207;970_61896": "To",
  "I1_4308;23_39072": "Work Management",
  "I1_4224;970_61896": "To",
  "I1_4313;23_39072": "Approval",
  "I1_4213;970_61896": "Tuesday",
  "I1_4262;30_32021": "Add",
  "I1_4277;30_32137": "Cancel",
  "I1_4184;35_49550;28_31236": "Business Hours",
  "1_4282": "5",
  "I1_4241;75_53865;318_97298": "17:30",
  "I1_4206;30_32021": "Add",
  "I1_4245;970_61896": "Thursday",
  "I1_4286;21_22137": "Top",
  "I1_4302;21_22137": "Passenger Management",
  "I1_4184;35_49548;28_31236": "Company Settings",
  "I1_4253;75_53865;318_97298": "17:30",
  "1_4190": "*",
  "I1_4217;75_53865;318_97298": "8:30",
  "I1_4226;75_53865;318_97298": "17:30",
  "I1_4218;970_61896": "To",
  "I1_4248;75_53865;318_97298": "8:30",
  "I1_4335;7700_22671": "Description",
  "I1_4373;30_31991": "Add",
  "I1_4326;35_49550;28_31236": "Business Hours",
  "1_4426": "5",
  "I1_4408;30_31991": "Add",
  "I1_4409;970_61896": "To",
  "I1_4343;970_61896": "Monday",
  "I1_4411;75_53865;318_97304": "17:30",
  "I1_4422;30_32137": "Cancel",
  "I1_4402;970_61896": "Friday",
  "I1_4356;970_61896": "Tuesday",
  "I1_4415;970_61896": "Saturday",
  "I1_4398;75_53865;318_97304": "17:30",
  "I1_4385;75_53865;318_97304": "17:30",
  "I1_4363;75_53865;318_97304": "12:00",
  "I1_4457;23_39072": "Approval",
  "I1_4432;23_39084": "Company setting",
  "I1_4346;75_53865;318_97304": "Start Time",
  "I1_4437;250_58566": "Holidays",
  "I1_4361;970_61896": "To",
  "I1_4349;30_31991": "Add",
  "I1_4392;75_53865;318_97304": "8:30",
  "I1_4380;75_53865;318_97304": "8:30",
  "I1_4396;970_61896": "To",
  "I1_4360;75_53865;318_97304": "8:30",
  "1_4333": "Business Hour",
  "I1_4326;35_49552;28_31243": "View Business Hour",
  "I1_4326;35_49548;28_31236": "Company Settings",
  "I1_4438;250_58566": "Emergency Contact",
  "I1_4434;250_58566": "Department Management",
  "I1_4389;970_61896": "Thursday",
  "1_4449": "Chauffeur Connect",
  "I1_4445;21_22137": "Driver Management",
  "I1_4452;23_39072": "Work Management",
  "I1_4352;75_53865;318_97304": "End Time",
  "I1_4366;75_53865;318_97304": "13:00",
  "I1_4462;23_39072": "Notification",
  "I1_4395;30_31991": "Add",
  "1_4332": "*",
  "I1_4327;35_49503": "View Business Hour",
  "I1_4334;185_68545": "Business Hour",
  "I1_4350;970_61896": "To",
  "I1_4421;33_33258;33_33254": "Closed",
  "I1_4367;970_61896": "To",
  "1_4428": "Client Portal",
  "I1_4377;970_61896": "Wednesday",
  "I1_4440;23_39072": "Vehicle Management",
  "I1_4430;21_22137": "Top",
  "I1_4326;35_49549;28_31261": ">",
  "I1_4382;30_31991": "Add",
  "I1_4416;33_33258;33_33254": "Closed",
  "I1_4435;250_58575": "Business Hours",
  "I1_4405;75_53865;318_97304": "8:30",
  "I1_4436;250_58566": "Overtime Rules",
  "I1_4326;35_49551;28_31261": ">",
  "I1_4446;21_22137": "Passenger Management",
  "I1_4335;7700_22673;185_68601": "Description",
  "I1_4420;970_61896": "Sunday",
  "1_4339": "Working Time",
  "I1_4383;970_61896": "To",
  "1_4338": "*",
  "I1_4369;75_53865;318_97304": "17:30",
  "I1_4482;171_66633": "Vehicle ID",
  "I1_4518;835_98906": "10",
  "I1_4498;33_33258;33_33254": "In-Transit",
  "I1_4522;18_21709": "6512343",
  "I1_4494;18_21709": "Mazda 3",
  "I1_4470;28_31263;28_31261": ">",
  "I1_4509;18_21709": "6512342",
  "I1_4550;21_22137": "Top",
  "I1_4505;835_98906": "99",
  "I1_4478;121_59079": "Search...",
  "I1_4566;250_58566": "Vehicle Depot",
  "1_4514": "Action",
  "1_4501": "Action",
  "I1_4568;21_22137": "Passenger Management",
  "I1_4483;171_66633": "Brand",
  "I1_4531;835_98906": "18",
  "I1_4542;581_1954;7882_21853;433_34538": "2",
  "I1_4542;581_1954;7882_26229;433_34538": "10",
  "I1_4475;30_32021": "Export",
  "I1_4524;33_33258;33_33254": "Returned",
  "I1_4562;23_39084": "Vehicle Management",
  "I1_4537;33_33258;33_33254": "In-Transit",
  "I1_4542;581_1954;7882_22097;433_34538": "4",
  "I1_4542;581_1954;7882_21473;497_44116": "1",
  "I1_4510;18_21709": "Organization Name 2",
  "I1_4488;171_66633": "Status",
  "I1_4534;18_21709": "SUV",
  "I1_4567;21_22137": "Driver Management",
  "I1_4506;18_21709": "Mazda",
  "I1_4492;835_98906": "91",
  "I1_4564;250_58575": "Manage Vehicle",
  "I1_4523;18_21709": "Organization Name 3",
  "1_4527": "Action",
  "I1_4477;433_34538": "Vehicle Status",
  "I1_4485;171_66633": "Type",
  "I1_4542;581_1954;7882_19131;497_44116": "10 / page",
  "I1_4542;581_1954;7882_26275;433_34538": "11",
  "I1_4579;23_39072": "Approval",
  "1_4540": "Action",
  "I1_4535;18_21709": "6512348",
  "1_4571": "Chauffeur Connect",
  "I1_4487;171_66633": "Added by",
  "I1_4511;33_33258;33_33254": "Received",
  "I1_4470;28_31248;28_31243": "Manage Vehicle",
  "I1_4489;171_66633": "Action",
  "I1_4493;18_21709": "Mazda",
  "I1_4552;23_39072": "Company setting",
  "I1_4484;171_66633": "Model",
  "I1_4521;18_21709": "SUV",
  "I1_4471;35_49503": "Manage Vehicle",
  "I1_4584;23_39072": "Notification",
  "I1_4508;18_21709": "Sedan",
  "I1_4520;18_21709": "CX-5",
  "I1_4496;18_21709": "6512345",
  "I1_4497;18_21709": "Organization Name 1",
  "I1_4533;18_21709": "CX-5",
  "I1_4507;18_21709": "Mazda 5",
  "I1_4495;18_21709": "Sedan",
  "I1_4542;581_1954;7882_21971;433_34538": "3",
  "I1_4476;433_34538": "Type",
  "I1_4519;18_21709": "Mazda",
  "I1_4574;23_39072": "Work Management",
  "I1_4532;18_21709": "Mazda",
  "I1_4536;18_21709": "Organization Name 4",
  "1_4546": "5",
  "I1_4542;368_110840": "Show 1 to 10 of 100 results",
  "I1_4486;171_66633": "VIN",
  "I1_4542;581_1954;7882_26160;433_34538": "...",
  "I1_4470;28_31246;28_31236": "Vehicle Management",
  "1_4548": "Client Portal",
  "I1_4565;250_58566": "Vehicle Group",
  "1_4606": "Vehicle Brand",
  "1_4612": "Vehicle Type",
  "I1_4608;75_53865;250_37580": "Select Vehicle Brand",
  "1_4669": "Kg",
  "I1_4707;250_58575": "Manage Vehicle",
  "I1_4628;154_64377;75_53865;250_37580": "Select Vehicle Model Year",
  "1_4632": "*",
  "I1_4614;75_53865;250_37580": "Select Vehicle Type",
  "I1_4628;154_64376": "Vehicle Model Year",
  "I1_4592;35_49549;28_31261": ">",
  "1_4625": "Vehicle Model",
  "1_4672": "Advance Setting",
  "I1_4593;35_49503": "View Vehicle",
  "1_4681": "Insurance Service Information",
  "I1_4592;35_49551;28_31261": ">",
  "1_4640": "Length",
  "1_4624": "*",
  "1_4691": "Client Portal",
  "1_4605": "*",
  "I1_4636;7636_13405;185_68545": "Number Plate",
  "I1_4705;23_39084": "Vehicle Management",
  "I1_4685;30_32137": "Cancel",
  "I1_4659;185_68545": "Number Plate",
  "1_4618": "Vehicle Color",
  "I1_4636;7636_13403": "Number Plate",
  "I1_4627;75_53865;250_37580": "Select Vehicle Model",
  "1_4633": "VIN",
  "I1_4592;35_49548;28_31236": "Vehicle Management",
  "1_4664": "Max Weight",
  "I1_4592;35_49550;28_31236": "Manage Vehicle",
  "I1_4651;185_68545": "Number Plate",
  "I1_4710;21_22137": "Driver Management",
  "I1_4722;23_39072": "Approval",
  "I1_4717;23_39072": "Work Management",
  "1_4689": "5",
  "1_4656": "Height",
  "1_4599": "General Setting",
  "I1_4620;75_53865;250_37580": "Select Vehicle Color",
  "I1_4667;185_68545": "Number Plate",
  "I1_4643;185_68545": "Number Plate",
  "I1_4708;250_58566": "Vehicle Group",
  "I1_4595;758_37469;758_37460": "Vehicle Information",
  "1_4714": "Chauffeur Connect",
  "1_4645": "mm",
  "1_4648": "Width",
  "1_4661": "mm",
  "I1_4695;23_39072": "Company setting",
  "1_4617": "*",
  "I1_4709;250_58566": "Vehicle Depot",
  "I1_4727;23_39072": "Notification",
  "1_4678": "Vehicle Inspectation Information",
  "I1_4595;758_37470;758_37454": "Vehicle Usage",
  "I1_4693;21_22137": "Top",
  "1_4611": "*",
  "I1_4711;21_22137": "Passenger Management",
  "1_4684": "Towing Service Information",
  "1_4629": "Vehicle Model Year",
  "1_4675": "Vehicle Assignment",
  "1_4653": "mm",
  "I1_4635;185_68545": "VIN",
  "I1_4778;7636_13405;185_68545": "Number Plate",
  "I1_4928;21_22137": "Top",
  "I1_4778;7636_13403": "Number Plate",
  "I1_4905;7696_54949": "Enter Contract Expiry Date",
  "I1_4834;154_64377;75_53865;250_37580": "Select Vehicle Group",
  "1_4795": "mm",
  "1_4761": "Vehicle Color",
  "I1_4957;23_39072": "Approval",
  "I1_4735;35_49550;28_31236": "Manage Vehicle",
  "1_4815": "Advance Setting",
  "1_4886": "Inspectation Date",
  "1_4768": "Vehicle Model",
  "I1_4809;185_68545": "Number Plate",
  "I1_4852;18_21709": "Content",
  "I1_4757;75_53865;250_37580": "Select Vehicle Type",
  "I1_4915;7696_54949": "Enter Contract Start Date",
  "I1_4853;18_21709": "Content",
  "I1_4751;75_53865;250_37580": "Select Vehicle Brand",
  "1_4914": "Towing Service Contract Start Date",
  "I1_4919;7696_54949": "Enter Contract Expiry Date",
  "1_4826": "km",
  "1_4863": "Assigned Passenger",
  "I1_4940;23_39084": "Vehicle Management",
  "I1_4835;154_64377;75_53865;250_37580": "Select Vehicle Depot",
  "I1_4801;185_68545": "Number Plate",
  "I1_4846;171_66633": "Driver Name",
  "I1_4866;171_66633": "Passenger ID",
  "1_4790": "Width",
  "I1_4738;758_37470;758_37454": "Vehicle Usage",
  "1_4859": "Action",
  "1_4748": "*",
  "1_4755": "Vehicle Type",
  "I1_4777;185_68545": "VIN",
  "I1_4770;75_53865;250_37580": "Select Vehicle Model",
  "I1_4835;154_64376": "Vehicle Depot",
  "I1_4868;171_66633": "Email Address",
  "I1_4901;7696_54949": "Enter Contract Start Date",
  "1_4900": "Insurance Service Contract Start Date",
  "1_4842": "Assigned Driver",
  "1_4829": "Vehicle Image",
  "289_32098": "uploadFile",
  "I1_4854;18_21709": "Content",
  "1_4909": "Towing Service Information",
  "I1_4847;171_66633": "Email Address",
  "I1_4735;35_49551;28_31261": ">",
  "I1_4738;758_37469;758_37460": "Vehicle Information",
  "I1_4735;35_49548;28_31236": "Vehicle Management",
  "1_4821": "Odometer",
  "I1_4771;154_64376": "Vehicle Model Year",
  "1_4918": "Towing Service Contract Expiry Date",
  "1_4767": "*",
  "I1_4872;18_21709": "Content",
  "I1_4848;171_66633": "Department",
  "1_4803": "mm",
  "1_4760": "*",
  "1_4881": "Vehicle Inspectation Information",
  "I1_4874;18_21709": "Content",
  "I1_4942;250_58575": "Manage Vehicle",
  "1_4787": "mm",
  "I1_4771;154_64377;75_53865;250_37580": "Select Vehicle Model Year",
  "I1_4793;185_68545": "Number Plate",
  "I1_4861;30_32081": "Add Driver",
  "1_4811": "Kg",
  "I1_4785;185_68545": "Number Plate",
  "1_4949": "Chauffeur Connect",
  "I1_4943;250_58566": "Vehicle Group",
  "I1_4870;171_66633": "Action",
  "1_4754": "*",
  "I1_4869;171_66633": "Department",
  "1_4890": "Inspectation Due Date",
  "1_4775": "VIN",
  "1_4895": "Insurance Service Information",
  "I1_4873;18_21709": "Content",
  "I1_4962;23_39072": "Notification",
  "1_4806": "Max Weight",
  "I1_4855;18_21709": "Content",
  "1_4749": "Vehicle Brand",
  "1_4774": "*",
  "I1_4877;30_32081": "Add Passenger",
  "I1_4944;250_58566": "Vehicle Depot",
  "1_4782": "Length",
  "I1_4735;35_49549;28_31261": ">",
  "1_4798": "Height",
  "1_4926": "Client Portal",
  "I1_4867;171_66633": "Passenger Name",
  "I1_4763;75_53865;250_37580": "Select Vehicle Color",
  "I1_4824;185_68545": "Odometer",
  "I1_4833;7636_13403": "Vehicle Nickname",
  "I1_4887;7696_54949": "Enter Inspectation Date",
  "I1_4875;18_21709": "Content",
  "I1_4849;171_66633": "Driver Role",
  "I1_4945;21_22137": "Driver Management",
  "I1_4850;171_66633": "Action",
  "I1_4930;23_39072": "Company setting",
  "1_4924": "5",
  "I1_4920;30_32137": "Cancel",
  "I1_4946;21_22137": "Passenger Management",
  "I1_4891;7696_54949": "Enter Inspectation Due Date",
  "I1_4856;18_21709": "N/A",
  "1_4839": "Vehicle Assignment",
  "I1_4834;154_64376": "Vehicle Group",
  "I1_4833;7636_13405;185_68545": "Vehicle Nickname",
  "I1_4845;171_66633": "Driver ID",
  "1_4742": "General Setting",
  "I1_4876;528_68898;30_31856": "Delete",
  "I1_4952;23_39072": "Work Management",
  "1_4904": "Insurance Service Contract Expiry Date",
  "1_5001": "Action",
  "I1_5021;23_39072": "Vehicle Management",
  "I1_4984;18_21709":
    "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
  "I1_4980;171_66633": "Description",
  "I1_4971;28_31246;28_31236": "Company Settings",
  "I1_5003;581_1954;7882_21971;433_34538": "3",
  "I1_4998;18_21709":
    "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
  "1_4994": "Action",
  "1_4987": "Action",
  "I1_5011;21_22137": "Top",
  "I1_5015;250_58566": "Department Management",
  "I1_4979;171_66633": "Overtime Rules Name",
  "I1_4976;121_59079": "Search...",
  "1_5030": "Chauffeur Connect",
  "I1_4971;28_31263;28_31261": ">",
  "I1_5013;23_39084": "Company setting",
  "I1_4971;28_31248;28_31243": "Overtime Rules",
  "I1_5003;581_1954;7882_21473;497_44116": "1",
  "1_5009": "Client Portal",
  "I1_4983;835_98906": "Weekly Overtime Rule 1",
  "I1_4991;18_21709":
    "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
  "I1_5018;250_58566": "Holidays",
  "I1_5038;23_39072": "Approval",
  "I1_5033;23_39072": "Work Management",
  "I1_5026;21_22137": "Driver Management",
  "I1_5027;21_22137": "Passenger Management",
  "I1_5017;250_58575": "Overtime Rules",
  "I1_4990;835_98906": "Weekly Overtime Rule 2",
  "I1_5016;250_58566": "Business Hours",
  "I1_5003;581_1954;7882_19131;497_44116": "10 / page",
  "I1_4972;388_23148;30_32140": "Add",
  "I1_5003;581_1954;7882_26160;433_34538": "...",
  "I1_5019;250_58566": "Emergency Contact",
  "I1_4972;388_23101": "Overtime Rules",
  "I1_5003;581_1954;7882_21853;433_34538": "2",
  "I1_5043;23_39072": "Notification",
  "I1_5003;581_1954;7882_26229;433_34538": "10",
  "I1_5003;581_1954;7882_26275;433_34538": "11",
  "I1_5003;368_110840": "Show 1 to 10 of 100 results",
  "I1_4997;835_98906": "Weekly Overtime Rule 3",
  "I1_5003;581_1954;7882_22097;433_34538": "4",
  "I1_4981;171_66633": "Action",
  "1_5007": "5",
  "I1_5087;581_1954;7882_26229;433_34538": "10",
  "I1_5052;388_23148;30_32140": "Add",
  "I1_5103;250_58566": "Emergency Contact",
  "I1_5087;581_1954;7882_22097;433_34538": "4",
  "I1_5062;171_66633": "Action",
  "I1_5060;171_66633": "Description",
  "I1_5095;21_22137": "Top",
  "I1_5101;250_58566": "Overtime Rules",
  "I1_5087;581_1954;7882_26275;433_34538": "11",
  "I1_5087;581_1954;7882_21971;433_34538": "3",
  "I1_5087;581_1954;7882_26160;433_34538": "...",
  "I1_5081;18_21709": "Commemorates the independence of nation",
  "I1_5073;18_21709": "Celebration honoring the contributions of workers",
  "166_30353": "Repeat",
  "I1_5111;21_22137": "Passenger Management",
  "I1_5080;835_98906": "Independence Day",
  "I1_5064;835_98906": "New Year‘s Day",
  "I1_5099;250_58566": "Department Management",
  "I1_5056;121_59079": "Search...",
  "I1_5097;23_39084": "Company setting",
  "I1_5122;23_39072": "Approval",
  "I1_5105;23_39072": "Vehicle Management",
  "I1_5117;23_39072": "Work Management",
  "1_5093": "Client Portal",
  "1_5085": "Action",
  "I1_5087;368_110840": "Show 1 to 10 of 100 results",
  "I1_5072;835_98906": "International Worker’s Day",
  "I1_5065;18_21709": "Celebrated globally on January 1st",
  "I1_5074;18_21709": "Never",
  "I1_5087;581_1954;7882_21853;433_34538": "2",
  "1_5077": "Action",
  "I1_5061;171_66633": "Repeat",
  "1_5091": "5",
  "I1_5102;250_58575": "Holidays",
  "I1_5052;388_23101": "Holidays",
  "I1_5110;21_22137": "Driver Management",
  "I1_5066;18_21709": "Every Year",
  "I1_5059;171_66633": "Holiday Title",
  "I1_5127;23_39072": "Notification",
  "I1_5051;28_31246;28_31236": "Company Settings",
  "1_5114": "Chauffeur Connect",
  "1_5069": "Action",
  "I1_5051;28_31263;28_31261": ">",
  "I1_5051;28_31248;28_31243": "Holidays",
  "I1_5087;581_1954;7882_21473;497_44116": "1",
  "I1_5082;18_21709": "Every Year",
  "I1_5100;250_58566": "Business Hours",
  "I1_5087;581_1954;7882_19131;497_44116": "10 / page",
  "I1_5145;75_53807": "Holiday Title",
  "I1_5157;154_64376": "Repeat",
  "I1_5136;35_49550;28_31236": "Holidays",
  "1_5164": "5",
  "I1_5159;30_32137": "Cancel",
  "I1_5184;21_22137": "Passenger Management",
  "1_5149": "*",
  "1_5154": "*",
  "I1_5200;23_39072": "Notification",
  "I1_5136;35_49549;28_31261": ">",
  "I1_5173;250_58566": "Business Hours",
  "1_5187": "Chauffeur Connect",
  "I1_5146;7700_22671": "Description",
  "1_5166": "Client Portal",
  "I1_5136;35_49551;28_31261": ">",
  "I1_5156;7696_54752": "Select Date",
  "I1_5195;23_39072": "Approval",
  "I1_5178;23_39072": "Vehicle Management",
  "I1_5190;23_39072": "Work Management",
  "I1_5146;7700_22673;154_61744": "Description",
  "I1_5174;250_58566": "Overtime Rules",
  "I1_5160;30_32140": "Save",
  "I1_5137;35_49503": "Add Holiday",
  "1_5142": "*",
  "I1_5172;250_58566": "Department Management",
  "I1_5176;250_58566": "Emergency Contact",
  "I1_5151;7696_54752": "Select Date",
  "1_5150": "Start Date",
  "I1_5136;35_49548;28_31236": "Company Settings",
  "I1_5157;154_64377;75_53865;250_37564": "Never",
  "I1_5136;35_49552;28_31243": "Add Holiday",
  "I1_5170;23_39084": "Company setting",
  "I1_5168;21_22137": "Top",
  "I1_5175;250_58575": "Holidays",
  "I1_5183;21_22137": "Driver Management",
  "1_5143": "Holiday Title",
  "1_5155": "End Date",
  "1_5216": "Holiday Title",
  "I1_5251;23_39072": "Vehicle Management",
  "I1_5257;21_22137": "Passenger Management",
  "I1_5248;250_58575": "Holidays",
  "I1_5247;250_58566": "Overtime Rules",
  "I1_5263;23_39072": "Work Management",
  "1_5222": "*",
  "I1_5273;23_39072": "Notification",
  "I1_5268;23_39072": "Approval",
  "I1_5210;35_49503": "View Holiday",
  "I1_5232;30_32137": "Cancel",
  "I1_5230;154_64376": "Repeat",
  "I1_5245;250_58566": "Department Management",
  "I1_5209;35_49549;28_31261": ">",
  "I1_5218;185_68545": "Holiday Title",
  "1_5239": "Client Portal",
  "I1_5256;21_22137": "Driver Management",
  "I1_5209;35_49548;28_31236": "Company Settings",
  "I1_5224;7696_54949": "Select Date",
  "I1_5209;35_49552;28_31243": "View Holiday",
  "1_5260": "Chauffeur Connect",
  "I1_5229;7696_54949": "Select Date",
  "1_5223": "Start Date",
  "I1_5230;154_64377;75_53865;250_37580": "Never",
  "I1_5249;250_58566": "Emergency Contact",
  "1_5237": "5",
  "I1_5209;35_49550;28_31236": "Holidays",
  "1_5215": "*",
  "1_5227": "*",
  "1_5228": "End Date",
  "I1_5209;35_49551;28_31261": ">",
  "I1_5219;7700_22673;185_68601": "Description",
  "I1_5241;21_22137": "Top",
  "I1_5243;23_39084": "Company setting",
  "I1_5246;250_58566": "Business Hours",
  "I1_5219;7700_22671": "Description",
  "I1_5233;30_32140": "Update",
  "I1_5297;7696_54945": "Select Date",
  "I1_5327;21_22137": "Driver Management",
  "I1_5292;7700_22673;250_58177": "Description",
  "1_5310": "Client Portal",
  "I1_5302;7696_54945": "Select Date",
  "I1_5314;23_39084": "Company setting",
  "I1_5282;35_49551;28_31261": ">",
  "I1_5322;23_39072": "Vehicle Management",
  "I1_5316;250_58566": "Department Management",
  "I1_5291;121_58638": "Name",
  "I1_5282;35_49550;28_31236": "Holidays",
  "I1_5312;21_22137": "Top",
  "I1_5283;35_49503": "Edit Holiday",
  "1_5296": "Start Date",
  "1_5300": "*",
  "I1_5282;35_49552;28_31243": "Edit Holiday",
  "I1_5303;154_64376": "Repeat",
  "1_5331": "Chauffeur Connect",
  "I1_5320;250_58566": "Emergency Contact",
  "1_5295": "*",
  "I1_5282;35_49548;28_31236": "Company Settings",
  "1_5288": "*",
  "I1_5328;21_22137": "Passenger Management",
  "I1_5282;35_49549;28_31261": ">",
  "1_5308": "5",
  "I1_5304;30_32137": "Cancel",
  "I1_5334;23_39072": "Work Management",
  "1_5301": "End Date",
  "I1_5317;250_58566": "Business Hours",
  "I1_5303;154_64377;75_53865;250_37573": "Never",
  "I1_5318;250_58566": "Overtime Rules",
  "I1_5339;23_39072": "Approval",
  "I1_5344;23_39072": "Notification",
  "1_5289": "Holiday Title",
  "I1_5319;250_58575": "Holidays",
  "I1_5292;7700_22671": "Description",
  "I1_5395;250_58566": "Business Hours",
  "1_5372": "End Date",
  "I1_5353;35_49552;28_31243": "Add Holiday",
  "I1_5363;7700_22673;154_61744": "Description",
  "1_5360": "Holiday Title",
  "I1_5390;21_22137": "Top",
  "I1_5398;250_58566": "Emergency Contact",
  "I1_5354;35_49503": "Add Holiday",
  "I1_5374;154_64376": "Repeat",
  "I1_5353;35_49550;28_31236": "Holidays",
  "1_5366": "*",
  "I1_5362;75_53807": "Holiday Title",
  "1_5371": "*",
  "I1_5353;35_49551;28_31261": ">",
  "I1_5406;21_22137": "Passenger Management",
  "I1_5400;23_39072": "Vehicle Management",
  "1_5386": "5",
  "I1_5382;30_32140": "Save",
  "I1_5373;7696_54752": "Select Date",
  "I1_5379;7696_54752": "Never",
  "I1_5417;23_39072": "Approval",
  "I1_5353;35_49548;28_31236": "Company Settings",
  "I1_5368;7696_54752": "Select Date",
  "1_5388": "Client Portal",
  "I1_5363;7700_22671": "Description",
  "I1_5422;23_39072": "Notification",
  "I1_5374;154_64377;75_53865;250_37564": "Every Year",
  "I1_5396;250_58566": "Overtime Rules",
  "1_5367": "Start Date",
  "I1_5392;23_39084": "Company setting",
  "1_5377": "Repeat",
  "I1_5397;250_58575": "Holidays",
  "I1_5412;23_39072": "Work Management",
  "1_5409": "Chauffeur Connect",
  "1_5359": "*",
  "I1_5353;35_49549;28_31261": ">",
  "I1_5405;21_22137": "Driver Management",
  "I1_5381;30_32137": "Cancel",
  "I1_5394;250_58566": "Department Management",
  "1_5448": "*",
  "I1_5555;23_39072": "Work Management",
  "I1_5430;35_49549;28_31261": ">",
  "I1_5487;185_68545": "Number Plate",
  "1_5462": "Vehicle Model",
  "1_5529": "Client Portal",
  "1_5500": "Max Weight",
  "1_5461": "*",
  "1_5442": "*",
  "I1_5495;185_68545": "Number Plate",
  "I1_5546;250_58566": "Vehicle Group",
  "1_5505": "Kg",
  "1_5484": "Width",
  "1_5517": "Insurance Service Information",
  "I1_5547;250_58566": "Vehicle Depot",
  "I1_5560;23_39072": "Approval",
  "I1_5431;35_49503": "Edit Vehicle",
  "1_5476": "Length",
  "I1_5533;23_39072": "Company setting",
  "1_5511": "Vehicle Assignment",
  "I1_5445;75_53865;250_37580": "Select Vehicle Brand",
  "I1_5472;7636_13403": "Number Plate",
  "1_5468": "*",
  "I1_5471;185_68545": "VIN",
  "1_5497": "mm",
  "I1_5549;21_22137": "Passenger Management",
  "1_5454": "*",
  "I1_5472;7636_13405;185_68545": "Number Plate",
  "1_5527": "5",
  "I1_5430;35_49550;28_31236": "Manage Vehicle",
  "I1_5543;23_39084": "Vehicle Management",
  "I1_5479;185_68545": "Number Plate",
  "1_5508": "Advance Setting",
  "1_5469": "VIN",
  "I1_5565;23_39072": "Notification",
  "I1_5451;75_53865;250_37580": "Select Vehicle Type",
  "I1_5548;21_22137": "Driver Management",
  "1_5514": "Vehicle Inspectation Information",
  "I1_5465;154_64376": "Vehicle Model Year",
  "I1_5465;154_64377;75_53865;250_37580": "Select Vehicle Model Year",
  "1_5449": "Vehicle Type",
  "1_5443": "Vehicle Brand",
  "I1_5464;75_53865;250_37580": "Select Vehicle Model",
  "1_5455": "Vehicle Color",
  "I1_5457;75_53865;250_37580": "Select Vehicle Color",
  "1_5481": "mm",
  "1_5436": "General Setting",
  "I1_5523;30_32140": "Next",
  "1_5489": "mm",
  "I1_5545;250_58575": "Manage Vehicle",
  "1_5520": "Towing Service Information",
  "I1_5522;30_32137": "Cancel",
  "I1_5503;185_68545": "Number Plate",
  "I1_5430;35_49551;28_31261": ">",
  "I1_5430;35_49548;28_31236": "Vehicle Management",
  "I1_5430;35_49552;28_31243": "Edit Vehicle",
  "1_5552": "Chauffeur Connect",
  "I1_5531;21_22137": "Top",
  "1_5492": "Height",
  "I1_5791;21_22137": "Driver Management",
  "1_5597": "*",
  "I1_5573;35_49552;28_31243": "Edit Vehicle",
  "1_5611": "*",
  "1_5627": "Width",
  "1_5762": "Towing Service Contract Expiry Date",
  "1_5703": "Action",
  "I1_5689;18_21709": "Content",
  "1_5730": "Inspectation Date",
  "1_5734": "Inspectation Due Date",
  "I1_5776;23_39072": "Company setting",
  "I1_5615;7636_13403": "Number Plate",
  "I1_5692;18_21709": "Content",
  "I1_5714;171_66633": "Action",
  "I1_5763;7696_54949": "Contract Expiry Date",
  "I1_5687;171_66633": "Action",
  "I1_5700;18_21709": "N/A",
  "1_5663": "km",
  "I1_5672;154_64377;75_53865;250_37573": "Select Vehicle Depot",
  "I1_5713;171_66633": "Department",
  "I1_5608;154_64377;75_53865;250_37580": "Select Vehicle Model Year",
  "I1_5622;185_68545": "Number Plate",
  "I1_5638;185_68545": "Number Plate",
  "I1_5792;21_22137": "Passenger Management",
  "1_5770": "5",
  "I1_5711;171_66633": "Passenger Name",
  "I1_5646;185_68545": "Number Plate",
  "1_5707": "Assigned Passenger",
  "1_5598": "Vehicle Color",
  "1_5679": "Assigned Driver",
  "I1_5574;35_49503": "Edit Vehicle",
  "1_5624": "mm",
  "1_5592": "Vehicle Type",
  "1_5619": "Length",
  "I1_5718;18_21709": "Content",
  "1_5748": "Insurance Service Contract Expiry Date",
  "I1_5803;23_39072": "Approval",
  "I1_5705;30_32140": "Add Driver",
  "1_5640": "mm",
  "I1_5661;121_58638": "Odometer",
  "I1_5693;18_21709": "N/A",
  "I1_5607;75_53865;250_37580": "Select Vehicle Model",
  "1_5586": "Vehicle Brand",
  "I1_5721;30_32140": "Add Passenger",
  "I1_5765;30_32137": "Cancel",
  "I1_5691;18_21709": "Content",
  "I1_5573;35_49550;28_31236": "Manage Vehicle",
  "I1_5573;35_49551;28_31261": ">",
  "I1_5759;7696_54949": "Contract Start Date",
  "1_5652": "Advance Setting",
  "I1_5719;18_21709": "Content",
  "1_5666": "Vehicle Image",
  "1_5604": "*",
  "I1_5731;7696_54949": "Inspectation Date",
  "I1_5615;7636_13405;185_68545": "Number Plate",
  "I1_5716;18_21709": "Content",
  "I1_5788;250_58575": "Manage Vehicle",
  "I1_5710;171_66633": "Passenger ID",
  "I1_5786;23_39084": "Vehicle Management",
  "I1_5690;18_21709": "Content",
  "I1_5588;75_53865;250_37580": "Select Vehicle Brand",
  "1_5744": "Insurance Service Contract Start Date",
  "1_5725": "Vehicle Inspectation Information",
  "1_5795": "Chauffeur Connect",
  "289_32116": "uploadFile",
  "1_5658": "Odometer",
  "I1_5671;154_64376": "Vehicle Group",
  "I1_5608;154_64376": "Vehicle Model Year",
  "I1_5573;35_49548;28_31236": "Vehicle Management",
  "I1_5774;21_22137": "Top",
  "I1_5670;7636_13403": "Vehicle Nickname",
  "1_5635": "Height",
  "I1_5683;171_66633": "Driver Name",
  "I1_5712;171_66633": "Email Address",
  "I1_5720;528_68898;30_31886": "Delete",
  "I1_5745;7696_54949": "Contract Start Date",
  "1_5591": "*",
  "I1_5808;23_39072": "Notification",
  "I1_5696;18_21709": "Content",
  "I1_5697;18_21709": "Content",
  "1_5758": "Towing Service Contract Start Date",
  "1_5612": "VIN",
  "I1_5600;75_53865;250_37580": "Select Vehicle Color",
  "1_5753": "Towing Service Information",
  "1_5632": "mm",
  "I1_5790;250_58566": "Vehicle Depot",
  "I1_5614;185_68545": "VIN",
  "I1_5789;250_58566": "Vehicle Group",
  "I1_5766;30_32140": "Next",
  "I1_5735;7696_54949": "Inspectation Due Date",
  "1_5772": "Client Portal",
  "I1_5671;154_64377;75_53865;250_37573": "Select Vehicle Group",
  "I1_5699;18_21709": "Content",
  "I1_5594;75_53865;250_37580": "Select Vehicle Type",
  "I1_5798;23_39072": "Work Management",
  "I1_5684;171_66633": "Email Address",
  "1_5585": "*",
  "1_5643": "Max Weight",
  "I1_5672;154_64376": "Vehicle Depot",
  "I1_5630;185_68545": "Number Plate",
  "1_5676": "Vehicle Assignment",
  "1_5605": "Vehicle Model",
  "I1_5749;7696_54949": "Contract Expiry Date",
  "I1_5573;35_49549;28_31261": ">",
  "1_5739": "Insurance Service Information",
  "I1_5686;171_66633": "Driver Role",
  "1_5648": "Kg",
  "I1_5682;171_66633": "Driver ID",
  "I1_5685;171_66633": "Department",
  "I1_5717;18_21709": "Content",
  "1_5579": "General Setting",
  "I1_5698;18_21709": "Content",
  "I1_5670;7636_13405;121_58638": "Vehicle Nickname",
  "I1_5826;154_64377;75_53865;250_37580": "Select Vehicle Brand",
  "I1_5879;185_68545": "Odometer",
  "I1_5956;7696_54949": "Contract Start Date",
  "I1_5904;171_66633": "Driver Role",
  "I1_5888;7636_13403": "Vehicle Nickname",
  "1_5861": "Max Weight",
  "289_32117": "uploadFile",
  "I1_5927;18_21709": "Content",
  "I1_5816;35_49607;28_31261": ">",
  "I1_5900;171_66633": "Driver ID",
  "I1_5902;171_66633": "Email Address",
  "1_6006": "Chauffeur Connect",
  "I1_5889;154_64376": "Vehicle Group",
  "1_5981": "5",
  "I1_5976;30_32137": "Back",
  "I1_5997;23_39084": "Vehicle Management",
  "I1_5831;154_64376": "Vehicle Model Year",
  "I1_5942;7696_54949": "Inspectation Date",
  "I1_5974;7696_54949": "Contract Expiry Date",
  "I1_5977;30_32140": "Update",
  "I1_5924;171_66633": "Department",
  "1_5936": "Vehicle Inspectation Information",
  "I1_5925;171_66633": "Action",
  "I1_5816;35_49596;28_31261": ">",
  "I1_5901;171_66633": "Driver Name",
  "I1_5910;18_21709": "Content",
  "1_5955": "Insurance Service Contract Start Date",
  "I1_5830;154_64376": "* Vehicle Model",
  "I1_5816;35_49598;28_31261": ">",
  "I1_5816;35_49605;28_31243": "Preview Vehicle",
  "I1_6000;250_58566": "Vehicle Group",
  "I1_5908;18_21709": "Content",
  "I1_5833;7636_13403": "Number Plate",
  "I1_5826;154_64376": "* Vehicle Brand",
  "I1_5905;171_66633": "Action",
  "I1_5840;185_68545": "Number Plate",
  "1_5894": "Vehicle Assignment",
  "1_5845": "Width",
  "I1_5922;171_66633": "Passenger Name",
  "I1_5827;154_64376": "* Vehicle Type",
  "1_5918": "Assigned Passenger",
  "I1_5816;35_49599;28_31236": "Edit Vehicle",
  "I1_5889;154_64377;75_53865;250_37580": "Select Vehicle Group",
  "I1_6001;250_58566": "Vehicle Depot",
  "1_5866": "Kg",
  "I1_5828;154_64376": "* Vehicle Color",
  "I1_5832;7636_13403": "* VIN",
  "I1_5903;171_66633": "Department",
  "I1_5832;7636_13405;185_68545": "VIN",
  "I1_5831;154_64377;75_53865;250_37580": "Select Vehicle Model Year",
  "I1_6014;23_39072": "Approval",
  "1_5973": "Towing Service Contract Expiry Date",
  "1_5842": "mm",
  "I1_5970;7696_54949": "Contract Start Date",
  "1_5876": "Odometer",
  "I1_5985;21_22137": "Top",
  "I1_5932;30_32081": "Add Passenger",
  "1_5881": "km",
  "1_5897": "Assigned Driver",
  "I1_5911;18_21709": "N/A",
  "I1_6003;21_22137": "Passenger Management",
  "I1_5960;7696_54949": "Contract Expiry Date",
  "I1_5999;250_58575": "Manage Vehicle",
  "I1_5890;154_64377;75_53865;250_37580": "Select Vehicle Depot",
  "1_5969": "Towing Service Contract Start Date",
  "1_5858": "mm",
  "1_5850": "mm",
  "I1_5928;18_21709": "Content",
  "1_5853": "Height",
  "1_5959": "Insurance Service Contract Expiry Date",
  "I1_5827;154_64377;75_53865;250_37580": "Select Vehicle Type",
  "1_5945": "Inspectation Due Date",
  "1_5941": "Inspectation Date",
  "I1_5830;154_64377;75_53865;250_37580": "Select Vehicle Model",
  "I1_5888;7636_13405;185_68545": "Vehicle Nickname",
  "I1_5931;528_68898;30_31856": "Delete",
  "I1_5848;185_68545": "Number Plate",
  "I1_5930;18_21709": "Content",
  "I1_5929;18_21709": "Content",
  "I1_5987;23_39072": "Company setting",
  "I1_5890;154_64376": "Vehicle Depot",
  "1_5983": "Client Portal",
  "I1_6019;23_39072": "Notification",
  "I1_5864;185_68545": "Number Plate",
  "I1_5816;35_49597;28_31236": "Manage Vehicle",
  "1_5822": "General Setting",
  "I1_5916;30_32081": "Add Driver",
  "I1_5828;154_64377;75_53865;250_37580": "Select Vehicle Color",
  "I1_5909;18_21709": "Content",
  "1_5950": "Insurance Service Information",
  "I1_5907;18_21709": "Content",
  "I1_5921;171_66633": "Passenger ID",
  "1_5870": "Advance Setting",
  "I1_5833;7636_13405;185_68545": "Number Plate",
  "I1_5816;35_49595;28_31236": "Vehicle Management",
  "1_5884": "Vehicle Image",
  "I1_5817;35_49503": "Preview Vehicle",
  "I1_5856;185_68545": "Number Plate",
  "I1_6009;23_39072": "Work Management",
  "I1_5946;7696_54949": "Inspectation Due Date",
  "1_5964": "Towing Service Information",
  "1_5914": "Action",
  "1_5837": "Length",
  "I1_5923;171_66633": "Email Address",
  "I1_6002;21_22137": "Driver Management",
  "I1_6032;121_59079": "Search...",
  "I1_6028;388_23148;30_32140": "Add",
  "1_6047": "Action",
  "I1_6052;18_21709": "7 - Seater",
  "I1_6067;581_1954;7882_26229;433_34538": "10",
  "I1_6089;250_58566": "Manage Vehicle",
  "1_6056": "Action",
  "I1_6035;171_66633": "Vehicle group",
  "I1_6027;28_31263;28_31261": ">",
  "I1_6059;835_98906": "Luxury",
  "I1_6036;171_66633": "Image",
  "1_6071": "5",
  "I1_6067;581_1954;7882_22097;433_34538": "4",
  "I1_6075;21_22137": "Top",
  "I1_6043;18_21709": "4 - Seater",
  "I1_6109;23_39072": "Notification",
  "I1_6090;250_58575": "Vehicle Group",
  "I1_6027;28_31246;28_31236": "Vehicle Management",
  "I1_6087;23_39084": "Vehicle Management",
  "I1_6042;320_28620": "Image",
  "1_6073": "Client Portal",
  "I1_6067;581_1954;7882_21473;497_44116": "1",
  "I1_6067;581_1954;7882_26275;433_34538": "11",
  "I1_6050;835_98906": "Economy",
  "I1_6039;171_66633": "Action",
  "I1_6041;835_98906": "Standard",
  "I1_6027;28_31248;28_31243": "Vehicle Group",
  "I1_6067;581_1954;7882_21853;433_34538": "2",
  "I1_6093;21_22137": "Passenger Management",
  "I1_6104;23_39072": "Approval",
  "I1_6051;320_28620": "Image",
  "I1_6067;581_1954;7882_26160;433_34538": "...",
  "I1_6067;368_110840": "Show 1 to 10 of 100 results",
  "1_6097": "Chauffeur Connect",
  "I1_6037;171_66633": "Description",
  "I1_6092;21_22137": "Driver Management",
  "I1_6099;23_39072": "Work Management",
  "I1_6067;581_1954;7882_19131;497_44116": "10 / page",
  "I1_6091;250_58566": "Vehicle Depot",
  "I1_6028;388_23101": "Vehicle Group",
  "I1_6077;23_39072": "Company setting",
  "I1_6067;581_1954;7882_21971;433_34538": "3",
  "1_6065": "Action",
  "I1_6061;18_21709": "4 - Seater",
  "I1_6060;320_28620": "Image",
  "I1_6117;35_49548;28_31236": "Vehicle Management",
  "I1_6142;30_32137": "Cancel",
  "I1_6169;21_22137": "Passenger Management",
  "I1_6185;23_39072": "Notification",
  "1_6147": "5",
  "1_6124": "*",
  "I1_6163;23_39084": "Vehicle Management",
  "I1_6117;35_49552;28_31243": "Add Vehicle Group",
  "I1_6118;35_49503": "Add Vehicle Group",
  "I1_6167;250_58566": "Vehicle Depot",
  "I1_6151;21_22137": "Top",
  "1_6149": "Client Portal",
  "I1_6165;250_58566": "Manage Vehicle",
  "I1_6153;23_39072": "Company setting",
  "I1_6117;35_49551;28_31261": ">",
  "I1_6168;21_22137": "Driver Management",
  "I1_6128;7700_22671": "Description",
  "I1_6117;35_49549;28_31261": ">",
  "1_6137": "* ",
  "1_6173": "Chauffeur Connect",
  "I1_6166;250_58575": "Vehicle Group",
  "I1_6175;23_39072": "Work Management",
  "1_6138": "Vehicle Image",
  "I1_6180;23_39072": "Approval",
  "I1_6128;7700_22673;154_61744": "Description",
  "I289_32118;289_32078": "Upload",
  "I1_6143;30_32140": "Save",
  "1_6131": "Enable Status",
  "I1_6117;35_49550;28_31236": "Vehicle Group",
  "I1_6127;75_53807": "Vehicle group",
  "1_6125": "Vehicle Group",
  "I1_6225;21_22137": "Top",
  "I1_6204;7700_22671": "Description",
  "I1_6217;30_32137": "Cancel",
  "I1_6241;250_58566": "Vehicle Depot",
  "I1_6193;35_49548;28_31236": "Vehicle Management",
  "I1_6249;23_39072": "Work Management",
  "I1_6193;35_49551;28_31261": ">",
  "I1_6242;21_22137": "Driver Management",
  "289_32141": "uploadFile",
  "I1_6193;35_49550;28_31236": "Vehicle Group",
  "I1_6194;35_49503": "View Vehicle Group",
  "I1_6204;7700_22673;185_68601": "Description",
  "1_6221": "5",
  "I1_6193;35_49549;28_31261": ">",
  "1_6207": "Enable Status",
  "1_6223": "Client Portal",
  "I1_6203;185_68545": "Vehicle group",
  "I1_6254;23_39072": "Approval",
  "I1_6193;35_49552;28_31243": "View Vehicle Group",
  "1_6200": "*",
  "I1_6243;21_22137": "Passenger Management",
  "I1_6227;23_39072": "Company setting",
  "I1_6259;23_39072": "Notification",
  "1_6214": "Vehicle Image",
  "1_6201": "Vehicle Group",
  "1_6213": "* ",
  "1_6247": "Chauffeur Connect",
  "I1_6240;250_58575": "Vehicle Group",
  "I1_6239;250_58566": "Manage Vehicle",
  "I1_6237;23_39084": "Vehicle Management",
  "I1_6267;35_49552;28_31243": "Edeit Vehicle Group",
  "I1_6267;35_49551;28_31261": ">",
  "I1_6278;7700_22671": "Description",
  "1_6274": "*",
  "I1_6320;21_22137": "Driver Management",
  "1_6281": "Enable Status",
  "I1_6303;21_22137": "Top",
  "I1_6305;23_39072": "Company setting",
  "1_6275": "Vehicle Group",
  "I1_6268;35_49503": "Edit Vehicle Group",
  "I1_6267;35_49548;28_31236": "Vehicle Management",
  "I1_6332;23_39072": "Approval",
  "I1_6294;30_32137": "Cancel",
  "I1_6321;21_22137": "Passenger Management",
  "I1_6267;35_49549;28_31261": ">",
  "I1_6267;35_49550;28_31236": "Vehicle Group",
  "1_6287": "* ",
  "289_32142": "uploadFile",
  "I1_6315;23_39084": "Vehicle Management",
  "1_6301": "Client Portal",
  "I1_6319;250_58566": "Vehicle Depot",
  "I1_6337;23_39072": "Notification",
  "I1_6318;250_58575": "Vehicle Group",
  "1_6325": "Chauffeur Connect",
  "1_6299": "5",
  "I1_6327;23_39072": "Work Management",
  "1_6288": "Vehicle Image",
  "I1_6317;250_58566": "Manage Vehicle",
  "I1_6277;121_58638": "Vehicle Group",
  "I1_6295;30_32140": "Update",
  "I1_6278;7700_22673;250_58177": "Description",
  "I1_6372;835_98906": "Vehicle Depot 3",
  "1_6369": "Action",
  "I1_6401;250_58566": "Vehicle Group",
  "I1_6346;28_31246;28_31236": "Vehicle Management",
  "I1_6347;388_23148;30_32140": "Add",
  "I1_6346;28_31248;28_31243": "Vehicle Depot",
  "1_6384": "Client Portal",
  "I1_6351;121_59079": "Search...",
  "I1_6378;581_1954;7882_21853;433_34538": "2",
  "I1_6400;250_58566": "Manage Vehicle",
  "I1_6378;368_110840": "Show 1 to 10 of 100 results",
  "I1_6354;171_66633": "Depot Name",
  "I1_6378;581_1954;7882_26275;433_34538": "11",
  "I1_6359;18_21709": "Content",
  "I1_6404;21_22137": "Passenger Management",
  "I1_6346;28_31263;28_31261": ">",
  "I1_6378;581_1954;7882_22097;433_34538": "4",
  "I1_6355;171_66633": "Address",
  "I1_6410;23_39072": "Work Management",
  "I1_6365;835_98906": "Vehicle Depot 2",
  "1_6382": "5",
  "I1_6373;18_21709": "Content",
  "I1_6386;21_22137": "Top",
  "I1_6398;23_39084": "Vehicle Management",
  "1_6376": "Action",
  "I1_6356;171_66633": "Action",
  "I1_6378;581_1954;7882_21473;497_44116": "1",
  "1_6407": "Chauffeur Connect",
  "I1_6415;23_39072": "Approval",
  "1_6362": "Action",
  "I1_6420;23_39072": "Notification",
  "I1_6388;23_39072": "Company setting",
  "I1_6378;581_1954;7882_26160;433_34538": "...",
  "I1_6366;18_21709": "Content",
  "I1_6378;581_1954;7882_21971;433_34538": "3",
  "I1_6378;581_1954;7882_19131;497_44116": "10 / page",
  "I1_6347;388_23101": "Vehicle Depot",
  "I1_6403;21_22137": "Driver Management",
  "I1_6402;250_58575": "Vehicle Depot",
  "I1_6378;581_1954;7882_26229;433_34538": "10",
  "I1_6358;835_98906": "Vehicle Depot 1",
  "I1_6518;23_39072": "Notification",
  "I1_6499;250_58566": "Vehicle Group",
  "I1_6513;23_39072": "Approval",
  "I1_6500;250_58575": "Vehicle Depot",
  "I1_6502;21_22137": "Passenger Management",
  "I1_6484;21_22137": "Top",
  "I1_6428;35_49551;28_31261": ">",
  "1_6435": "*",
  "I1_6429;35_49503": "Add Vehicle Depot",
  "I1_6455;75_53807": "City, State",
  "1_6505": "Chauffeur Connect",
  "1_6446": "Auto-fills, using saved address",
  "I1_6498;250_58566": "Manage Vehicle",
  "I1_6428;35_49548;28_31236": "Vehicle Management",
  "1_6482": "Client Portal",
  "1_6443": "Latitude, Longtitude",
  "1_6442": "*",
  "1_6436": "Depot Name",
  "I1_6496;23_39084": "Vehicle Management",
  "172_30111": "Address",
  "1_6480": "5",
  "I1_6459;30_32137": "Cancel",
  "I1_6428;35_49549;28_31261": ">",
  "I1_6460;30_32140": "Save",
  "I1_6508;23_39072": "Work Management",
  "I1_6438;75_53807": "Depot Name",
  "I1_6428;35_49550;28_31236": "Vehicle Depot",
  "I1_6501;21_22137": "Driver Management",
  "I1_6486;23_39072": "Company setting",
  "1_6461": "Frame 1000003129",
  "I1_6609;23_39072": "Approval",
  "I1_6597;21_22137": "Driver Management",
  "1_6544": "Auto-fills, using saved address",
  "1_6578": "Client Portal",
  "I1_6526;35_49552;28_31243": "View Vehicle Depot",
  "I1_6594;250_58566": "Manage Vehicle",
  "I1_6580;21_22137": "Top",
  "I1_6598;21_22137": "Passenger Management",
  "1_6557": "Frame 1000003129",
  "I1_6556;30_32137": "Cancel",
  "1_6534": "Depot Name",
  "I1_6526;35_49550;28_31236": "Vehicle Depot",
  "I1_6526;35_49551;28_31261": ">",
  "1_6540": "*",
  "1_6576": "5",
  "1_6601": "Chauffeur Connect",
  "I1_6526;35_49549;28_31261": ">",
  "I1_6527;35_49503": "View Vehicle Depot",
  "I1_6604;23_39072": "Work Management",
  "I1_6536;185_68545": "Depot Name",
  "I1_6553;185_68545": "City, State",
  "1_6541": "Latitude, Longtitude",
  "I1_6526;35_49548;28_31236": "Vehicle Management",
  "I1_6596;250_58575": "Vehicle Depot",
  "1_6533": "*",
  "I1_6614;23_39072": "Notification",
  "1_6550": "Address",
  "I1_6595;250_58566": "Vehicle Group",
  "I1_6582;23_39072": "Company setting",
  "I1_6592;23_39084": "Vehicle Management",
  "1_6630": "Depot Name",
  "I1_6632;121_58638": "Name",
  "I1_6649;121_58638": "City, State",
  "I1_6712;23_39072": "Notification",
  "I1_6622;35_49548;28_31236": "Vehicle Management",
  "1_6676": "Client Portal",
  "1_6646": "Address",
  "I1_6654;30_32140": "Update",
  "I1_6690;23_39084": "Vehicle Management",
  "I1_6692;250_58566": "Manage Vehicle",
  "I1_6653;30_32137": "Cancel",
  "I1_6695;21_22137": "Driver Management",
  "1_6629": "*",
  "I1_6623;35_49503": "Edit Vehicle Depot",
  "I1_6707;23_39072": "Approval",
  "I1_6680;23_39072": "Company setting",
  "1_6699": "Chauffeur Connect",
  "1_6636": "*",
  "1_6655": "Frame 1000003129",
  "I1_6693;250_58566": "Vehicle Group",
  "I1_6622;35_49549;28_31261": ">",
  "1_6640": "Auto-fills, using saved address",
  "I1_6622;35_49550;28_31236": "Vehicle Depot",
  "1_6674": "5",
  "I1_6622;35_49551;28_31261": ">",
  "1_6637": "Latitude, Longtitude",
  "I1_6678;21_22137": "Top",
  "I1_6696;21_22137": "Passenger Management",
  "I1_6694;250_58575": "Vehicle Depot",
  "I1_6702;23_39072": "Work Management",
  "1_6794": "Vehicle Management",
  "I1_6764;18_21709": "123467891",
  "I1_6734;171_66633": "Employee Number",
  "I1_6732;171_66633": "Driver ID",
  "I1_6736;171_66633": "Email",
  "I1_6744;18_21709": "xxxxx@gmail.com",
  "I1_6755;18_21709": "xxxxx@gmail.com",
  "I1_6725;30_32021": "Import",
  "I1_6803;23_39072": "Work Management",
  "I1_6751;18_21709": "83 2",
  "I1_6740;18_21709": "25",
  "1_6800": "Chauffeur Connect",
  "I1_6813;23_39072": "Notification",
  "1_6769": "Action",
  "I1_6771;581_1954;7882_21473;497_44116": "1",
  "I1_6735;171_66633": "Department",
  "1_6777": "Client Portal",
  "I1_6771;581_1954;7882_26160;433_34538": "...",
  "I1_6796;21_27541": "Driver Management",
  "I1_6763;835_98906": "Greg Ho",
  "I1_6771;581_1954;7882_22097;433_34538": "4",
  "I1_6765;18_21709": "Driver department",
  "I1_6781;23_39072": "Company setting",
  "1_6775": "5",
  "I1_6808;23_39072": "Approval",
  "I1_6762;18_21709": "28",
  "I1_6771;581_1954;7882_21971;433_34538": "3",
  "I1_6742;18_21709": "123467895",
  "I1_6771;368_110840": "Show 1 to 10 of 100 results",
  "I1_6741;835_98906": "Adam Bean",
  "I1_6743;18_21709": "Driver department",
  "I1_6753;18_21709": "123467892",
  "1_6747": "Action",
  "I1_6722;388_23148;30_32140": "Add",
  "I1_6754;18_21709": "Driver department",
  "I1_6771;581_1954;7882_26229;433_34538": "10",
  "I1_6722;388_23101": "Driver Management",
  "I1_6726;30_32021": "Export",
  "I1_6771;581_1954;7882_26275;433_34538": "11",
  "I1_6771;581_1954;7882_19131;497_44116": "10 / page",
  "I1_6752;835_98906": "Eric Foo",
  "1_6758": "Action",
  "I1_6733;171_66633": "Driver Name",
  "I1_6737;171_66633": "Action",
  "I1_6779;21_22137": "Top",
  "I1_6771;581_1954;7882_21853;433_34538": "2",
  "I1_6797;21_22137": "Passenger Management",
  "I1_6766;18_21709": "xxxxx@gmail.com",
  "I1_6727;121_59079": "Search...",
  "I1_6915;21_22137": "Passenger Management",
  "1_6912": "Vehicle Management",
  "1_6848": "Email",
  "I1_6881;250_37564": "State/Province",
  "1_6870": "Confirm Password",
  "I1_6879;75_53807": "City",
  "289_32155": "Photo",
  "1_6847": "*",
  "I1_6889;30_32140": "Save",
  "I1_6850;75_53807": "Email",
  "1_6827": "Basic Information",
  "1_6854": "Phone Number",
  "155_30104": "Middle Name",
  "I1_6821;28_31263;28_31261": ">",
  "1_6853": "*",
  "1_6874": "Address",
  "I1_6899;23_39072": "Company setting",
  "I1_6821;28_31248;28_31243": "Add Driver",
  "I1_6888;30_32137": "Cancel",
  "1_6839": "*",
  "I1_6921;23_39072": "Work Management",
  "1_6840": "Last Name",
  "I1_6877;75_53807": "Address 1",
  "I1_6822;35_49503": "Add Driver",
  "I1_6858;75_53807": "Phone Number",
  "I1_6897;21_22137": "Top",
  "I1_6821;28_31246;28_31236": "Driver Management",
  "I1_6883;250_37564": "Country",
  "1_6895": "Client Portal",
  "1_6918": "Chauffeur Connect",
  "1_6833": "*",
  "I289_32166;289_32078": "Upload",
  "I1_6872;884_102263": "Confirm Password",
  "I1_6926;23_39072": "Approval",
  "I1_6882;75_53807": "Zip/ Postal Code",
  "1_6893": "5",
  "1_6869": "*",
  "I1_6914;21_27541": "Driver Management",
  "155_30103": "*",
  "I1_6842;75_53807": "Last Name",
  "I1_6836;75_53807": "First Name",
  "1_6863": "*",
  "I1_6866;884_102263": "Password",
  "1_6864": "Password",
  "1_6834": "First Name",
  "I1_6857;956_83002": "Phone code",
  "1_6886": "Company Information",
  "I1_6878;75_53807": "Address 2",
  "I1_6931;23_39072": "Notification",
  "I155_30106;75_53807": "Middle Name",
  "I1_7035;30_32137": "Cancel",
  "I1_6939;28_31248;28_31243": "Add Driver",
  "1_6965": "*",
  "I1_6968;75_53807": "Email",
  "1_6992": "Address",
  "I1_6960;75_53807": "Last Name",
  "289_32202": "Photo",
  "1_7012": "Employee Number",
  "1_6982": "Password",
  "1_6981": "*",
  "I1_7027;75_53865;250_37564": "Select Department",
  "1_6966": "Email",
  "I1_6976;75_53807": "Phone Number",
  "1_7031": "Select Overtime Rules",
  "1_7040": "5",
  "I1_6940;35_49503": "Add Driver",
  "I1_7014;75_53807": "Employee Number",
  "1_7024": "*",
  "I1_7073;23_39072": "Approval",
  "I1_7033;75_53865;250_37564": "Select Overtime Rules",
  "1_7042": "Client Portal",
  "1_7018": "Select Business Hour",
  "155_30110": "*",
  "I1_7046;23_39072": "Company setting",
  "1_6945": "Basic Information",
  "I1_6995;75_53807": "Address 1",
  "I1_6939;28_31246;28_31236": "Driver Management",
  "1_6957": "*",
  "1_7005": "Company Information",
  "I1_6975;956_83002": "Phone code",
  "1_6971": "*",
  "I1_7036;30_32140": "Next",
  "I289_32228;289_32078": "Upload",
  "I1_6999;250_37564": "State/Province",
  "1_6958": "Last Name",
  "1_6988": "Confirm Password",
  "1_7017": "*",
  "I1_7020;75_53865;250_37564": "Select Business Hour",
  "1_6951": "*",
  "I1_6939;28_31263;28_31261": ">",
  "I1_6997;75_53807": "City",
  "1_6952": "First Name",
  "I1_6954;75_53807": "First Name",
  "1_6972": "Phone Number",
  "I1_7062;21_22137": "Passenger Management",
  "1_7059": "Vehicle Management",
  "I1_7001;250_37564": "Country",
  "I1_7000;75_53807": "Zip/ Postal Code",
  "I155_30113;75_53807": "Middle Name",
  "I1_7078;23_39072": "Notification",
  "1_7025": "Select Department",
  "1_7011": "*",
  "I1_6990;884_102263": "Confirm Password",
  "I1_7061;21_27541": "Driver Management",
  "I1_7044;21_22137": "Top",
  "I1_6984;884_102263": "Password",
  "1_7030": "*",
  "I1_6996;75_53807": "Address 2",
  "I1_7068;23_39072": "Work Management",
  "1_6987": "*",
  "1_7065": "Chauffeur Connect",
  "155_30111": "Middle Name",
  "1_7139": "Address",
  "1_7206": "Vehicle Management",
  "I1_7225;23_39072": "Notification",
  "I1_7191;21_22137": "Top",
  "I1_7146;250_37580": "State/Province",
  "I1_7142;185_68545": "Address 1",
  "1_7134": "*",
  "1_7105": "Last Name",
  "I1_7161;185_68545": "Employee Number",
  "I1_7183;30_32140": "Save",
  "I155_30120;185_68545": "Middle Name",
  "1_7104": "*",
  "155_30118": "Middle Name",
  "I1_7131;884_102319": "Password",
  "I1_7208;21_27541": "Driver Management",
  "I1_7143;185_68545": "Address 2",
  "I1_7122;956_83014": "Phone code",
  "I289_32247;289_32078": "Upload",
  "1_7099": "First Name",
  "I1_7147;185_68545": "Zip/ Postal Code",
  "1_7118": "*",
  "1_7152": "Company Information",
  "I1_7086;28_31248;28_31243": "Preview Driver",
  "I1_7180;75_53865;250_37580": "Select Overtime Rules",
  "1_7212": "Chauffeur Connect",
  "I1_7215;23_39072": "Work Management",
  "1_7113": "Email",
  "I1_7086;28_31246;28_31236": "Driver Management",
  "1_7098": "*",
  "I1_7182;30_32137": "Back",
  "I1_7101;185_68545": "First Name",
  "1_7171": "*",
  "1_7128": "*",
  "I1_7148;250_37580": "Country",
  "1_7172": "Select Department",
  "1_7187": "5",
  "1_7119": "Phone Number",
  "1_7112": "*",
  "I1_7087;35_49503": "Preview Driver",
  "I1_7086;28_31263;28_31261": ">",
  "1_7165": "Select Business Hour",
  "I1_7174;75_53865;250_37580": "Select Department",
  "1_7189": "Client Portal",
  "1_7177": "*",
  "289_32236": "Photo",
  "I1_7209;21_22137": "Passenger Management",
  "I1_7107;185_68545": "Last Name",
  "1_7135": "Confirm Password",
  "155_30117": "*",
  "I1_7193;23_39072": "Company setting",
  "I1_7220;23_39072": "Approval",
  "1_7164": "*",
  "1_7159": "Employee Number",
  "1_7092": "Basic Information",
  "I1_7115;185_68545": "Email",
  "1_7178": "Select Overtime Rules",
  "1_7158": "*",
  "I1_7137;884_102319": "Confirm Password",
  "I1_7123;185_68545": "Phone Number",
  "1_7129": "Password",
  "I1_7167;75_53865;250_37580": "Select Business Hour",
  "I1_7144;185_68545": "City",
  "I155_30127;185_68545": "Middle Name",
  "1_7248": "First Name",
  "I1_7235;35_49503": "View Driver",
  "I1_7272;185_68545": "Phone Number",
  "1_7287": "Company Information",
  "I1_7264;185_68545": "Email",
  "1_7241": "Basic Information",
  "1_7261": "*",
  "I1_7313;21_27541": "Driver Management",
  "155_30125": "Middle Name",
  "155_30124": "*",
  "I1_7237;758_37471;758_37454": "Work Report",
  "I1_7237;758_37470;758_37454": "Driver Evaluation",
  "1_7267": "*",
  "I1_7278;185_68545": "Address 1",
  "I1_7296;21_22137": "Top",
  "I1_7330;23_39072": "Notification",
  "I1_7325;23_39072": "Approval",
  "I1_7233;28_31248;28_31243": "View Driver",
  "1_7311": "Vehicle Management",
  "I289_32605;289_32078": "Upload",
  "1_7317": "Chauffeur Connect",
  "I1_7282;250_37580": "State/Province",
  "I1_7279;185_68545": "Address 2",
  "289_32581": "Photo",
  "I1_7271;956_83014": "Phone code",
  "1_7294": "Client Portal",
  "1_7253": "*",
  "I1_7233;28_31246;28_31236": "Driver Management",
  "I1_7298;23_39072": "Company setting",
  "I1_7237;758_37469;758_37460": "Driver Information",
  "I1_7280;185_68545": "City",
  "I1_7314;21_22137": "Passenger Management",
  "I1_7288;30_32137": "Cancel",
  "I1_7284;250_37580": "Country",
  "I1_7256;185_68545": "Last Name",
  "1_7262": "Email",
  "I1_7233;28_31263;28_31261": ">",
  "1_7292": "5",
  "I1_7250;185_68545": "First Name",
  "I1_7320;23_39072": "Work Management",
  "I1_7283;185_68545": "Zip/ Postal Code",
  "1_7254": "Last Name",
  "1_7247": "*",
  "1_7268": "Phone Number",
  "1_7275": "Address",
  "1_7405": "*",
  "I1_7422;30_32137": "Cancel",
  "I1_7387;250_37580": "State/Province",
  "1_7372": "*",
  "1_7393": "Company Information",
  "155_30131": "*",
  "1_7346": "Basic Information",
  "I1_7342;758_37470;758_37454": "Driver Evaluation",
  "1_7366": "*",
  "I1_7384;185_68545": "Address 2",
  "I1_7377;185_68545": "Phone Number",
  "I1_7340;35_49503": "View Driver",
  "I1_7355;185_68545": "First Name",
  "I1_7402;185_68545": "Employee Number",
  "1_7358": "*",
  "I1_7376;956_83014": "Phone code",
  "1_7445": "Vehicle Management",
  "I1_7459;23_39072": "Approval",
  "I1_7342;758_37469;758_37460": "Driver Information",
  "1_7399": "*",
  "1_7400": "Employee Number",
  "1_7413": "Select Department",
  "I1_7369;185_68545": "Email",
  "1_7419": "Select Overtime Rules",
  "1_7428": "Client Portal",
  "I1_7421;75_53865;250_37580": "Select Overtime Rules",
  "I1_7432;23_39072": "Company setting",
  "I1_7464;23_39072": "Notification",
  "1_7426": "5",
  "I1_7447;21_27541": "Driver Management",
  "I1_7383;185_68545": "Address 1",
  "1_7412": "*",
  "1_7367": "Email",
  "I289_32573;289_32078": "Upload",
  "I1_7430;21_22137": "Top",
  "289_32594": "Photo",
  "I1_7338;28_31248;28_31243": "View Driver",
  "1_7418": "*",
  "I1_7448;21_22137": "Passenger Management",
  "155_30132": "Middle Name",
  "I1_7385;185_68545": "City",
  "I1_7338;28_31263;28_31261": ">",
  "1_7373": "Phone Number",
  "1_7451": "Chauffeur Connect",
  "I1_7408;75_53865;250_37580": "Select Business Hour",
  "1_7352": "*",
  "1_7359": "Last Name",
  "I1_7342;758_37471;758_37454": "Work Report",
  "I1_7338;28_31246;28_31236": "Driver Management",
  "I1_7415;75_53865;250_37580": "Select Department",
  "1_7380": "Address",
  "I1_7388;185_68545": "Zip/ Postal Code",
  "1_7353": "First Name",
  "1_7406": "Select Business Hour",
  "I1_7454;23_39072": "Work Management",
  "I1_7389;250_37580": "Country",
  "I155_30134;185_68545": "Middle Name",
  "I1_7361;185_68545": "Last Name",
  "I1_7604;23_39072": "Approval",
  "I1_7565;581_1954;7882_26275;433_34538": "11",
  "I1_7599;23_39072": "Work Management",
  "1_7552": "A day ago",
  "I1_7575;21_22137": "Top",
  "1_7483": "Employee Number",
  "1_7494": "2",
  "1_7562": "A day ago",
  "1_7542": "A day ago",
  "I1_7472;28_31246;28_31236": "Driver Management",
  "1_7505": "Total Reviews",
  "1_7522": "30",
  "1_7524": "3 Star",
  "I1_7476;758_37671;758_37460": "Driver Evaluation",
  "1_7571": "5",
  "1_7530": "9",
  "1_7487": "Evaluations",
  "1_7534": "0",
  "1_7480": "Driver Name",
  "1_7482": "Driver Department",
  "I1_7592;21_27541": "Driver Management",
  "1_7596": "Chauffeur Connect",
  "1_7493": "Excess Handing",
  "I1_7472;28_31263;28_31261": ">",
  "1_7498": "2",
  "1_7590": "Vehicle Management",
  "1_7551": "Jinny Oslin",
  "1_7497": "Hard Acceleration",
  "I1_7565;581_1954;7882_19131;497_44116": "10 / page",
  "I1_7488;497_44890": "All time",
  "1_7526": "10",
  "1_7532": "1 Star",
  "I1_7565;581_1954;7882_26229;433_34538": "10",
  "I1_7474;35_49503": "View Driver",
  "I1_7567;30_32137": "Cancel",
  "I1_7476;758_37672;758_37454": "Work Report",
  "I1_7565;581_1954;7882_21473;497_44116": "1",
  "1_7564": "Magna  id sint iren in cillum ese nisi dolor laboris",
  "I1_7565;581_1954;7882_22097;433_34538": "4",
  "I1_7565;581_1954;7882_21853;433_34538": "2",
  "I1_7472;28_31248;28_31243": "View Driver",
  "1_7541": "Jinny Oslin",
  "1_7518": "50",
  "1_7509": "Average Rating",
  "1_7561": "Jinny Oslin",
  "1_7501": "Hard Breaking",
  "I1_7609;23_39072": "Notification",
  "I1_7565;368_110840": "Show 1 to 10 of 100 results",
  "1_7520": "4 Star",
  "I1_7476;758_37670;758_37454": "Driver Information",
  "I1_7565;581_1954;7882_26160;433_34538": "...",
  "1_7502": "0",
  "1_7544": "Magna  id sint iren in cillum ese nisi dolor laboris",
  "1_7528": "2 Star",
  "1_7516": "5 Star",
  "I1_7577;23_39072": "Company setting",
  "1_7573": "Client Portal",
  "1_7511": "4.5",
  "1_7554": "Magna  id sint iren in cillum ese nisi dolor laboris",
  "1_7506": "99",
  "I1_7593;21_22137": "Passenger Management",
  "1_7478": "uploadFile",
  "I1_7565;581_1954;7882_21971;433_34538": "3",
  "I289_32267;289_32078": "Upload",
  "I1_7646;121_58638": "Email",
  "1_7649": "*",
  "I1_7618;35_49503": "Edit Driver",
  "I1_7617;28_31263;28_31261": ">",
  "1_7643": "*",
  "I1_7665;121_58638": "ZIP/Postal Code",
  "1_7695": "Vehicle Management",
  "1_7630": "First Name",
  "1_7676": "5",
  "I1_7661;121_58638": "Address 2",
  "I1_7704;23_39072": "Work Management",
  "I1_7666;250_37573": "Country",
  "I1_7682;23_39072": "Company setting",
  "155_30139": "Middle Name",
  "1_7629": "*",
  "1_7701": "Chauffeur Connect",
  "I1_7662;121_58638": "City",
  "1_7635": "*",
  "I1_7680;21_22137": "Top",
  "I1_7617;28_31248;28_31243": "Edit Driver",
  "1_7636": "Last Name",
  "I1_7632;121_58638": "First Name",
  "155_30138": "*",
  "289_32256": "Photo",
  "I155_30141;121_58638": "Middle Name",
  "I1_7617;28_31246;28_31236": "Driver Management",
  "1_7644": "Email",
  "1_7669": "Company Information",
  "I1_7664;250_37573": "State/Province",
  "I1_7709;23_39072": "Approval",
  "1_7678": "Client Portal",
  "1_7657": "Address",
  "I1_7653;956_83011": "Phone code",
  "1_7623": "Basic Information",
  "I1_7654;121_58638": "Phone Number",
  "I1_7660;121_58638": "Address 1",
  "I1_7672;30_32140": "Next",
  "1_7650": "Phone Number",
  "I1_7638;121_58638": "Last Name",
  "I1_7714;23_39072": "Notification",
  "I1_7697;21_27541": "Driver Management",
  "I1_7671;30_32137": "Cancel",
  "I1_7698;21_22137": "Passenger Management",
  "I1_7843;23_39072": "Approval",
  "I1_7737;121_58638": "First Name",
  "1_7740": "*",
  "1_7762": "Address",
  "1_7810": "5",
  "I1_7766;121_58638": "Address 2",
  "1_7728": "Basic Information",
  "1_7749": "Email",
  "1_7794": "*",
  "I1_7723;35_49503": "Edit Driver",
  "I1_7767;121_58638": "City",
  "I1_7751;121_58638": "Email",
  "I1_7722;28_31246;28_31236": "Driver Management",
  "1_7801": "Select Overtime Rules",
  "1_7782": "Employee Number",
  "I1_7722;28_31263;28_31261": ">",
  "I1_7805;30_32137": "Cancel",
  "1_7735": "First Name",
  "155_30145": "*",
  "1_7755": "Phone Number",
  "I1_7806;30_32140": "Next",
  "I289_32286;289_32078": "Upload",
  "I1_7797;75_53865;250_37573": "Select Department",
  "I1_7790;75_53865;250_37573": "Select Business Hour",
  "I1_7816;23_39072": "Company setting",
  "I1_7743;121_58638": "Last Name",
  "I155_30148;121_58638": "Middle Name",
  "I1_7784;121_58638": "Employee Number",
  "1_7812": "Client Portal",
  "1_7775": "Company Information",
  "I1_7831;21_27541": "Driver Management",
  "I1_7770;121_58638": "ZIP/Postal Code",
  "I1_7848;23_39072": "Notification",
  "1_7829": "Vehicle Management",
  "1_7741": "Last Name",
  "I1_7758;956_83011": "Phone code",
  "I1_7765;121_58638": "Address 1",
  "1_7835": "Chauffeur Connect",
  "I1_7722;28_31248;28_31243": "Edit Driver",
  "I1_7769;250_37573": "State/Province",
  "1_7795": "Select Department",
  "289_32275": "Photo",
  "I1_7803;75_53865;250_37573": "Select Overtime Rules",
  "1_7788": "Select Business Hour",
  "I1_7838;23_39072": "Work Management",
  "1_7800": "*",
  "I1_7832;21_22137": "Passenger Management",
  "I1_7771;250_37573": "Country",
  "1_7781": "*",
  "I1_7759;121_58638": "Phone Number",
  "1_7734": "*",
  "155_30146": "Middle Name",
  "1_7787": "*",
  "1_7748": "*",
  "I1_7814;21_22137": "Top",
  "1_7754": "*",
  "I289_32305;289_32078": "Upload",
  "1_7934": "*",
  "I1_7877;185_68545": "Last Name",
  "155_30152": "*",
  "I1_7905;250_37580": "Country",
  "I1_7856;28_31246;28_31236": "Driver Management",
  "I155_30155;185_68545": "Middle Name",
  "I1_7900;185_68545": "Address 2",
  "I1_7948;21_22137": "Top",
  "I1_7965;21_27541": "Driver Management",
  "I1_7918;185_68545": "Employee Number",
  "1_7916": "Employee Number",
  "1_7944": "5",
  "I1_7924;75_53865;250_37580": "Select Business Hour",
  "1_7889": "Phone Number",
  "I1_7977;23_39072": "Approval",
  "1_7862": "Basic Information",
  "1_7888": "*",
  "I1_7901;185_68545": "City",
  "1_7969": "Chauffeur Connect",
  "1_7929": "Select Department",
  "I1_7885;185_68545": "Email",
  "1_7946": "Client Portal",
  "I1_7893;185_68545": "Phone Number",
  "1_7928": "*",
  "I1_7966;21_22137": "Passenger Management",
  "1_7882": "*",
  "I1_7903;250_37580": "State/Province",
  "1_7896": "Address",
  "1_7921": "*",
  "I1_7931;75_53865;250_37580": "Select Department",
  "289_32294": "Photo",
  "I1_7899;185_68545": "Address 1",
  "I1_7857;35_49503": "Preview Driver",
  "1_7875": "Last Name",
  "1_7915": "*",
  "I1_7856;28_31263;28_31261": ">",
  "1_7963": "Vehicle Management",
  "1_7868": "*",
  "1_7874": "*",
  "I1_7937;75_53865;250_37580": "Select Overtime Rules",
  "I1_7982;23_39072": "Notification",
  "I1_7939;30_32137": "Back",
  "I1_7892;956_83014": "Phone code",
  "1_7922": "Select Business Hour",
  "I1_7972;23_39072": "Work Management",
  "1_7909": "Company Information",
  "1_7935": "Select Overtime Rules",
  "I1_7856;28_31248;28_31243": "Preview Driver",
  "155_30153": "Middle Name",
  "I1_7950;23_39072": "Company setting",
  "I1_7871;185_68545": "First Name",
  "I1_7904;185_68545": "Zip/ Postal Code",
  "I1_7940;30_32140": "Update",
  "1_7869": "First Name",
  "1_7883": "Email",
  "I1_8039;835_98906": "Carol Dean",
  "I1_8072;23_39072": "Work Management",
  "I1_8003;171_66633": "Employee Number",
  "I1_8046;581_1954;7882_26160;433_34538": "...",
  "I1_8046;581_1954;7882_22097;433_34538": "4",
  "1_8044": "Action",
  "I1_8054;21_22137": "Top",
  "I1_7994;30_32021": "Import",
  "I1_8046;581_1954;7882_19131;497_44116": "10 / page",
  "I1_8046;581_1954;7882_26229;433_34538": "10",
  "I1_8066;21_22137": "Driver Management",
  "I1_8001;171_66633": "Passenger ID",
  "1_8024": "Action",
  "I1_8046;581_1954;7882_21971;433_34538": "3",
  "1_8014": "Action",
  "I1_8074;23_39072": "Approval",
  "1_8052": "Client Portal",
  "I1_8019;835_98906": "Eric Foo",
  "I1_8002;171_66633": "Passenger Name",
  "1_8050": "5",
  "I1_8031;18_21709": "xxxxx@gmail.com",
  "I1_8004;171_66633": "Department",
  //"I1_8004;171_66633": "Email",
  "I649_32033;171_66633": "Email",
  "I1_8005;171_66633": "Action",
  "I1_8046;581_1954;7882_21853;433_34538": "2",
  "I1_8067;21_27541": "Passenger Management",
  "I1_8011;18_21709": "xxxxx@gmail.com",
  "I1_8018;18_21709": "21",
  "I1_8030;18_21709": "123467891",
  "I1_7991;388_23148;30_32140": "Add",
  "I1_8061;23_39084": "Vehicle Management",
  "I1_8046;581_1954;7882_26275;433_34538": "11",
  "I1_8038;18_21709": "ID 4",
  "1_8070": "Chauffeur Connect",
  "I1_8009;835_98906": "Adam Bean",
  "I1_7996;121_59079": "Search...",
  "I1_8021;18_21709": "xxxxx@gmail.com",
  "I1_8008;18_21709": "15",
  "1_8034": "Action",
  "I1_8029;835_98906": "Greg Ho",
  "I1_8046;581_1954;7882_21473;497_44116": "1",
  "I1_8056;23_39072": "Company setting",
  "I1_8028;18_21709": "93",
  "I1_8041;18_21709": "xxxxx@gmail.com",
  "I1_8020;18_21709": "123467892",
  "I1_7995;30_32021": "Export",
  "I1_8010;18_21709": "123467895",
  "I1_8040;18_21709": "456315787",
  "I1_8079;23_39072": "Notification",
  "I1_7991;388_23101": "Passenger Management",
  "I1_8046;368_110840": "Show 1 to 10 of 100 results",
  "I1_8144;75_53807": "Address 2",
  "1_8105": "*",
  "I1_8116;75_53807": "Email",
  "1_8093": "Basic Information",
  "1_8129": "*",
  "I1_8123;956_83002": "Phone code",
  "I1_8175;21_22137": "Driver Management",
  "I1_8163;21_22137": "Top",
  "155_30159": "*",
  "1_8106": "Last Name",
  "1_8119": "*",
  "1_8179": "Chauffeur Connect",
  "I1_8165;23_39072": "Company setting",
  "1_8140": "Address",
  "I1_8148;75_53807": "Zip/ Postal Code",
  "I1_8147;250_37564": "State/Province",
  "I1_8087;28_31248;28_31243": "Add Driver",
  "I1_8154;30_32137": "Cancel",
  "1_8120": "Phone Number",
  "I1_8170;23_39084": "Vehicle Management",
  "I1_8108;75_53807": "Last Name",
  "155_30160": "Middle Name",
  "I1_8132;884_102263": "Password",
  "I1_8124;75_53807": "Phone Number",
  "1_8100": "First Name",
  "I1_8102;75_53807": "First Name",
  "1_8159": "5",
  "I155_30162;75_53807": "Middle Name",
  "I1_8143;75_53807": "Address 1",
  "I1_8149;250_37564": "Country",
  "1_8152": "Company Information",
  "1_8135": "*",
  "I1_8183;23_39072": "Approval",
  "1_8113": "*",
  "I1_8176;21_27541": "Passenger Management",
  "I1_8188;23_39072": "Notification",
  "I289_32311;289_32078": "Upload",
  "I1_8087;28_31263;28_31261": ">",
  "I1_8087;28_31246;28_31236": "Passenger Management",
  "1_8099": "*",
  "I1_8181;23_39072": "Work Management",
  "1_8136": "Confirm Password",
  "1_8130": "Password",
  "I1_8155;30_32140": "Next",
  "1_8161": "Client Portal",
  "I1_8088;35_49503": "Add Passenger",
  "I1_8138;884_102263": "Confirm Password",
  "289_32319": "Photo",
  "1_8114": "Email",
  "I1_8145;75_53807": "City",
  "I1_8213;185_68545": "First Name",
  "I1_8261;23_39072": "Company setting",
  "I1_8266;23_39084": "Vehicle Management",
  "I1_8200;758_37470;758_37454": "Passenger Evaluation",
  "1_8257": "Client Portal",
  "I1_8243;185_68545": "City",
  "I155_30183;185_68545": "Middle Name",
  "I1_8272;21_27541": "Passenger Management",
  "I1_8196;28_31246;28_31236": "Passenger Management",
  "1_8217": "Last Name",
  "I289_32362;289_32078": "Upload",
  "1_8275": "Chauffeur Connect",
  "I1_8241;185_68545": "Address 1",
  "I1_8219;185_68545": "Last Name",
  "I1_8277;23_39072": "Work Management",
  "I1_8279;23_39072": "Approval",
  "I1_8271;21_22137": "Driver Management",
  "1_8224": "*",
  "I1_8247;250_37580": "Country",
  "1_8216": "*",
  "I1_8196;28_31248;28_31243": "View Passenger",
  "1_8238": "Address",
  "1_8204": "Basic Information",
  "155_30181": "Middle Name",
  "289_32370": "Photo",
  "1_8230": "*",
  "I1_8259;21_22137": "Top",
  "I1_8245;250_37580": "State/Province",
  "I1_8251;30_32137": "Cancel",
  "1_8225": "Email",
  "I1_8200;758_37469;758_37460": "Passenger Information",
  "I1_8196;28_31263;28_31261": ">",
  "I1_8227;185_68545": "Email",
  "1_8255": "5",
  "155_30180": "*",
  "I1_8198;35_49503": "View Passenger",
  "I1_8234;956_83014": "Phone code",
  "I1_8242;185_68545": "Address 2",
  "I1_8246;185_68545": "Zip/ Postal Code",
  "I1_8235;185_68545": "Phone Number",
  "1_8231": "Phone Number",
  "1_8211": "First Name",
  "I1_8284;23_39072": "Notification",
  "1_8250": "Company Information",
  "1_8210": "*",
  "1_8340": "*",
  "I1_8321;75_53807": "Email",
  "1_8381": "Client Portal",
  "I1_8350;75_53807": "City",
  "1_8318": "*",
  "I1_8396;21_27541": "Passenger Management",
  "I1_8375;30_32140": "Next",
  "I1_8293;35_49503": "Add Passenger",
  "1_8298": "Basic Information",
  "1_8369": "*",
  "I1_8366;75_53807": "Employee Number",
  "I155_30169;75_53807": "Middle Name",
  "1_8335": "Password",
  "155_30166": "*",
  "I1_8374;30_32137": "Cancel",
  "I1_8307;75_53807": "First Name",
  "1_8325": "Phone Number",
  "1_8363": "*",
  "289_32332": "Photo",
  "I1_8352;250_37564": "State/Province",
  "I1_8354;250_37564": "Country",
  "I1_8395;21_22137": "Driver Management",
  "I1_8408;23_39072": "Notification",
  "1_8319": "Email",
  "155_30167": "Middle Name",
  "1_8310": "*",
  "1_8324": "*",
  "I1_8292;28_31246;28_31236": "Passenger Management",
  "I1_8401;23_39072": "Work Management",
  "1_8304": "*",
  "1_8305": "First Name",
  "1_8345": "Address",
  "1_8358": "Company Information",
  "I1_8329;75_53807": "Phone Number",
  "1_8399": "Chauffeur Connect",
  "I289_32343;289_32078": "Upload",
  "I1_8313;75_53807": "Last Name",
  "1_8364": "Employee Number",
  "1_8379": "5",
  "I1_8292;28_31263;28_31261": ">",
  "I1_8343;884_102263": "Confirm Password",
  "I1_8385;23_39072": "Company setting",
  "I1_8328;956_83002": "Phone code",
  "1_8334": "*",
  "I1_8349;75_53807": "Address 2",
  "1_8370": "Select Department",
  "1_8311": "Last Name",
  "I1_8372;75_53865;250_37564": "Select Department",
  "I1_8337;884_102263": "Password",
  "I1_8390;23_39084": "Vehicle Management",
  "I1_8292;28_31248;28_31243": "Add Passenger ",
  "I1_8383;21_22137": "Top",
  "I1_8403;23_39072": "Approval",
  "I1_8348;75_53807": "Address 1",
  "1_8341": "Confirm Password",
  "I1_8353;75_53807": "Zip/ Postal Code",
  "1_8450": "Phone Number",
  "1_8470": "Company Information",
  "I1_8461;185_68545": "Address 2",
  "1_8476": "Employee Number",
  "1_8491": "Client Portal",
  "1_8423": "Basic Information",
  "I1_8518;23_39072": "Notification",
  "I1_8506;21_27541": "Passenger Management",
  "1_8481": "*",
  "155_30187": "*",
  "I1_8465;185_68545": "Zip/ Postal Code",
  "I1_8493;21_22137": "Top",
  "I1_8446;185_68545": "Email",
  "1_8430": "First Name",
  "I1_8416;28_31248;28_31243": "View Passenger ",
  "I1_8453;956_83014": "Phone code",
  "I1_8416;28_31263;28_31261": ">",
  "I1_8484;75_53865;250_37580": "Select Department",
  "1_8443": "*",
  "1_8457": "Address",
  "I289_32394;289_32078": "Upload",
  "I1_8419;758_37470;758_37454": "Passenger Evaluation",
  "I155_30190;185_68545": "Middle Name",
  "I1_8438;185_68545": "Last Name",
  "I1_8419;758_37469;758_37460": "Passenger Information",
  "1_8435": "*",
  "I1_8485;30_32137": "Cancel",
  "I1_8505;21_22137": "Driver Management",
  "1_8475": "*",
  "I1_8513;23_39072": "Approval",
  "I1_8416;28_31246;28_31236": "Passenger Management",
  "I1_8500;23_39084": "Vehicle Management",
  "1_8509": "Chauffeur Connect",
  "1_8449": "*",
  "I1_8417;35_49503": "View Passenger",
  "I1_8478;185_68545": "Employee Number",
  "I1_8460;185_68545": "Address 1",
  "155_30188": "Middle Name",
  "1_8489": "5",
  "I1_8495;23_39072": "Company setting",
  "1_8429": "*",
  "1_8444": "Email",
  "I1_8462;185_68545": "City",
  "I1_8466;250_37580": "Country",
  "I1_8464;250_37580": "State/Province",
  "1_8482": "Select Department",
  "1_8436": "Last Name",
  "I1_8511;23_39072": "Work Management",
  "I1_8432;185_68545": "First Name",
  "289_32383": "Photo",
  "I1_8454;185_68545": "Phone Number",
  "I1_8607;75_53865;250_37580": "Select Department",
  "1_8604": "*",
  "1_8559": "*",
  "I155_30176;185_68545": "Middle Name",
  "I1_8585;185_68545": "City",
  "1_8545": "*",
  "1_8598": "*",
  "1_8575": "*",
  "1_8593": "Company Information",
  "I1_8609;30_32137": "Back",
  "I1_8638;23_39072": "Approval",
  "1_8540": "First Name",
  "I1_8542;185_68545": "First Name",
  "I1_8601;185_68545": "Employee Number",
  "I1_8636;23_39072": "Work Management",
  "I1_8563;956_83014": "Phone code",
  "1_8576": "Confirm Password",
  "1_8605": "Select Department",
  "I1_8610;30_32140": "Save",
  "155_30174": "Middle Name",
  "1_8634": "Chauffeur Connect",
  "1_8533": "Basic Information",
  "1_8570": "Password",
  "I1_8589;250_37580": "Country",
  "I1_8578;884_102319": "Confirm Password",
  "1_8580": "Address",
  "1_8539": "*",
  "I1_8556;185_68545": "Email",
  "I1_8564;185_68545": "Phone Number",
  "1_8546": "Last Name",
  "I1_8526;35_49552;28_31243": "Preview Passenger",
  "I1_8526;35_49549;28_31261": ">",
  "I1_8548;185_68545": "Last Name",
  "I1_8528;35_49503": "Preview Passenger",
  "1_8560": "Phone Number",
  "I1_8572;884_102319": "Password",
  "1_8616": "Client Portal",
  "I1_8620;23_39072": "Company setting",
  "1_8553": "*",
  "I1_8631;21_27541": "Passenger Management",
  "I289_32194;289_32078": "Upload",
  "1_8599": "Employee Number",
  "I1_8625;23_39084": "Vehicle Management",
  "I1_8583;185_68545": "Address 1",
  "155_30173": "*",
  "I1_8584;185_68545": "Address 2",
  "I1_8643;23_39072": "Notification",
  "1_8614": "5",
  "I1_8526;35_49548;28_31236": "Passenger Management",
  "289_32351": "Photo",
  "I1_8526;35_49551;28_31261": ">",
  "1_8554": "Email",
  "I1_8588;185_68545": "Zip/ Postal Code",
  "I1_8630;21_22137": "Driver Management",
  "1_8569": "*",
  "I1_8618;21_22137": "Top",
  "I1_8526;35_49550;28_31236": "Add Passenger",
  "I1_8587;250_37580": "State/Province",
  "1_8694": "99",
  "I1_8775;21_22137": "Driver Management",
  "1_8665": "uploadFile",
  "1_8686": "Business Activities",
  "1_8697": "Average Rating",
  "I1_8753;581_1954;7882_19131;497_44116": "10 / page",
  "I1_8788;23_39072": "Notification",
  "1_8729": "Jinny Oslin",
  "1_8722": "0",
  "1_8669": "Passenger  Department",
  "1_8683": "Activity Cancellations",
  "1_8667": "Passenger Name",
  "1_8730": "A day ago",
  "1_8759": "5",
  "I1_8753;581_1954;7882_22097;433_34538": "4",
  "1_8752": "Magna  id sint iren in cillum ese nisi dolor laboris",
  "1_8656": "View Passenger",
  "1_8740": "A day ago",
  "1_8749": "Jinny Oslin",
  "1_8653": "Passenger Management",
  "I1_8753;581_1954;7882_26229;433_34538": "10",
  "I1_8783;23_39072": "Approval",
  "1_8690": "0",
  "I1_8753;581_1954;7882_21971;433_34538": "3",
  "1_8681": "2",
  "1_8684": "2",
  "1_8687": "2h",
  "1_8680": "Total Activities",
  "I1_8753;581_1954;7882_21473;497_44116": "1",
  "I1_8753;581_1954;7882_21853;433_34538": "2",
  "I1_8654;28_31261": ">",
  "1_8720": "1 Star",
  "1_8699": "4.5",
  "I1_8663;758_37671;758_37460": "Passenger Evaluation",
  "1_8710": "30",
  "1_8670": "Employee Number",
  "I1_8753;581_1954;7882_26160;433_34538": "...",
  "1_8732": "Magna  id sint iren in cillum ese nisi dolor laboris",
  "I1_8781;23_39072": "Work Management",
  "I1_8776;21_27541": "Passenger Management",
  "1_8742": "Magna  id sint iren in cillum ese nisi dolor laboris",
  "1_8779": "Chauffeur Connect",
  "1_8739": "Jinny Oslin",
  "I1_8753;581_1954;7882_26275;433_34538": "11",
  "I1_8765;23_39072": "Company setting",
  "1_8704": "5 Star",
  "1_8675": "Evaluations",
  "1_8708": "4 Star",
  "1_8689": "Personal Activities",
  "1_8750": "A day ago",
  "I1_8663;758_37670;758_37454": "Passenger Information",
  "1_8714": "10",
  "1_8661": "View Passenger",
  "I1_8753;368_110840": "Show 1 to 10 of 100 results",
  "1_8716": "2 Star",
  "I1_8755;30_32137": "Cancel",
  "1_8718": "9",
  "1_8706": "50",
  "I1_8676;497_44890": "All time",
  "1_8712": "3 Star",
  "1_8693": "Total Reviews",
  "I1_8770;23_39084": "Vehicle Management",
  "I1_8763;21_22137": "Top",
  "1_8761": "Client Portal",
  "1_8816": "Last Name",
  "I1_8852;30_32140": "Next",
  "I1_8873;21_27541": "Passenger Management",
  "I1_8844;250_37573": "State/Province",
  "1_8809": "*",
  "1_8815": "*",
  "1_8876": "Chauffeur Connect",
  "I1_8833;956_83011": "Phone code",
  "155_30195": "*",
  "I1_8841;121_58638": "Address 2",
  "155_30196": "Middle Name",
  "1_8824": "Email",
  "I289_32147;289_32078": "Upload",
  "I1_8796;28_31248;28_31243": "Edit Passenger",
  "I1_8845;121_58638": "ZIP/Postal Code",
  "I1_8880;23_39072": "Approval",
  "1_8837": "Address",
  "1_8803": "Basic Information",
  "I1_8826;121_58638": "Email",
  "I1_8872;21_22137": "Driver Management",
  "I1_8818;121_58638": "Last Name",
  "I1_8812;121_58638": "First Name",
  "289_32402": "Photo",
  "I1_8878;23_39072": "Work Management",
  "I1_8867;23_39084": "Vehicle Management",
  "I1_8796;28_31263;28_31261": ">",
  "1_8810": "First Name",
  "1_8823": "*",
  "I1_8796;28_31246;28_31236": "Passenger Management",
  "I1_8834;121_58638": "Phone Number",
  "1_8849": "Company Information",
  "1_8830": "Phone Number",
  "I1_8862;23_39072": "Company setting",
  "I1_8860;21_22137": "Top",
  "I155_30198;121_58638": "Middle Name",
  "I1_8798;35_49503": "Edit Passenger",
  "I1_8851;30_32137": "Cancel",
  "1_8829": "*",
  "1_8856": "5",
  "1_8858": "Client Portal",
  "I1_8842;121_58638": "City",
  "I1_8885;23_39072": "Notification",
  "I1_8840;121_58638": "Address 1",
  "I1_8846;250_37573": "Country",
  "1_8907": "First Name",
  "I1_8955;121_58638": "Employee Number",
  "1_8952": "*",
  "I1_8930;956_83011": "Phone code",
  "289_32421": "Photo",
  "I1_8893;28_31246;28_31236": "Passenger Management",
  "I1_8972;21_22137": "Top",
  "155_30202": "*",
  "1_8900": "Basic Information",
  "I1_8939;121_58638": "City",
  "I1_8941;250_37573": "State/Province",
  "I1_8997;23_39072": "Notification",
  "1_8947": "Company Information",
  "1_8968": "5",
  "I289_32432;289_32078": "Upload",
  "1_8913": "Last Name",
  "I1_8963;30_32137": "Cancel",
  "I1_8893;28_31248;28_31243": "Edit Passenger ",
  "I1_8961;75_53865;250_37573": "Select Department",
  "I1_8992;23_39072": "Approval",
  "1_8970": "Client Portal",
  "I1_8974;23_39072": "Company setting",
  "I1_8990;23_39072": "Work Management",
  "1_8906": "*",
  "1_8926": "*",
  "I1_8942;121_58638": "ZIP/Postal Code",
  "I1_8895;35_49503": "Edit Passenger",
  "1_8921": "Email",
  "155_30203": "Middle Name",
  "1_8927": "Phone Number",
  "I1_8937;121_58638": "Address 1",
  "I1_8938;121_58638": "Address 2",
  "I1_8931;121_58638": "Phone Number",
  "I1_8979;23_39084": "Vehicle Management",
  "1_8953": "Employee Number",
  "I1_8909;121_58638": "First Name",
  "I155_30205;121_58638": "Middle Name",
  "1_8988": "Chauffeur Connect",
  "I1_8984;21_22137": "Driver Management",
  "1_8959": "Select Department",
  "I1_8923;121_58638": "Email",
  "I1_8964;30_32140": "Next",
  "1_8934": "Address",
  "I1_8985;21_27541": "Passenger Management",
  "I1_8943;250_37573": "Country",
  "1_8958": "*",
  "I1_8915;121_58638": "Last Name",
  "1_8920": "*",
  "I1_8893;28_31263;28_31261": ">",
  "1_8912": "*",
  "I1_9005;35_49550;28_31236": "Edit Passenger ",
  "I1_9104;23_39084": "Vehicle Management",
  "I1_9097;21_22137": "Top",
  "I1_9117;23_39072": "Approval",
  "I1_9021;185_68545": "First Name",
  "I1_9110;21_27541": "Passenger Management",
  "1_9032": "*",
  "I1_9099;23_39072": "Company setting",
  "I155_30212;185_68545": "Middle Name",
  "I1_9043;185_68545": "Phone Number",
  "1_9083": "*",
  "I1_9086;75_53865;250_37580": "Select Department",
  "1_9012": "Basic Information",
  "1_9048": "*",
  "1_9025": "Last Name",
  "I1_9115;23_39072": "Work Management",
  "I1_9122;23_39072": "Notification",
  "1_9077": "*",
  "1_9049": "Password",
  "I1_9005;35_49549;28_31261": ">",
  "1_9113": "Chauffeur Connect",
  "I1_9063;185_68545": "Address 2",
  "I1_9089;30_32140": "Update",
  "I1_9067;185_68545": "Zip/ Postal Code",
  "1_9055": "Confirm Password",
  "1_9095": "Client Portal",
  "I1_9066;250_37580": "State/Province",
  "I1_9035;185_68545": "Email",
  "I289_32451;289_32078": "Upload",
  "I1_9080;185_68545": "Employee Number",
  "1_9059": "Address",
  "I1_9027;185_68545": "Last Name",
  "I1_9042;956_83014": "Phone code",
  "I1_9062;185_68545": "Address 1",
  "1_9084": "Select Department",
  "155_30210": "Middle Name",
  "1_9039": "Phone Number",
  "1_9078": "Employee Number",
  "I1_9007;35_49503": "Preview Passenger",
  "155_30209": "*",
  "1_9072": "Company Information",
  "1_9038": "*",
  "1_9093": "5",
  "I1_9068;250_37580": "Country",
  "I1_9109;21_22137": "Driver Management",
  "1_9019": "First Name",
  "1_9018": "*",
  "1_9033": "Email",
  "1_9024": "*",
  "I1_9005;35_49552;28_31243": "Preview Passenger",
  "289_32440": "Photo",
  "I1_9051;884_102319": "Password",
  "I1_9088;30_32137": "Back",
  "I1_9005;35_49551;28_31261": ">",
  "I1_9064;185_68545": "City",
  "I1_9057;884_102319": "Confirm Password",
  "1_9054": "*",
  "I1_9005;35_49548;28_31236": "Passenger Management",
  "I1_9165;30_32140": "Search",
  "1_9201": "Chauffeur Connect",
  "1_9183": "Client Portal",
  "1_9153": "*",
  "I1_9164;185_68545": "Select Activity",
  "1_9141": "*",
  "I1_9144;7696_54949": "Select Date",
  "1_9147": "*",
  "1_9142": "Date",
  "I1_9177;30_32137": "Cancel",
  "289_32493": "uploadFile",
  "I1_9198;21_22137": "Passenger Management",
  "I1_9150;75_53865;250_37580": "Select Expense Category",
  "I1_9158;30_32140": "Search",
  "1_9181": "5",
  "I1_9197;21_22137": "Driver Management",
  "I1_9192;23_39084": "Vehicle Management",
  "I1_9204;23_39084": "Work Management",
  "289_32491": "Photo",
  "I1_9135;35_49548;28_31236": "Work Management",
  "1_9148": "Expense Category",
  "I1_9157;185_68545": "Select Payer",
  "I1_9187;23_39072": "Company setting",
  "I1_9175;7700_22673;185_68601": "Description",
  "1_9161": "Which activity is this expense for",
  "1_9154": "Paid by",
  "I1_9207;250_58566": "Expense Category",
  "I1_9135;35_49549;28_31261": ">",
  "I1_9185;21_22137": "Top",
  "I1_9172;185_68545": "Enter Amount",
  "1_9168": "*",
  "I1_9175;7700_22671": "Description",
  "1_9169": "Expense Amount",
  "I1_9209;23_39072": "Approval",
  "I1_9135;35_49552;28_31243": "View Report",
  "I1_9135;35_49550;28_31236": "Report List",
  "I1_9206;250_58575": "Report List",
  "I1_9136;35_49503": "View Expense Report",
  "I1_9214;23_39072": "Notification",
  "I1_9135;35_49551;28_31261": ">",
  "I1_9261;121_58638": "Enter Amount",
  "I1_9301;23_39084": "Work Management",
  "1_9258": "Expense Amount",
  "1_9278": "5",
  "1_9236": "*",
  "I1_9247;30_32140": "Search",
  "I1_9306;23_39072": "Approval",
  "1_9280": "Client Portal",
  "I1_9253;121_58638": "Select Activity",
  "I1_9264;7700_22671": "Description",
  "I1_9294;21_22137": "Driver Management",
  "I1_9273;30_32137": "Cancel",
  "1_9298": "Chauffeur Connect",
  "I1_9239;75_53865;250_37573": "Select Expense Category",
  "I1_9233;7696_54945": "Select Date",
  "I1_9304;250_58566": "Expense Category",
  "1_9230": "*",
  "1_9270": "uploadFile",
  "I1_9274;30_32140": "Update",
  "I1_9264;7700_22673;250_58177": "Description",
  "I1_9224;35_49552;28_31243": "Edit Expense Report",
  "1_9237": "Expense Category",
  "I1_9254;30_32140": "Search",
  "I1_9303;250_58575": "Report List",
  "1_9243": "Paid by",
  "1_9257": "*",
  "I1_9295;21_22137": "Passenger Management",
  "I1_9284;23_39072": "Company setting",
  "I1_9224;35_49550;28_31236": "Report List",
  "I1_9224;35_49551;28_31261": ">",
  "I1_9246;121_58638": "Select Payer",
  "I1_9282;21_22137": "Top",
  "1_9242": "*",
  "I1_9289;23_39084": "Vehicle Management",
  "1_9231": "Date",
  "I1_9224;35_49549;28_31261": ">",
  "I1_9224;35_49548;28_31236": "Work Management",
  "I1_9225;35_49503": "Edit Expense Report",
  "1_9267": "Photo",
  "1_9250": "Which activity is this expense for",
  "I1_9311;23_39072": "Notification",
  "I1_9344;75_53865;250_37580": "Select Time",
  "I1_9363;23_39072": "Company setting",
  "1_9327": "*",
  "I1_9338;7696_54949": "Select Date",
  "I1_9390;23_39072": "Notification",
  "1_9342": "From",
  "I1_9374;21_22137": "Passenger Management",
  "I1_9332;30_32140": "Search",
  "I1_9353;30_32137": "Cancel",
  "1_9357": "5",
  "I1_9321;35_49550;28_31236": "Report List",
  "I1_9382;250_58575": "Report List",
  "I1_9321;35_49548;28_31236": "Work Management",
  "I1_9321;35_49551;28_31261": ">",
  "1_9328": "Absentee",
  "I1_9321;35_49549;28_31261": ">",
  "1_9336": "Date",
  "I1_9350;75_53865;250_37580": "Select Time",
  "1_9347": "*",
  "I1_9351;154_61648;185_68545": "Reason",
  "I1_9322;35_49503": "View Absence Report",
  "1_9348": "To",
  "289_32508": "uploadFile",
  "1_9377": "Chauffeur Connect",
  "I1_9368;23_39084": "Vehicle Management",
  "I1_9380;23_39084": "Work Management",
  "I1_9361;21_22137": "Top",
  "I1_9321;35_49552;28_31243": "View Report",
  "1_9341": "*",
  "1_9335": "*",
  "I1_9385;23_39072": "Approval",
  "I1_9331;185_68545": "Select Absentee",
  "289_32506": "Photo",
  "I1_9383;250_58566": "Expense Category",
  "I1_9351;154_61647": "Reason",
  "I1_9373;21_22137": "Driver Management",
  "1_9359": "Client Portal",
  "I1_9401;35_49503": "Edit Absence Report",
  "1_9427": "To",
  "I1_9469;250_58575": "Report List",
  "1_9414": "* ",
  "1_9464": "Chauffeur Connect",
  "I1_9400;35_49551;28_31261": ">",
  "1_9421": "From",
  "I1_9430;154_61648;121_58638": "Name",
  "I1_9430;154_61647": "Reason",
  "I1_9400;35_49550;28_31236": "Report List",
  "I1_9440;30_32140": "Update",
  "I1_9400;35_49552;28_31243": "Edit Absence Report",
  "I1_9411;30_32140": "Search",
  "I1_9417;7696_54945": "Select Date",
  "289_32514": "uploadFile",
  "I1_9423;75_53865;250_37573": "Select Time",
  "289_32511": "Photo",
  "1_9446": "Client Portal",
  "1_9406": "*",
  "1_9415": "Date",
  "I1_9460;21_22137": "Driver Management",
  "1_9426": "*",
  "I1_9400;35_49549;28_31261": ">",
  "I1_9448;21_22137": "Top",
  "I1_9450;23_39072": "Company setting",
  "I1_9400;35_49548;28_31236": "Work Management",
  "1_9420": "*",
  "I1_9477;23_39072": "Notification",
  "I1_9472;23_39072": "Approval",
  "I1_9429;75_53865;250_37573": "Select Time",
  "I1_9467;23_39084": "Work Management",
  "1_9444": "5",
  "I1_9410;121_58638": "Name",
  "I1_9455;23_39084": "Vehicle Management",
  "I1_9461;21_22137": "Passenger Management",
  "1_9407": "Absentee",
  "I1_9470;250_58566": "Expense Category",
  "I1_9439;30_32137": "Cancel",
  "I1_9519;581_1954;7882_21473;497_44116": "1",
  "I1_9551;23_39072": "Approval",
  "I1_9519;581_1954;7882_21853;433_34538": "2",
  "I1_9534;23_39072": "Vehicle Management",
  "1_9543": "Chauffeur Connect",
  "I1_9499;835_98906": "Gasoline Fee",
  "I1_9539;21_22137": "Driver Management",
  "I1_9556;23_39072": "Notification",
  "I1_9487;28_31248;28_31243": "Expense Category",
  "I1_9492;121_59079": "Search...",
  "I1_9487;28_31246;28_31236": "Work Management",
  "I1_9546;23_39084": "Work Management",
  "I1_9488;388_23148;30_32140": "Add",
  "I1_9500;18_21709":
    "Lorem ipsum dolor sit amet consectetur. Sed amet leo luctus nibh. Vestibulum urna tellus sed ultrices morbi nisl amet nam.",
  "I1_9514;18_21709":
    "Lorem ipsum dolor sit amet consectetur. Viverra vitae donec urna dictum. Et dignissim malesuada rhoncus vel.",
  "I1_9496;171_66633": "Description",
  "1_9510": "Action",
  "I1_9506;835_98906": "Toll Fee",
  "I1_9519;581_1954;7882_22097;433_34538": "4",
  "I1_9549;250_58575": "Expense Category",
  "I1_9519;581_1954;7882_26275;433_34538": "11",
  "I1_9513;835_98906": "Other",
  "I1_9487;28_31263;28_31261": ">",
  "I1_9529;23_39072": "Company setting",
  "I1_9507;18_21709":
    "Lorem ipsum dolor sit amet consectetur. Et scelerisque cras dolor adipiscing morbi. Scelerisque facilisis scelerisque nisl arcu sed magna orci ornare.",
  "I1_9495;171_66633": "Expense Category",
  "I1_9519;368_110840": "Show 1 to 10 of 100 results",
  "I1_9540;21_22137": "Passenger Management",
  "1_9503": "Action",
  "I1_9519;581_1954;7882_26160;433_34538": "...",
  "1_9523": "5",
  "I1_9527;21_22137": "Top",
  "I1_9548;250_58566": "Report List",
  "I1_9488;388_23101": "Expense Category",
  "I1_9497;171_66633": "Action",
  "I1_9519;581_1954;7882_26229;433_34538": "10",
  "I1_9519;581_1954;7882_19131;497_44116": "10 / page",
  "I1_9519;581_1954;7882_21971;433_34538": "3",
  "1_9525": "Client Portal",
  "1_9517": "Action",
  "I1_9606;18_21709": "16/01/2024",
  "I1_9639;23_39084": "Vehicle Management",
  "I1_9624;581_1954;7882_26160;433_34538": "...",
  "I1_9593;18_21709": "Expense Report",
  "I1_9651;23_39084": "Work Management",
  "I1_9566;28_31246;28_31236": "Work Management",
  "I1_9592;18_21709": "Administration department",
  "I1_9632;21_22137": "Top",
  "I1_9617;18_21709": "Absence Report",
  "I1_9645;21_22137": "Passenger Management",
  "I1_9607;33_33258;33_33254": "Approved",
  "I1_9654;250_58566": "Expense Category",
  "1_9610": "Action",
  "I1_9653;250_58575": "Report List",
  "I1_9624;581_1954;7882_21473;497_44116": "1",
  "I1_9566;28_31263;28_31261": ">",
  "I1_9567;388_23148;30_32140": "Add",
  "I1_9583;171_66633": "Department",
  "1_9628": "5",
  "I1_9604;18_21709": "Administration department",
  "I1_9661;23_39072": "Notification",
  "I1_9572;433_34538": "Report Type",
  "I1_9656;23_39072": "Approval",
  "I1_9644;21_22137": "Driver Management",
  "I1_9584;171_66633": "Report Type",
  "I1_9616;18_21709": "Driver department",
  "I1_9624;368_110840": "Show 1 to 10 of 100 results",
  "I1_9594;18_21709": "16/01/2024",
  "I1_9603;18_21709": "Eric Foo",
  "I1_9582;171_66633": "Created by",
  "1_9630": "Client Portal",
  "I1_9624;581_1954;7882_21853;433_34538": "2",
  "I1_9591;18_21709": "Adam bean",
  "I1_9618;18_21709": "16/01/2024",
  "I1_9585;171_66633": "Created Date",
  "I1_9587;171_66633": "Action",
  "I1_9595;33_33258;33_33254": "Pending",
  "1_9574": "Start Date - End Date",
  "I1_9590;835_98906": "39",
  "I1_9624;581_1954;7882_19131;497_44116": "10 / page",
  "I1_9624;581_1954;7882_26229;433_34538": "10",
  "I1_9576;433_34538": "Status",
  "I1_9605;18_21709": "Expense Report",
  "1_9648": "Chauffeur Connect",
  "I1_9624;581_1954;7882_26275;433_34538": "11",
  "I1_9577;121_59079": "Search...",
  "I1_9602;835_98906": "76",
  "I1_9624;581_1954;7882_22097;433_34538": "4",
  "I1_9614;835_98906": "69",
  "I1_9571;30_32021": "Export",
  "1_9598": "Action",
  "I1_9566;28_31248;28_31243": "Report List",
  "I1_9634;23_39072": "Company setting",
  "I1_9581;171_66633": "Report ID",
  "1_9622": "Action",
  "I1_9586;171_66633": "Status",
  "I1_9615;18_21709": "Grey Ho",
  "I1_9619;33_33258;33_33254": "Disapproved",
  "I1_9567;388_23101": "Report List",
  "I1_9624;581_1954;7882_21971;433_34538": "3",
  "1_9710": "Chauffeur Connect",
  "1_9679": "*",
  "I1_9706;21_22137": "Driver Management",
  "1_9680": "Expense Category",
  "1_9690": "5",
  "I1_9682;75_53807": "Expense Category",
  "I1_9673;35_49551;28_31261": ">",
  "I1_9683;7700_22673;154_61744": "Description",
  "I1_9723;23_39072": "Notification",
  "I1_9696;23_39072": "Company setting",
  "I1_9716;250_58575": "Expense Category",
  "I1_9674;35_49503": "Add Expense Category",
  "I1_9707;21_22137": "Passenger Management",
  "I1_9673;35_49548;28_31236": "Work Management",
  "I1_9718;23_39072": "Approval",
  "I1_9701;23_39072": "Vehicle Management",
  "I1_9673;35_49552;28_31243": "Add Expense Category",
  "1_9692": "Client Portal",
  "I1_9713;23_39084": "Work Management",
  "I1_9683;7700_22671": "Description",
  "I1_9715;250_58566": "Report List",
  "I1_9694;21_22137": "Top",
  "I1_9685;30_32137": "Cancel",
  "I1_9673;35_49549;28_31261": ">",
  "I1_9686;30_32140": "Save",
  "I1_9673;35_49550;28_31236": "Expense Category",
  "I1_9775;23_39072": "Approval",
  "I1_9772;250_58566": "Report List",
  "I1_9770;23_39084": "Work Management",
  "1_9767": "Chauffeur Connect",
  "I1_9734;35_49503": "View Expense Category",
  "I1_9743;7700_22673;185_68601": "Description",
  "I1_9758;23_39072": "Vehicle Management",
  "I1_9733;35_49548;28_31236": "Work Management",
  "I1_9763;21_22137": "Driver Management",
  "1_9740": "Expense Category",
  "1_9747": "5",
  "I1_9733;35_49552;28_31243": "View Expense Category",
  "1_9749": "Client Portal",
  "I1_9743;7700_22671": "Description",
  "I1_9764;21_22137": "Passenger Management",
  "1_9739": "*",
  "I1_9773;250_58575": "Expense Category",
  "I1_9733;35_49551;28_31261": ">",
  "I1_9780;23_39072": "Notification",
  "I1_9742;185_68545": "Expense Category",
  "I1_9733;35_49550;28_31236": "Expense Category",
  "I1_9753;23_39072": "Company setting",
  "I1_9733;35_49549;28_31261": ">",
  "I1_9751;21_22137": "Top",
  "1_9796": "*",
  "1_9797": "Expense Category",
  "1_9805": "5",
  "I1_9816;23_39072": "Vehicle Management",
  "I1_9821;21_22137": "Driver Management",
  "I1_9833;23_39072": "Approval",
  "I1_9790;35_49549;28_31261": ">",
  "I1_9831;250_58575": "Expense Category",
  "I1_9828;23_39084": "Work Management",
  "I1_9790;35_49548;28_31236": "Work Management",
  "I1_9790;35_49552;28_31243": "Edit Expense Category",
  "I1_9800;7700_22673;250_58177": "Description",
  "I1_9822;21_22137": "Passenger Management",
  "I1_9801;30_32140": "Update",
  "I1_9799;121_58638": "Expense Category",
  "I1_9791;35_49503": "Edit Expense Category",
  "I1_9811;23_39072": "Company setting",
  "I1_9830;250_58566": "Report List",
  "I1_9809;21_22137": "Top",
  "I1_9790;35_49550;28_31236": "Expense Category",
  "I1_9838;23_39072": "Notification",
  "I1_9790;35_49551;28_31261": ">",
  "1_9807": "Client Portal",
  "I1_9800;7700_22671": "Description",
  "1_9825": "Chauffeur Connect",
  "I1_9919;23_39084": "Work Management",
  "1_9896": "5",
  "I1_9885;75_53807": "Enter Amount",
  "1_9916": "Chauffeur Connect",
  "1_9867": "Paid by",
  "I1_9848;35_49550;28_31236": "Report List",
  "I1_9907;23_39084": "Vehicle Management",
  "1_9866": "*",
  "1_9855": "Date",
  "1_9898": "Client Portal",
  "I1_9892;30_32140": "Save",
  "I289_32470;289_32078": "Upload",
  "I1_9848;35_49551;28_31261": ">",
  "I1_9912;21_22137": "Driver Management",
  "289_32478": "Photo",
  "I1_9849;35_49503": "Add Expense Report",
  "I1_9878;30_32140": "Search",
  "1_9882": "Expense Amount",
  "1_9854": "*",
  "I1_9888;7700_22671": "Description",
  "I1_9848;35_49549;28_31261": ">",
  "I1_9913;21_22137": "Passenger Management",
  "I1_9848;35_49552;28_31243": "Add Expense Report",
  "I1_9929;23_39072": "Notification",
  "1_9874": "Which activity is this expense for",
  "I1_9921;250_58575": "Report List",
  "I1_9870;75_53807": "Select Payer",
  "I1_9848;35_49548;28_31236": "Work Management",
  "1_9861": "Expense Category",
  "I1_9877;75_53807": "Select Activity",
  "I1_9900;21_22137": "Top",
  "I1_9888;7700_22673;154_61744": "Description",
  "I1_9871;30_32140": "Search",
  "I1_9902;23_39072": "Company setting",
  "I1_9863;75_53865;250_37564": "Select Expense Category",
  "1_9881": "*",
  "I1_9891;30_32137": "Cancel",
  "1_9860": "*",
  "I1_9922;250_58566": "Expense Category",
  "I1_9924;23_39072": "Approval",
  "I1_9857;7696_54752": "Select Date",
  "I1_10009;23_39072": "Notification",
  "I1_9993;21_22137": "Passenger Management",
  "I1_9982;23_39072": "Company setting",
  "I1_9968;154_61647": "Reason",
  "I1_9939;35_49552;28_31243": "Add Absence Report",
  "I1_10001;250_58575": "Report List",
  "I1_9939;35_49548;28_31236": "Work Management",
  "I1_10002;250_58566": "Expense Category",
  "I1_9967;75_53865;250_37573": "Select Time",
  "1_9945": "*",
  "I289_32498;289_32078": "Upload",
  "I1_10004;23_39072": "Approval",
  "I1_9968;154_61648;75_53807": "Reason",
  "I1_9939;35_49551;28_31261": ">",
  "I1_9972;30_32140": "Save",
  "I1_9939;35_49549;28_31261": ">",
  "289_32496": "Photo",
  "1_9958": "*",
  "1_9946": "Absentee",
  "1_9965": "To",
  "I1_9939;35_49550;28_31236": "Report List",
  "1_9996": "Chauffeur Connect",
  "1_9959": "From",
  "I1_9999;23_39084": "Work Management",
  "I1_9955;7696_54752": "Select Date",
  "1_9964": "*",
  "I1_9949;75_53807": "Select Absentee",
  "I1_9987;23_39084": "Vehicle Management",
  "I1_9971;30_32137": "Cancel",
  "I1_9961;75_53865;250_37573": "Select Time",
  "I1_9940;35_49503": "Add Absence Report",
  "I1_9992;21_22137": "Driver Management",
  "I1_9980;21_22137": "Top",
  "I1_9950;30_32140": "Search",
  "1_9976": "5",
  "1_9978": "Client Portal",
  "1_9953": "* Date",
  "277_34814": "Vehicle can only be set to a maximum of 1 primary & 10 secondary.",
  "274_34785": "Delete project Success",
  "102_30132": "Select Vehicle Type",
  "102_30170": "Email is required",
  "102_30155": "Enter driver email",
  "307_32115": "Received",
  "102_30135": "Enter VIN",
  "102_30119": "Please input business hours",
  "313_32149": "Please input location in map",
  "308_32126": "Cancelled",
  "307_32114": "In-Transit",
  "250_35113": "Are your sure to delete Segment?",
  "313_32144": "Please input departure airport",
  "102_30136": "Inspection Date cannot be greater than Inspection Due Date!",
  "102_30125": "Please input hours",
  "102_30194": "Select Time",
  "279_34816": "Select Role",
  "102_30139": "Expiry Date cannot be smaller than Start Date!",
  "102_30190": "Select Expense Category",
  "102_30158": "Phone is invalid",
  "31_30206": "Please input Department name",
  "102_30197": "Please input longtitude",
  "102_30201": "Please select Segment",
  "102_30183": "Please input pickup depot",
  "102_30196": "Please input range",
  "313_32143": "Please input usage type",
  "309_32128": "Meeting",
  "174_32696": "Enter phone",
  "174_32691": "Enter first name",
  "277_34813": "You have not set role for the driver.",
  "102_30173": "Phone is required!",
  "174_32698": "Postcode is wrong",
  "243_30546": "Please input pick up date",
  "102_30130": "Select Vehicle Brand",
  "102_30144": "Enter Depot Name",
  "313_32146": "Please input Pickup Location A",
  "102_30189": "Select Date",
  "102_30159": "Enter driver password",
  "309_32129": "Airport",
  "102_30150": "Missing Contact Name",
  "102_30181": "It should be number!",
  "102_30120": "Please input Working Time",
  "309_32131": "Package",
  "102_30165": "Select Business Hour",
  "102_30171": "Email is invalid!",
  "102_30146": "Phone is invalid!",
  "102_30142": "Enter Vehicle Group",
  "102_30151": "Are your sure to send invitation?",
  "102_30129": "Please input end date",
  "102_30134": "Select Vehicle Color",
  "102_30199": "Please input message",
  "313_32147": "Please input pickup sender name",
  "102_30200": "Please input Title",
  "313_32148": "Please input pickup recipient name",
  "174_32695": "Enter code",
  "174_32697": "Phone is invalid",
  "102_30193": "Select Absentee",
  "308_32122": "Ongoing",
  "243_30547": "Please input drop off date",
  "102_30149": "Phone code is required",
  "274_34782": "Approval approved successfully",
  "308_32118": "InStock",
  "308_32119": "Sent",
  "163_30098": "Select Passenger...",
  "102_30148": "Phone Number is required!",
  "102_30191": "Select Driver",
  "279_34817": "Select Organization",
  "277_34812": "Incorrect service code",
  "102_30169": "Last name is required",
  "243_30545": "Please input start date",
  "174_32692": "Enter middle name",
  "274_34781": "Geolocation is not supported by this browser.",
  "309_32130": "Rental",
  "102_30161": "The new password that you entered do not match!",
  "274_34780": "Are your sure to delete Segment?",
  "308_32124": "Completed",
  "277_34811": "Delete service success",
  "308_32125": "Upcoming",
  "102_30152": "Enter driver first name",
  "102_30153": "Enter driver middle name",
  "130_30095": "Request approval successful!",
  "102_30157": "Enter phone",
  "102_30122": "The start time must be less than the end time",
  "102_30160": "Enter confirm password",
  "274_34783": "Approval rejected successfully",
  "307_32113": "Updated",
  "102_30187": "Please input pickup location",
  "309_32127": "Ride",
  "274_34787": "Update successful!",
  "277_34815": "Error",
  "308_32120": "Delivered",
  "102_30192": "Enter Expense Amount",
  "102_30156": "Enter phone code",
  "174_32693": "Enter last name",
  "102_30178": "Confirm Password is required",
  "277_34810": "Please select vehicle",
  "307_32116": "Returned",
  "102_30166": "Select Overtime Rule",
  "102_30127": "Please input holiday title",
  "102_30167": "First name is required",
  "102_30126": "Please input minutes",
  "102_30177": "Your password should not have whitespace",
  "306_32112": "Please input First Day of Week",
  "102_30145": "Enter Address",
  "102_30175": "Password is required!",
  "185_34095": "No data",
  "102_30162": "Enter Employee Number",
  "313_32142": "Please input passenger",
  "102_30163": "No Special Characters Allowed 0-9",
  "274_34784": "Step numbers are duplicated",
  "102_30188": "Enter timeout setting",
  "552_31977": "Enter standby time setting",
  "102_30137": "Inspection Due Date cannot be smaller than Inspection Date!",
  "102_30184": "Please input return depot",
  "163_30097": "Time out is only between 10->15",
  "552_31976": "Standby time is only between 10->60",
  "102_30123": "Please fill the End Time",
  "102_30179": "Confirm Password needs to match Root Password",
  "102_30198": "Please input latitude",
  "102_30186": "Please input time",
  "102_30138": "Start Date cannot be greater than Expiry Date!",
  "313_32145": "Please input meeting location",
  "307_32117": "Added",
  "308_32121": "Returning",
  "174_32694": "Email is invalid",
  "102_30164": "Select Department",
  "102_30121": "Please fill the Start Time",
  "102_30176": "Your password should have 6 characters or more",
  "102_30185": "Please input drop-off location",
  "102_30128": "Please input start date",
  "102_30143": "Select Vehicle Image",
  "102_30124": "Please input overtime rule name",
  "102_30180": "Enter your Employee Number",
  "194_30552": "File should be csv type!",
  "274_34786": "Changed Successfully!",
  "102_30195": "Enter Expense Category",
  "185_34094": "Notification",
  "102_30131": "Select Vehicle Model",
  "193_30545": "Success",
  "102_30154": "Enter driver last name",
  "102_30133": "Select Vehicle Year",
  "102_30147": "Input phone code first!",
  "I174_32597;23_39072": "Approval",
  "174_32593": "Chauffeur Connect",
  "174_30631": "Upload",
  "174_30612": "Update",
  "174_30597": "Address 2",
  "I174_32589;21_22137": "Driver Management",
  "I174_32590;21_22137": "Passenger Management",
  "174_30603": "State/Prefecture",
  "I174_32583;23_39072": "Company setting",
  "174_32577": "5",
  "174_30566": "Phone",
  "I174_32602;23_39072": "Notification",
  "174_30590": "Email",
  "174_30626": "Owner OmAdmin",
  "174_30601": "Postcode",
  "174_30636": "Middle Name",
  "174_30573": "Nick name",
  "174_30581": "Birthday",
  "174_30593": "Address",
  "174_30617": "Security",
  "174_30628": "admin@organiclink.cloud",
  "174_30595": "Address 1",
  "174_30586": "English",
  "174_30619": "Personal data usage history",
  "I174_32588;23_39084": "Vehicle Management",
  "I174_32595;23_39072": "Work Management",
  "174_30605": "Select Country...",
  "174_30584": "Language",
  "174_30563": "First Name",
  "I174_32581;21_27541": "Top",
  "174_32579": "Client Portal",
  "174_30627": "Role:",
  "174_30623": "Log out",
  "174_30576": "Gender",
  "174_30559": "User Profile",
  "174_30599": "City",
  "174_30609": "Last Name",
  "I174_32862;23_39084": "Vehicle Management",
  "174_32739": "8",
  "I174_32871;23_39072": "Approval",
  "174_32867": "Chauffeur Connect",
  "I174_32864;21_22137": "Passenger Management",
  "174_32707": "Overview",
  "174_32738": "Policies",
  "I174_32855;21_27541": "Top",
  "I174_32876;23_39072": "Notification",
  "174_32726": "Roles",
  "174_32752": "End User",
  "174_32716": "Apps",
  "174_32746": "8",
  "174_32753": "8",
  "174_32727": "8",
  "174_32732": "Resources",
  "174_32851": "5",
  "I174_32863;21_22137": "Driver Management",
  "174_32711": "Services",
  "174_32712": "6",
  "174_32853": "Client Portal",
  "174_32717": "8",
  "174_32745": "Users",
  "174_32733": "8",
  "174_32721": "Organizations",
  "174_32722": "8",
  "I174_32857;23_39072": "Company setting",
  "I174_32869;23_39072": "Work Management",
  "I1_14025;250_58566": "Expense Category",
  "I177_33652;405_13268;405_11583": "Tasks",
  "I177_33663;30_32021": "Approve",
  "I1_14005;250_58566": "Vehicle Group",
  "I1_14040;250_58566": "Segment",
  "I177_33686;30_31991": "Approve",
  "1_14020": "Setting",
  "177_33670": "[OM] - The system will be in maintain status at 12:00 today\n07:23 17/11/2023",
  "I1_14031;250_58566": "Approval setting",
  "I1_14039;250_58575": "Send Notification",
  "I1_14024;250_58575": "Report List",
  "177_33676": "Thurday, 16 Nov 2023",
  "I1_13998;250_58566": "Overtime Rules",
  "I177_33649;636_27910": "ABC Corporation",
  "I1_14006;250_58566": "Vehicle Depot",
  "177_33690": "Bien Hoang update Translation Task from TODO to DONE\n10:23 16/11/2023",
  "I1_13999;250_58566": "Holidays",
  "I177_33649;366_12081": "Logout",
  "I177_33649;366_12073": "UserName",
  "177_33698": "View All",
  "I177_33652;405_11590;405_13233;144_59614": "99+",
  "177_33703": "Admin Portal",
  "I177_33649;643_12017": "Activity Logs",
  "I1_14004;250_58575": "Manage Vehicle",
  "I177_33649;366_12079": "Organization Setting",
  "1_14017": "Activity List",
  "177_33709": "Develop Console",
  "I177_33652;405_11590;405_11754": "All",
  "177_33692": "Scroll to load",
  "I1_13996;250_58575": "Department Management",
  "I177_33652;405_13262;405_11583": "Approval",
  "I1_14030;250_58575": "List Approval",
  "I177_33652;405_13262;405_13239;144_59614": "9",
  "I1_13997;250_58566": "Business Hours",
  "177_33713": "Identity Management",
  "1_13988": "5",
  "177_33657": "Friday, 17 Nov 2023",
  "177_33685": "Bien Hoang need you to Approval the Translation Request\n10:23 16/11/2023",
  "177_33662": "Bien Hoang need you to Approval the Translation Request\n10:23 17/11/2023",
  "I1_14000;250_58566": "Emergency Contact",
  "177_33699": "Dashboard",
  "177_33680": "Bien Hoang need you to Approval 5 Wrapper API Creation Request\n07:23 16/11/2023",
  "I177_33649;974_98967": "Profile Setting",
  "177_33666": "Reject",
  "I177_33652;405_13251;405_11583": "Inbox",
  "185_34186": "Client Portal",
  "185_34158": "Security",
  "185_34164": "Log out",
  "185_34142": "Postcode",
  "185_34153": "Update",
  "185_34131": "Email",
  "185_34176": "Middle Name",
  "185_34140": "City",
  "I185_34195;23_39084": "Vehicle Management",
  "185_34114": "Nick name",
  "185_34107": "Phone",
  "185_34168": "Role:",
  "I185_34190;23_39072": "Company setting",
  "185_34167": "Owner OmAdmin",
  "I185_34188;21_27541": "Top",
  "I185_34204;23_39072": "Approval",
  "185_34138": "Address 2",
  "185_34125": "Language",
  "I185_34202;23_39072": "Work Management",
  "185_34117": "Gender",
  "185_34146": "Select Country...",
  "185_34122": "Birthday",
  "185_34144": "State/Prefecture",
  "185_34184": "5",
  "I185_34197;21_22137": "Passenger Management",
  "185_34127": "English",
  "185_34134": "Address",
  "185_34100": "User Profile",
  "185_34169": "admin@organiclink.cloud",
  "185_34136": "Address 1",
  "185_34104": "First Name",
  "I185_34196;21_22137": "Driver Management",
  "I185_34209;23_39072": "Notification",
  "185_34200": "Chauffeur Connect",
  "185_34150": "Last Name",
  "185_34172": "Upload",
  "185_34160": "Personal data usage history",
  "298_32054": "All",
  "301_32099": "Created Date:",
  "301_32111": "Approve",
  "298_32051": "Approved",
  "247_31033": "Edit",
  "247_31028": "View",
  "298_32049": "In process",
  "I247_31021;623_21593": "Warning",
  "I247_31045;611_93387": "The Approval Setting has been successfully updated.",
  "I247_31018;645_18156": "Are you sure you want to delete this Approval Setting?",
  "301_32077": "Approval Confirmation",
  "301_32095": "bienhoanggiang+10@gmail.com",
  "298_32052": "Rejected",
  "I247_31020;611_93387": "The Approval Setting has been successfully deleted.",
  "301_32087": "Function Name:",
  "I247_31038;623_21687": "An error has occurred, please try again or contact the administrator.",
  "I247_31018;645_18160;30_31931": "Confirm",
  "247_31044": "No data",
  "301_32101": "01:15 08-03-2024",
  "I247_31019;611_93387": "The Approval Setting has been successfully created.",
  "247_31037": "Delete",
  "I247_31018;645_18159;30_32137": "Cancel",
  "301_32093": "Created By:",
  "301_32107": "Reject",
  "I247_31022;623_21698": "Info",
  "I247_31018;645_18155": "Warning",
  "301_32089":
    "api-ams_translation_version_english_translation_9b8234d-ccd8-4e00-b407-2386596f7c09",
  "247_31161": "admin@organiclink.cloud",
  "247_34304": "5",
  "247_31235": "update",
  "247_31178": "update",
  "247_31174": "admin@organiclink.cloud",
  "247_31198": "14:10 29-01-2024",
  "247_31159": "api-ams",
  "247_31226": "system_error",
  "247_31167": "14:10 29-01-2024",
  "247_31129": "admin@organiclink.cloud",
  "247_31156": "translation_element",
  "247_31142": "admin@organiclink.cloud",
  "247_31146": "14:10 29-01-2024",
  "247_34306": "Client Portal",
  "247_31169": "translation_element",
  "247_31222": "update",
  "247_31080": "Action",
  "247_31191": "admin@organiclink.cloud",
  "247_31287": "to",
  "247_31131": "create",
  "247_31266": "Create",
  "247_31182": "api-idm_user_update",
  "247_31082": "Object Name",
  "I247_34311;23_39072": "Vehicle Management",
  "247_31172": "api-ams",
  "247_31272": "1",
  "247_31165": "update",
  "247_31220": "admin@organiclink.cloud",
  "247_31114": "admin@organiclink.cloud",
  "247_31127": "admin@organiclink.cloud",
  "247_31096": "User",
  "247_31288": "of",
  "247_31088": "Created By",
  "247_31224": "14:10 29-01-2024",
  "247_31125": "api-ams",
  "247_31099": "api-idm",
  "247_31200": "organization",
  "247_31116": "admin@organiclink.cloud",
  "247_31289": "results",
  "247_31140": "admin@organiclink.cloud",
  "247_31216": "api-ams",
  "247_31211": "14:10 29-01-2024",
  "247_31283": "Show",
  "247_31092": "Modified Date",
  "247_31122": "client_dictionary",
  "247_31237": "14:10 29-01-2024",
  "247_31086": "Modified By",
  "247_31118": "delete",
  "247_31135": "translation_element",
  "247_31247": "ID",
  "247_31180": "14:10 29-01-2024",
  "247_31133": "14:10 29-01-2024",
  "247_31112": "api-ams",
  "247_31239": "api-idm_user_update",
  "247_31245": "api-idm_user_update",
  "247_31250": "Search",
  "I247_34325;250_58575": "List Approval",
  "247_31101": "admin@organiclink.cloud",
  "247_31176": "admin@organiclink.cloud",
  "247_31150": "api-idm_user_update",
  "247_31148": "api-idm_user_update",
  "247_31105": "create",
  "I247_34328;23_39072": "Notification",
  "247_31243": "api-idm_user_update",
  "247_31241": "api-idm_user_update",
  "247_31195": "update",
  "247_31293": "Approval Setting",
  "247_31186": "User",
  "247_31107": "14:10 29-01-2024",
  "247_31189": "api-idm",
  "247_31090": "Method",
  "247_31292": "/",
  "247_31284": "1",
  "247_31152": "api-idm_user_update",
  "247_31196": "update",
  "247_31154": "api-idm_user_update",
  "247_31279": "page",
  "247_31203": "api-idm",
  "247_31144": "translation",
  "247_31231": "admin@organiclink.cloud",
  "247_31209": "update",
  "247_31213": "client_dictionary",
  "I247_34323;23_39084": "Approval",
  "247_31233": "admin@organiclink.cloud",
  "247_31109": "client_dictionary",
  "I247_34308;21_22137": "Top",
  "247_31286": "40",
  "247_31103": "admin@organiclink.cloud",
  "I247_34309;23_39072": "Company Settings",
  "247_31291": "Approval",
  "I247_34315;21_22137": "Driver Management",
  "247_31205": "admin@organiclink.cloud",
  "247_31278": "20  /",
  "247_31084": "Service Name",
  "I247_34326;250_58566": "Approval setting",
  "247_31184": "api-idm_user_update",
  "247_31120": "14:10 29-01-2024",
  "247_31218": "admin@organiclink.cloud",
  "247_31229": "api-ams",
  "247_31163": "admin@organiclink.cloud",
  "I247_34316;21_22137": "Passenger Management",
  "247_31262": "Approval Settings",
  "I247_34321;23_39072": "Work Management",
  "247_31285": "20",
  "247_31193": "admin@organiclink.cloud",
  "247_34319": "Chauffeur Connect",
  "247_31138": "api-ams",
  "247_31207": "admin@organiclink.cloud",
  "247_31316": "Enter name",
  "247_31314": "Name",
  "247_31342": "Enter approvers",
  "247_31340": "Approvers",
  "I247_34669;23_39072": "Work Management",
  "I247_34671;23_39084": "Approval",
  "247_31326": "Enter object",
  "247_31351": "Add field",
  "247_31362": "Enter method",
  "I247_34674;250_58566": "Approval setting",
  "247_31359": "Please enter an integer value",
  "I247_34657;23_39072": "Company Settings",
  "I247_34664;21_22137": "Passenger Management",
  "247_34654": "Client Portal",
  "247_31361": "Enter object",
  "247_31358": "Enter step number",
  "247_34667": "Chauffeur Connect",
  "247_31364": "Approval",
  "247_34652": "5",
  "247_31332": "Enter method",
  "I247_34663;21_22137": "Driver Management",
  "I247_34656;21_22137": "Top",
  "I247_34673;250_58575": "List Approval",
  "247_31355": "Next",
  "247_31324": "Object",
  "247_31356": "Enter name",
  "247_31336": "Steps",
  "247_31309": "Create",
  "I247_34659;23_39072": "Vehicle Management",
  "247_31353": "Cancel",
  "247_31318": "Service Name",
  "247_31330": "Method",
  "I247_34676;23_39072": "Notification",
  "247_31338": "Enter step number",
  "247_31360": "Enter approvers",
  "247_31320": "Enter service name",
  "247_31357": "Enter service",
  "247_31365": "/",
  "247_31313": "Approval Setting",
  "247_31366": "Approval Setting",
  "247_31437": "Back",
  "247_34422": "Client Portal",
  "247_31399": "Service Name",
  "247_31382": "Create",
  "I247_34442;250_58566": "Approval setting",
  "247_31390": "/",
  "247_31389": "Approval",
  "247_31401": "IDM",
  "I247_34437;23_39072": "Work Management",
  "247_31417": "Steps",
  "I247_34424;21_22137": "Top",
  "I247_34432;21_22137": "Passenger Management",
  "247_31397": "Update Admin User",
  "247_31383": "Preview",
  "I247_34431;21_22137": "Driver Management",
  "I247_34427;23_39072": "Vehicle Management",
  "247_31424": "Admin@organicklink.cloud",
  "247_34420": "5",
  "247_31419": "1",
  "I247_34425;23_39072": "Company Settings",
  "I247_34439;23_39084": "Approval",
  "247_31387": "Approval Setting",
  "247_31395": "Name",
  "247_31439": "Save",
  "247_31407": "User",
  "247_34435": "Chauffeur Connect",
  "247_31413": "Update",
  "247_31421": "Approvers",
  "247_31411": "Method",
  "247_31405": "Object",
  "247_31435": "Add field",
  "I247_34444;23_39072": "Notification",
  "247_31391": "Approval Setting",
  "I247_34441;250_58575": "List Approval",
  "247_31489": "1",
  "247_31475": "Object",
  "247_34536": "5",
  "247_31487": "Steps",
  "247_34538": "Client Portal",
  "I247_34560;23_39072": "Notification",
  "247_31467": "Update Admin User",
  "I247_34541;23_39072": "Company Settings",
  "247_31491": "Approvers",
  "247_34551": "Chauffeur Connect",
  "247_31459": "Approval",
  "247_31469": "Service Name",
  "247_31477": "User",
  "247_31461": "Approval Setting",
  "I247_34555;23_39084": "Approval",
  "247_31507": "Back",
  "247_31460": "/",
  "247_31494": "Admin@organicklink.cloud",
  "247_31505": "Add field",
  "247_31465": "Name",
  "I247_34548;21_22137": "Passenger Management",
  "I247_34547;21_22137": "Driver Management",
  "I247_34557;250_58575": "List Approval",
  "I247_34558;250_58566": "Approval setting",
  "247_31481": "Method",
  "247_31453": "Preview",
  "247_31483": "Update",
  "247_31471": "IDM",
  "247_31452": "Update",
  "I247_34540;21_22137": "Top",
  "247_31457": "Approval Setting",
  "247_31509": "Update",
  "I247_34543;23_39072": "Vehicle Management",
  "I247_34553;23_39072": "Work Management",
  "I247_34780;21_22137": "Passenger Management",
  "I247_34773;23_39072": "Company Settings",
  "I247_34792;23_39072": "Notification",
  "247_31587": "Update",
  "247_34770": "Client Portal",
  "I247_34779;21_22137": "Driver Management",
  "247_31534": "Name",
  "247_31552": "Update",
  "247_34768": "5",
  "247_31558": "1",
  "I247_34789;250_58575": "List Approval",
  "247_31580": "Approval",
  "247_31536": "Update Admin User",
  "I247_34785;23_39072": "Work Management",
  "247_31556": "Steps",
  "I247_34775;23_39072": "Vehicle Management",
  "I247_34790;250_58566": "Approval setting",
  "I247_34787;23_39084": "Approval",
  "247_31544": "Object",
  "247_31574": "Add field",
  "247_31591": "Approval Setting",
  "247_31582": "Approval Setting",
  "247_31581": "/",
  "247_31563": "Admin@organicklink.cloud",
  "I247_34772;21_22137": "Top",
  "247_31550": "Method",
  "247_34783": "Chauffeur Connect",
  "247_31546": "User",
  "247_31538": "Service Name",
  "247_31578": "Next",
  "247_31560": "Approvers",
  "247_31540": "IDM",
  "247_31576": "Cancel",
  "247_35000": "5",
  "247_31625": "Steps",
  "I247_35004;21_22137": "Top",
  "I247_35007;23_39072": "Vehicle Management",
  "247_31650": "View",
  "I247_35005;23_39072": "Company Settings",
  "247_31609": "IDM",
  "247_31656": "Approval",
  "247_31654": "Approval Setting",
  "I247_35019;23_39084": "Approval",
  "I247_35024;23_39072": "Notification",
  "247_31619": "Method",
  "I247_35012;21_22137": "Passenger Management",
  "247_31605": "Update Admin User",
  "247_31632": "Admin@organicklink.cloud",
  "247_31657": "/",
  "I247_35021;250_58575": "List Approval",
  "247_31603": "Name",
  "247_31643": "Add field",
  "247_31629": "Approvers",
  "247_31627": "1",
  "247_31645": "Back",
  "247_35015": "Chauffeur Connect",
  "I247_35017;23_39072": "Work Management",
  "247_31658": "Approval Setting",
  "247_31613": "Object",
  "I247_35011;21_22137": "Driver Management",
  "247_35002": "Client Portal",
  "247_31615": "User",
  "I247_35022;250_58566": "Approval setting",
  "247_31607": "Service Name",
  "247_31621": "Update",
  "I247_34889;23_39072": "Company Settings",
  "247_31710": "Add field",
  "247_31726": "/",
  "247_31692": "Steps",
  "I247_34908;23_39072": "Notification",
  "247_31727": "Approval Setting",
  "I247_34896;21_22137": "Passenger Management",
  "I247_34901;23_39072": "Work Management",
  "247_34884": "5",
  "247_31696": "Approvers",
  "I247_34903;23_39084": "Approval",
  "247_31680": "Object",
  "247_31670": "Name",
  "247_34899": "Chauffeur Connect",
  "I247_34888;21_22137": "Top",
  "247_31674": "Service Name",
  "247_31682": "User",
  "247_31725": "Approval",
  "247_31721": "Approval Setting",
  "247_31694": "1",
  "247_31717": "Review",
  "247_31688": "Update",
  "247_31672": "Update Admin User",
  "I247_34906;250_58566": "Approval setting",
  "247_31699": "Admin@organicklink.cloud",
  "I247_34895;21_22137": "Driver Management",
  "247_31676": "IDM",
  "247_31686": "Method",
  "I247_34891;23_39072": "Vehicle Management",
  "247_31712": "Back",
  "247_31723": "Update",
  "247_34886": "Client Portal",
  "I247_34905;250_58575": "List Approval",
  "258_31588": "Client Portal",
  "I258_31545;35_49548;28_31236": "Notification",
  "258_31808": "*",
  "258_31618": "Notification",
  "258_31586": "5",
  "I258_31597;23_39072": "Vehicle Management",
  "I258_31810;121_59006": "Select Organization",
  "258_31555": "*",
  "I258_31552;75_53807": "Title",
  "258_31556": "Segment Type",
  "I258_31621;250_58566": "Send Notification",
  "I258_31610;23_39072": "Approval",
  "I258_31581;30_32137": "Cancel",
  "I258_31813;30_32140": "Add Target",
  "258_31551": "Title",
  "I258_31854;30_32140": "Search",
  "I258_31545;35_49550;28_31236": "Segment",
  "I258_31622;250_58575": "Segment",
  "I258_31853;185_68545": "Select User",
  "258_31809": "Organization",
  "258_31564": "All Organization",
  "I258_31545;35_49551;28_31261": ">",
  "258_31803": "*",
  "I258_31582;30_32140": "Next",
  "258_31550": "*",
  "I258_31608;23_39072": "Work Management",
  "258_31858": "User",
  "258_31822": "*",
  "258_31823": "Role",
  "I258_31603;21_22137": "Passenger Management",
  "I258_31545;35_49549;28_31261": ">",
  "I258_31545;35_49552;28_31243": "Add Segment",
  "258_31606": "Chauffeur Connect",
  "I258_31602;21_22137": "Driver Management",
  "I258_31592;23_39072": "Company setting",
  "258_31570": "Specific Organization",
  "258_31804": "Target",
  "I258_31546;35_49503": "Add Segment",
  "I258_31590;21_22137": "Top",
  "I258_31824;121_59006": "Select Role",
  "I268_32007;1_6306;1_6254;1_6154": "Function Setting",
  "268_32056": "Approve",
  "I268_32007;1_6306;1_6257;1_6154": "Vehicle Management",
  "I268_32007;1_6306;1_6247;1_6154": "Service Management",
  "I268_32007;1_6306;1_6242": "5",
  "268_32041": "Get IDM",
  "268_32053": "29-01-2024 15:52",
  "I268_32007;1_6306;1_6256;1_6154": "Device Management",
  "I268_32007;1_6306;1_6265;1_6214": "Notification",
  "268_32058": "Reject",
  "268_32021": "99+",
  "268_32019": "General",
  "268_32011": "Activity Logs",
  "268_32051": "Get IDM",
  "I268_32007;1_6306;1_6246;1_6154": "TOP",
  "268_32034": "Setting",
  "268_32025": "99+",
  "268_32031": "Tasks",
  "I268_32007;1_6306;1_6259;1_6219": "Approval",
  "I268_32007;1_6306;1_6244": "Admin Portal",
  "268_32033": "99+",
  "268_32043": "29-01-2024 15:52",
  "268_32027": "Inbox",
  "I268_32007;1_6306;1_6253;1_6154": "Identity Management",
  "268_32052": "test idm . From service api-idm raise error 200",
  "268_32014": "My Notification",
  "268_32029": "99+",
  "268_32042": "test idm . From service api-idm raise error 200",
  "I268_32007;1_6306;1_6255;1_6154": "Developer Portal",
  "268_32023": "Approval",
  "268_32100": "Search by object",
  "I268_32071;1_6306;1_6256;1_6154": "Device Management",
  "268_32111": "Tasks",
  "268_32093": "99+",
  "268_32115": "Vehicle Management",
  "I268_32071;1_6306;1_6265;1_6214": "Notification",
  "268_32127": "Turn on if you would like to receive notifications without popups and sound.",
  "I268_32071;1_6306;1_6253;1_6154": "Identity Management",
  "268_32085": "99+",
  "I268_32071;1_6306;1_6255;1_6154": "Developer Portal",
  "268_32130": "Send Email",
  "268_32103": "Inbox",
  "268_32091": "Inbox",
  "I268_32071;1_6306;1_6257;1_6154": "Vehicle Management",
  "268_32075": "Activity Logs",
  "268_32107": "Approval",
  "268_32094": "Setting",
  "268_32119": "Logistic Connect",
  "I268_32071;1_6306;1_6254;1_6154": "Function Setting",
  "I268_32071;1_6306;1_6247;1_6154": "Service Management",
  "268_32126": "Silent",
  "I268_32071;1_6306;1_6246;1_6154": "TOP",
  "I268_32071;1_6306;1_6244": "Admin Portal",
  "268_32089": "99+",
  "268_32087": "Approval",
  "I268_32071;1_6306;1_6259;1_6219": "Approval",
  "268_32078": "My Notification",
  "268_32083": "General",
  "I268_32071;1_6306;1_6242": "5",
  "268_32131": "Turn on if you would like to receive notifications by email.",
  "268_32157": "/",
  "I268_32137;1_6306;1_6254;1_6154": "Function Setting",
  "I268_32137;1_6306;1_6246;1_6154": "TOP",
  "268_32147": "Subject",
  "268_32152": "Thurday, 28-02-2024 12:14",
  "268_32154": "Back",
  "I268_32137;1_6306;1_6256;1_6154": "Device Management",
  "I268_32137;1_6306;1_6253;1_6154": "Identity Management",
  "I268_32137;1_6306;1_6242": "5",
  "268_32141": "Activity Logs",
  "I268_32137;1_6306;1_6255;1_6154": "Developer Portal",
  "268_32149": "GET IDM",
  "I268_32137;1_6306;1_6244": "Admin Portal",
  "268_32158": "Detail Notification",
  "I268_32137;1_6306;1_6265;1_6214": "Notification",
  "268_32151": "test idm . From service api-idm raise error 200",
  "268_32156": "My Notification",
  "I268_32137;1_6306;1_6257;1_6154": "Vehicle Management",
  "I268_32137;1_6306;1_6259;1_6219": "Approval",
  "I268_32137;1_6306;1_6247;1_6154": "Service Management",
  "268_32144": "Detail Notification",
  "269_34298": "create",
  "269_34302": "admin@organiclink.cloud",
  "269_34294": "api_idm",
  "269_34284": "Detail Log",
  "269_34310": "user",
  "I269_34327;1_6306;1_6242": "5",
  "I269_34327;1_6306;1_6255;1_6154": "Developer Portal",
  "I269_34327;1_6306;1_6259;1_6219": "Approval",
  "269_34300": "User Email",
  "269_34312": "16:12:47 2024-02-28",
  "I269_34327;1_6306;1_6256;1_6154": "Device Management",
  "269_34334": "Expired At:",
  "269_34338": "/",
  "269_34336": "Detail Log",
  "269_34332": "Activity Logs",
  "269_34292": "Service Code",
  "I269_34327;1_6306;1_6246;1_6154": "TOP",
  "269_34311": "16:12:47 2024-01-29",
  "269_34304": "Status",
  "I269_34327;1_6306;1_6257;1_6154": "Vehicle Management",
  "269_34290": "om-admin",
  "I269_34327;1_6306;1_6254;1_6154": "Function Setting",
  "269_34296": "Action",
  "I269_34327;1_6306;1_6247;1_6154": "Service Management",
  "269_34321": "New Data",
  "269_34306": "SUCCESS",
  "269_34314": "Old Data",
  "269_34333": "Created At:",
  "I269_34327;1_6306;1_6253;1_6154": "Identity Management",
  "I269_34327;1_6306;1_6244": "Admin Portal",
  "269_34308": "Object Code",
  "269_34288": "Organization Code",
  "269_34337": "Activity Logs",
  "I269_34327;1_6306;1_6265;1_6214": "Notification",
  "269_34417": "SUCCESS",
  "269_34466": "CREATE",
  "269_34462": "admin@organiclink.cloud",
  "275_34809": "16:12 29-01-2024",
  "269_34477": "DELETE",
  "269_34424": "service",
  "275_34789": "Expired At",
  "I269_34546;1_6306;1_6259;1_6219": "Approval",
  "275_34799": "16:12 29-01-2024",
  "269_34569": "20",
  "269_34436": "admin@organiclink.cloud",
  "269_34384": "Status",
  "269_34506": "SUCCESS",
  "269_34493": "16:12 29-01-2024",
  "269_34473": "SUCCESS",
  "275_34801": "16:12 29-01-2024",
  "269_34447": "SUCCESS",
  "I269_34546;1_6306;1_6246;1_6154": "TOP",
  "269_34410": "CREATE",
  "269_34516": "admin@organiclink.cloud",
  "269_34514": "admin@organiclink.cloud",
  "269_34499": "CREATE",
  "269_34451": "DELETE",
  "269_34419": "api_control_plane",
  "I269_34546;1_6306;1_6254;1_6154": "Function Setting",
  "269_34440": "CREATE",
  "269_34480": "permission",
  "275_34803": "16:12 29-01-2024",
  "269_34376": "Action",
  "269_34430": "api_control_plane",
  "269_34542": "End Date",
  "275_34791": "16:2 29-01-2024",
  "269_34413": "organization",
  "275_34795": "16:12 29-01-2024",
  "I269_34546;1_6306;1_6253;1_6154": "Identity Management",
  "269_34573": "results",
  "269_34495": "SUCCESS",
  "269_34469": "user",
  "269_34406": "SUCCESS",
  "269_34464": "admin@organiclink.cloud",
  "269_34502": "user",
  "269_34458": "SUCCESS",
  "269_34456": "16:12 29-01-2024",
  "269_34523": "Activity Logs",
  "269_34391": "permission",
  "269_34570": "40",
  "275_34807": "16:12 29-01-2024",
  "269_34382": "Created At",
  "269_34445": "16:12 29-01-2024",
  "269_34562": "20  /",
  "269_34475": "api_idm",
  "I269_34546;1_6306;1_6244": "Admin Portal",
  "I269_34546;1_6306;1_6256;1_6154": "Device Management",
  "269_34504": "16:12 29-01-2024",
  "269_34556": "1",
  "269_34563": "page",
  "269_34428": "SUCCESS",
  "269_34438": "admin@organiclink.cloud",
  "269_34393": "16:2 29-01-2024",
  "I269_34546;1_6306;1_6255;1_6154": "Developer Portal",
  "269_34527": "Service",
  "269_34512": "admin@organiclink.cloud",
  "269_34454": "service",
  "269_34551": "Activity Logs",
  "269_34491": "user",
  "269_34460": "api_control_plane",
  "269_34432": "admin@organiclink.cloud",
  "269_34497": "api_idm",
  "269_34484": "SUCCESS",
  "269_34518": "User",
  "269_34426": "16:12 29-01-2024",
  "269_34434": "admin@organiclink.cloud",
  "I269_34546;1_6306;1_6242": "5",
  "269_34395": "SUCCESS",
  "269_34535": "Action",
  "269_34402": "permission",
  "269_34531": "User",
  "269_34408": "api_idm",
  "275_34805": "16:12 29-01-2024",
  "269_34415": "16:12 29-01-2024",
  "275_34793": "16:12 29-01-2024",
  "269_34510": "admin@organiclink.cloud",
  "269_34571": "to",
  "269_34443": "service",
  "269_34449": "api_control_plane",
  "269_34567": "Show",
  "269_34399": "CREATE",
  "275_34797": "16:12 29-01-2024",
  "269_34471": "16:12 29-01-2024",
  "269_34488": "DELETE",
  "269_34421": "DELETE",
  "269_34572": "of",
  "I269_34546;1_6306;1_6247;1_6154": "Service Management",
  "269_34380": "Object code",
  "269_34539": "Start Date",
  "I269_34546;1_6306;1_6257;1_6154": "Vehicle Management",
  "269_34397": "api_idm",
  "269_34482": "16:12 29-01-2024",
  "269_34386": "Service code",
  "269_34486": "api_idm",
  "269_34388": "UPDATE",
  "269_34378": "Action",
  "269_34508": "api_idm",
  "I269_34546;1_6306;1_6265;1_6214": "Notification",
  "269_34404": "16:12 29-01-2024",
  "269_34568": "1",
  "269_34580": "Create",
  "269_34582": "Delete",
  "269_34581": "Update",
  "I269_34595;623_21687": "An error has occurred, please try again or contact the administrator.",
  "269_34594": "No data",
  "269_34588": "View",
  "I280_34933;611_95467;30_32137": "Cancel",
  "I280_34933;611_93748": "Warning",
  "I280_34933;611_93749": "",
  "I280_34933;611_95468;611_95349": "Confirm",
  "294_31900": "*",
  "I294_31957;23_39072": "Vehicle Management",
  "I294_31981;250_58566": "Send Notification",
  "294_31978": "Notification",
  "I294_31942;30_32140": "Save",
  "294_31915": "Specific Organization",
  "294_31948": "Client Portal",
  "I294_31941;30_32137": "Back",
  "294_31966": "Chauffeur Connect",
  "I294_31970;23_39072": "Approval",
  "I294_31931;121_59018": "Select Role",
  "294_31924": "*",
  "294_31895": "*",
  "294_31908": "All Organization",
  "I294_31963;21_22137": "Passenger Management",
  "I294_31952;23_39072": "Company setting",
  "I294_31968;23_39072": "Work Management",
  "I294_31890;35_49597;28_31236": "Segment",
  "I294_31962;21_22137": "Driver Management",
  "I294_31935;185_68545": "Select User",
  "I294_31950;21_22137": "Top",
  "I294_31982;250_58575": "Segment",
  "294_31929": "*",
  "294_31925": "Organization",
  "I294_31890;35_49599;28_31236": "Edit Segment",
  "294_31901": "Segment Type",
  "294_31919": "*",
  "I294_31897;185_68545": "Title",
  "294_31933": "User",
  "I294_31926;121_59018": "Select Organization",
  "I294_31890;35_49598;28_31261": ">",
  "I294_31890;35_49607;28_31261": ">",
  "I294_31890;35_49595;28_31236": "Notification",
  "I294_31891;35_49503": "View Segment",
  "I294_31939;30_32081": "Add Target",
  "294_31920": "Target",
  "294_31946": "5",
  "I294_31890;35_49596;28_31261": ">",
  "294_31930": "Role",
  "294_31896": "Title",
  "I294_31936;30_32081": "Search",
  "I294_31890;35_49605;28_31243": "View Segment",
  "217_4561": "Country Code",
  "I2695_15314;7636_13403": "Seating Capacity",
  "2695_15316": "Enter Seating Capacity",
  "I2695_15314;7636_13405;75_53807": "Seating Capacity"
}
export default localeDefault
